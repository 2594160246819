import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { useCookies } from "react-cookie";

// const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

// API call for users-list
export const getusersList = createAsyncThunk("users/list", async (reqbody) => {

    if (reqbody == null && reqbody === undefined) {
        reqbody = {



            "source": "users",
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                // "_id": userInfo?.userinfo?.roles?.is_admin !== 1 ? userInfo?.userinfo?._id : undefined, //-- list will show exculding this refrence user
            },
            "project": {},
            "token": "",
            "email": "",

            "count": false,



        }
    }

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body,)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-list-or-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


///////////////////////////////// Status Update //////////////////////////////////
export const usermanagestatusUpdate = createAsyncThunk("statusUpdateUserManagement", async (reqbody) => {

    console.warn("<<<<< usermanagestatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody.editData;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": [reqbody.dataCopy._id], "status": reqbody.status })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/status-update", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});


///////////////////////////////// Delete User //////////////////////////////////
export const userManagementDelete = createAsyncThunk("usermanagementDelete", async (reqbody) => {

    // console.warn("<<<<< userManagementDelete >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody.editData;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": [reqbody._id] })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/delete-users", requestOptions);
    const respdataforUserDelete = await response.json();

    return respdataforUserDelete;
});


///////////////////////////////// Multiple Delete User //////////////////////////////////
export const userManagementMultiDelete = createAsyncThunk("userManagementMulti/Delete", async (reqbody) => {

    // console.warn("<<<<< userManagementDelete >>>>>>", reqbody)

    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqbody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/delete-users", requestOptions);
    const respdataforUserMultiDelete = await response.json();

    return respdataforUserMultiDelete;
});


///////////////////////////////// Multi Status Update User //////////////////////////////////
export const usermanageMultiStatusUpdate = createAsyncThunk("userManagementMulti/Delete", async (reqbody) => {


    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqbody.copyArr, status: reqbody.statusAction })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/status-update", requestOptions);
    const usermanageMultiStatusUpdate = await response.json();

    return usermanageMultiStatusUpdate;
});


/////////////////////////////////  ResendWelcomeEmail //////////////////////////////////
export const userManagementResendWelcomeEmail = createAsyncThunk("usermanagement/resendwelcomeemail", async (reqbody) => {

    let req_body = {};
    req_body = reqbody.editData;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": reqbody._id, })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "profile/resend-welcome-mail", requestOptions);
    const managementResendWelcomeEmail = await response.json();

    return managementResendWelcomeEmail;
});


/////////////////////////////////  sendResetPassword //////////////////////////////////
export const sendResetPassword = createAsyncThunk("usermanagement/resetpassword", async (reqbody) => {
    console.warn("<<<<<<<< sendResetPassword >>>>>>", reqbody)
    let req_body = {};
    req_body = reqbody.editData;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "email": reqbody.o_email })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "profile/forgot-password", requestOptions);
    const sendResetPassword = await response.json();

    return sendResetPassword;
});


/////////////////////////////////  logMeIn //////////////////////////////////
export const logMeIn = createAsyncThunk("usermanagement/logmein", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logMeIn thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "admin_id": reqbody.admin_id, "log_me_id": reqbody.log_me_id })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api/log-in-as-user", requestOptions);
    const logMeIn = await response.json();

    return logMeIn;
});


/////////////////////////////////  logInInfo //////////////////////////////////
export const logInInfo = createAsyncThunk("usermanagement/logininfo", async (reqbody) => {
    console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                "condition": {
                    "limit": 3,
                    "skip": 0
                },
                "sort": {
                    "field": "_id",
                    "type": "desc"
                },
                "searchcondition": {
                    "user_id": reqbody._id
                }
            })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "profile/fetch-login-details", requestOptions);
    const logInInfo = await response.json();

    return logInInfo;
});








/////////////////////////////////  fetchUserRoles //////////////////////////////////
export const fetchUserRoles = createAsyncThunk("userlist/fetchuserroles", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                "_id": reqbody._id
            })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "role/manage-roles", requestOptions);
    const fetchUserRoles = await response.json();

    return fetchUserRoles;
});



/////////////////////////////////  addUserRoles //////////////////////////////////
export const addUserRoles = createAsyncThunk("userlist/adduserroles", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                "_id": reqbody._id,
                "roles_data": reqbody.roles_data
            })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-role-add", requestOptions);
    const addUserRoles = await response.json();

    return addUserRoles;
});


/////////////////////////////////  fetchUserRolesName //////////////////////////////////
export const fetchUserRolesName = createAsyncThunk("userlist/fetchuserrolename", async (reqbody) => {
    console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "role/roles-list-count", requestOptions);
    const fetchUserRolesName = await response.json();

    return fetchUserRolesName;
});



/////////////////////////////////  fetchRoles //////////////////////////////////
export const FetchRoles = createAsyncThunk("userlist/fetchRoles", async (reqbody) => {
    console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "role/fetch-roles", requestOptions);
    const FetchRoles = await response.json();

    return FetchRoles;
});


/////////////////////////////////  roundRobin //////////////////////////////////
export const roundRobin = createAsyncThunk("userlist/roundRobin", async (reqbody) => {
    console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/round-robin-listing", requestOptions);
    const roundRobin = await response.json();

    return roundRobin;
});


/////////////////////////////////  roundRobinEdit //////////////////////////////////
export const roundRobinEdit = createAsyncThunk("userlist/roundRobinEdit", async (reqbody) => {
    console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/round-robin-edit", requestOptions);
    const roundRobinEdit = await response.json();

    return roundRobinEdit;
});


/////////////////////////////////  FetchHMSettingModal //////////////////////////////////
export const FetchHMSettingModal = createAsyncThunk("userlist/FetchHMSettingModal", async (reqbody) => {
    console.warn("<<<<<<<< payload from FetchHMSettingModal thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/hm-listing", requestOptions);
    const FetchHMSettingModal = await response.json();

    return FetchHMSettingModal;
});


/////////////////////////////////  FetchHMSettingAccessUpdate //////////////////////////////////
export const FetchHMSettingAccessUpdate = createAsyncThunk("userlist/FetchHMSettingAccessUpdate", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/hm-type", requestOptions);
    const FetchHMSettingAccessUpdate = await response.json();

    return FetchHMSettingAccessUpdate;
});

/////////////////////////////////  FetchTagDetails //////////////////////////////////
export const FetchTagDetails = createAsyncThunk("userlist/FetchTagDetails", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/manage-tags", requestOptions);
    const FetchTagDetails = await response.json();

    return FetchTagDetails;
});


/////////////////////////////////  updateTags //////////////////////////////////
export const updateTags = createAsyncThunk("userlist/updateUserTags", async (reqbody) => {
    console.warn("<<<<<<<< payload from updateTags thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-tag-add", requestOptions);
    const updateTags = await response.json();

    return updateTags;
});


/////////////////////////////////  FetchTrainingStatus //////////////////////////////////
export const FetchTrainingStatus = createAsyncThunk("userlist/fetchtrainingstatus", async (reqbody) => {
    console.warn("<<<<<<<< payload from FetchTrainingStatus thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/completed-training-list", requestOptions);
    const FetchTrainingStatus = await response.json();

    return FetchTrainingStatus;
});


/////////////////////////////////  FetchStages //////////////////////////////////
export const FetchStages = createAsyncThunk("userlist/fetchstages", async (reqbody) => {
    console.warn("<<<<<<<< payload from FetchStages thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "assign-stage/assigned-stage-list", requestOptions);
    const FetchStages = await response.json();

    return FetchStages;
});


/////////////////////////////////  AssignStages //////////////////////////////////
export const updateStages = createAsyncThunk("userlist/updatestages", async (reqbody) => {
    console.warn("<<<<<<<< payload from AssignStages thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "assign-stage/assign-stage", requestOptions);
    const updateStages = await response.json();

    return updateStages;
});


/////////////////////////////////  fetchContract //////////////////////////////////
export const fetchContract = createAsyncThunk("userlist/fetchContract", async (reqbody) => {
    console.warn("<<<<<<<< payload from fetchContract thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/fetch-contract", requestOptions);
    const fetchContract = await response.json();

    return fetchContract;
});


/////////////////////////////////  fetchContractTemplate  //////////////////////////////////
export const fetchContractTemplate = createAsyncThunk("userlist/fetchContactTemplate", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/set-contract-template", requestOptions);
    const fetchContractTemplate = await response.json();

    return fetchContractTemplate;
});


/////////////////////////////////  sendContract //////////////////////////////////
export const sendContract = createAsyncThunk("userlist/sendContract", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/send-contract", requestOptions);
    const sendContract = await response.json();

    return sendContract;
});


/////////////////////////////////  FetchRolesforUpgrade //////////////////////////////////
export const rolesForUpgrade = createAsyncThunk("userlist/upgraderole", async (reqbody) => {
    console.warn("<<<<<<<< payload from fetchContract thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "_id": reqbody._id
        })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "role/manage-roles", requestOptions);
    const rolesForUpgrade = await response.json();

    return rolesForUpgrade;
});


/////////////////////////////////  upgradeRole //////////////////////////////////
export const upgradeRole = createAsyncThunk("userlist/rolesupgrade", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                "_id": reqbody._id,
                "roles_data": reqbody.roles_data
            })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-role-add", requestOptions);
    const upgradeRole = await response.json();

    return upgradeRole;
});


// // -------------------------------------------------------------
export const signContract = createAsyncThunk("contract/sign", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/sign-contract", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});
// -------------------------------------------------------------




const userManagment = createSlice({
    name: 'userManagment',
    initialState: {
        submitting: false,
        users: [],
        loading: false,
        submitted: false,
        resMessage: null,
        usersList: null,
        successmsg: null,
        success: false,
        failed: false,
        currentEditUserData: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        successAction: false,
        loadinglist: false,
        logininfo: null,
        userNotLoginYet: false,
        userRoles: [],
        userRoleNames: [],
        fetchRoles: [],
        roleAdded: false,
        roundRobinData: null,
        roundRobinSuccess: false,
        HMSettingModalData: null,
        HMSettingModalStatus: false,
        FetchTagDetailsData: [],
        trainingData: [],
        userTrainingData: null,
        tagUpdated: true,
        FetchStages: [],
        fetchContractData: [],
        userRolesNew: [],
        roleUpgraded: false,
        contractTemplate: {},
        contractSend: false,
        conMessage: null,
        contractLoader: false,
        contractSigned: false,
        contractSignedData: [],
        stageUpdated: false
    },

    reducers: {

        setSuccessAfterSubmit(state, action) {
            state.success = false;
            state.failed = false;
            state.loading = false;
        },
        currentEditUserData(state, action) {
            state.currentEditUserData = action.payload
        },
        setMessagefterSubmit(state, action) {
            state.messageflag = false;
            state.message = null;
        },
        setTokenRequiredStatus(state, action) {
            state.tokenRequired = false
        },
        setStatesBlank(state, action) {
            state.submitting = false;
            state.users = [];
            state.loading = false;
            state.submitted = false;
            state.resMessage = null;
            state.usersList = null;
            state.successmsg = null;
            state.success = false;
            state.failed = false;
            state.currentEditUserData = null;
            state.tokenRequired = false;
            state.tokenRequiredMsg = '';
            state.successAction = false;
            state.loadinglist = false;
            state.logininfo = null;
            state.userNotLoginYet = false;
            state.userRoles = [];
            state.userRoleNames = [];
            state.fetchRoles = [];
            state.roundRobinSuccess = false;
            state.HMSettingModalData = null;
            state.FetchTagDetailsData = null;
            state.trainingData = [];
            state.contractSignedData = [];
            state.stageUpdated = false;

        },
        clearContractData(state, action) {
            state.fetchContractData = [];

        },
        clearContractModalData(state, action) {

            state.contractTemplate = {}
        }


    },
    extraReducers: {

        /// #################### USER LISting ###############################
        [getusersList.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [getusersList.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
            state.successAction = false;

        },
        [getusersList.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.usersList = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            if (action.payload.status === 'error') {
                if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
                    state.tokenRequired = true;
                    state.tokenRequiredMsg = action.payload.results;
                }
            }
        },



        /////////////////////////// status update ///////////////////////////////
        [usermanagestatusUpdate.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.failed = false;
            state.successAction = false;


        },
        [usermanagestatusUpdate.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Updated Successfully'
                // state.success = true;
                state.successAction = true;
                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [usermanagestatusUpdate.rejected]: (state, action) => {
            state.loading = false;
            state.failed = true;
            state.resMessage = 'Something went wrong';
        },



        /////////////////////////// Delete User ///////////////////////////////
        [userManagementDelete.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.successAction = false;

        },
        [userManagementDelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Deleted Successfully'
                // state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [userManagementDelete.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },




        /////////////////////////// Multiple Delete User ///////////////////////////////
        [userManagementMultiDelete.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.successAction = false;

        },
        [userManagementMultiDelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Deleted Successfully'
                // state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [userManagementMultiDelete.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },


        /////////////////////////// Multi Status Update User ///////////////////////////////
        [usermanageMultiStatusUpdate.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.successAction = false;

        },
        [usermanageMultiStatusUpdate.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Status Update Successfully'
                // state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [usermanageMultiStatusUpdate.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },



        /////////////////////////// Resend Welcome Email /////////////////////////
        [userManagementResendWelcomeEmail.pending]: (state, action) => {
            state.loading = true;
            state.resMessage = null
            state.success = false;
        },
        [userManagementResendWelcomeEmail.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Mail Sent Successfully'
                state.success = true;
            }
        },
        [userManagementResendWelcomeEmail.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },


        /////////////////////////// sendResetPassword ///////////////////////////
        [sendResetPassword.pending]: (state, action) => {
            state.loading = true;
            state.resMessage = null
            state.success = false;
        },
        [sendResetPassword.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Reset Password Link Sent Successfully'
                state.success = true;
            }
        },
        [sendResetPassword.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },



        /////////////////////////// logMeIn ///////////////////////////
        [logMeIn.pending]: (state, action) => {
            state.loading = true;
        },
        [logMeIn.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Successfully Login'
                state.success = true;

            }
        },
        [logMeIn.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },



        /////////////////////////// logInDetails ///////////////////////////
        [logInInfo.pending]: (state, action) => {

            console.warn("<<<< from pending logininfo >>>>", state.logininfo)
            console.warn("<<<< from pending resMessage >>>>", state.resMessage)
            state.failed = false;

            state.loading = true;
            state.logininfo = null;
            state.resMessage = null;
            state.userNotLoginYet = false;


        },
        [logInInfo.fulfilled]: (state, action) => {
            console.log("loginInfoBtn", action.payload)

            if (action.payload.status === "success") {
                state.loading = false;
                state.failed = false;
                state.resMessage = null;
                state.logininfo = action.payload?.results?.res ? action.payload.results.res : null;
                //    console.log("Fdlogininfo-----------",state.logininfo);

                console.warn("action.payload.results?.res?.length", action.payload.results?.res?.length)

                if (action.payload.status === "success" && action.payload.results?.res?.length === 0) {
                    state.failed = true;
                    state.loading = false;
                    state.logininfo = null;
                    state.resMessage = "Login info not found";

                }


            } else {
                state.failed = true;
                state.loading = false;
                state.resMessage = "Something Went Wrong";

            }
        },
        [logInInfo.rejected]: (state, action) => {
            state.loading = false;
            state.failed = false;
            state.logininfo = null;
            state.resMessage = 'Login information not found';

        },




        /////////////////////////// fetchUserRoles ///////////////////////////
        [fetchUserRoles.pending]: (state, action) => {

            state.loading = true;
            state.userRoles = [];
            state.resMessage = null;
        },
        [fetchUserRoles.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.userRoles = action.payload?.results?.res ? action.payload.results.res : null;
            }
        },
        [fetchUserRoles.rejected]: (state, action) => {
            state.loading = false;
            state.userRoles = [];
            state.resMessage = 'Login information not found';

        },



        /////////////////////////// addUserRoles ///////////////////////////
        [addUserRoles.pending]: (state, action) => {

            state.loading = true;
            state.userRoles = [];
            state.resMessage = null;
            state.roleAdded = false;

        },
        [addUserRoles.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.roleAdded = true;
                state.userRoles = action.payload?.results ? action.payload.results : null;
                state.resMessage = 'Assigned Successfully';

            }
        },
        [addUserRoles.rejected]: (state, action) => {
            state.loading = false;
            state.roleAdded = false;
            state.userRoles = [];
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },



        /////////////////////////// RoleNames ///////////////////////////
        [fetchUserRolesName.pending]: (state, action) => {

            state.loading = true;
            state.userRoleNames = [];
            state.resMessage = null;
        },
        [fetchUserRolesName.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.userRoleNames = action.payload?.results?.res ? action.payload.results.res : [];
                state.resMessage = 'Updated Successfully';

            }
        },
        [fetchUserRolesName.rejected]: (state, action) => {
            state.loading = false;
            state.userRoleNames = [];
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },


        /////////////////////////// fetchRoles ///////////////////////////
        [FetchRoles.pending]: (state, action) => {

            state.loading = true;
            state.fetchRoles = [];
            state.resMessage = null;
        },
        [FetchRoles.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.fetchRoles = action.payload?.results?.res ? action.payload.results.res.map((data) => ({ val: data.key, name: data.label })) : [];
                // state.resMessage = 'Updated Successfully';

            }
        },
        [FetchRoles.rejected]: (state, action) => {
            state.loading = false;
            state.fetchRoles = [];
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },



        /////////////////////////// roundRobin ///////////////////////////
        [roundRobin.pending]: (state, action) => {

            state.loading = true;
            state.roundRobinData = null;
            state.resMessage = null;
        },
        [roundRobin.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.roundRobinData = action.payload?.results?.res ? action.payload.results.res[0] : null;

            }
        },
        [roundRobin.rejected]: (state, action) => {
            state.loading = false;
            state.roundRobinData = null;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },




        /////////////////////////// roundRobinEdit ///////////////////////////
        [roundRobinEdit.pending]: (state, action) => {

            state.loading = true;
            state.roundRobinSuccess = false;
            state.resMessage = null;
        },
        [roundRobinEdit.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.roundRobinSuccess = true;
                // state.resMessage = 'Update Successfull';

            }
        },
        [roundRobinEdit.rejected]: (state, action) => {
            state.loading = false;
            state.roundRobinSuccess = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },




        /////////////////////////// FetchHMSettingModal ///////////////////////////
        [FetchHMSettingModal.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
        },
        [FetchHMSettingModal.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                // state.resMessage = 'Update Successfull';
                state.HMSettingModalData = action.payload?.results?.res ? action.payload.results.res[0] : null;

            }
        },
        [FetchHMSettingModal.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },


        /////////////////////////// FetchHMSettingAccessUpdate ///////////////////////////
        [FetchHMSettingAccessUpdate.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.HMSettingModalStatus = false
        },
        [FetchHMSettingAccessUpdate.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.HMSettingModalStatus = true

            }
        },
        [FetchHMSettingAccessUpdate.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },


        /////////////////////////// FetchTagDetails ///////////////////////////
        [FetchTagDetails.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.FetchTagDetailsData = []
        },
        [FetchTagDetails.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.FetchTagDetailsData = action.payload?.results?.res ? action.payload.results.res : [];

            }
        },
        [FetchTagDetails.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchTagDetailsData = []


        },



        /////////////////////////// updateTags ///////////////////////////
        [updateTags.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.tagUpdated = false;

        },
        [updateTags.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.tagUpdated = true;
                // state.FetchTagDetailsData.find((data) => data.key === action.meta?.arg?.tag_data?.key).status = action.meta.arg.tag_data?.status

                state.FetchTagDetailsData = state.FetchTagDetailsData.map((data) => ({ ...data, status: data.key === action.meta?.arg?.tag_data?.key ? action.meta.arg.tag_data?.status : 0 }))

            } else {
                state.loading = false;
                state.resMessage = 'Something Went Wrong';
                state.tagUpdated = false;

            }
        },
        [updateTags.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchTagDetailsData = []
            state.tagUpdated = false;



        },


        /////////////////////////// FetchTrainingStatus ///////////////////////////
        [FetchTrainingStatus.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.trainingData = [];
            state.userTrainingData = null

        },
        [FetchTrainingStatus.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.trainingData = action.payload?.results?.res ? action.payload.results.res : []
                state.userTrainingData = action.payload?.results?.userData ? action.payload.results.userData : null
            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
                state.userTrainingData = null
                state.trainingData = []


            }
        },
        [FetchTrainingStatus.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.trainingData = [];
            state.userTrainingData = null



        },


        /////////////////////////// FetchStages ///////////////////////////
        [FetchStages.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.FetchStages = []

        },
        [FetchStages.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.FetchStages = action.payload?.results?.res ? action.payload.results.res : []
            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
                state.FetchStages = []
            }
        },
        [FetchStages.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchStages = []

        },




        /////////////////////////// AssignStages ///////////////////////////
        [updateStages.pending]: (state, action) => {
            state.stageUpdated = false
            state.loading = true;
            state.resMessage = null;

        },
        [updateStages.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;

                console.warn("<<<<<<<<<<<< state.FetchStages >>>>>>>>", [...state.FetchStages])

                state.FetchStages = state.FetchStages.map((stages) => ({ ...stages, status: stages.key === action.meta.arg.stage_slug ? 1 : 0 }))
                state.stageUpdated = true;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

            } else {
                state.loading = false;
                state.stageUpdated = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

            }
        },
        [updateStages.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.stageUpdated = false

        },




        /////////////////////////// fetchContract ///////////////////////////
        [fetchContract.pending]: (state, action) => {
            state.contractLoader = true
            state.loading = true;
            state.resMessage = null;
            state.fetchContractData = [];

        },
        [fetchContract.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.contractLoader = false
                state.fetchContractData = action.payload?.results?.res ? action.payload.results.res : []
            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
                state.fetchContractData = []
            }
        },
        [fetchContract.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.fetchContractData = [];
        },



        /////////////////////////// rolesForUpgrade ///////////////////////////
        [rolesForUpgrade.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.userRolesNew = [];

        },
        [rolesForUpgrade.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.userRolesNew = action.payload?.results?.res ? action.payload.results.res : []
            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
                state.userRolesNew = []
            }
        },
        [rolesForUpgrade.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.userRolesNew = [];
        },



        /////////////////////////// upgradeRole ///////////////////////////
        [upgradeRole.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.roleUpgraded = false
        },
        [upgradeRole.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.roleUpgraded = true

            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            }
        },
        [upgradeRole.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },



        /////////////////////////// fetchContactTemplate ///////////////////////////
        [fetchContractTemplate.pending]: (state, action) => {

            state.loading = true;
            state.resMessage = null;
            state.contractTemplate = {};
            state.contractSend = false

        },
        [fetchContractTemplate.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.contractTemplate = action.payload?.results ? action.payload.results : {}
                state.contractSend = false

            } else {
                state.loading = false;
                state.contractTemplate = {}
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            }
        },
        [fetchContractTemplate.rejected]: (state, action) => {
            state.loading = false;
            state.contractTemplate = {}
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },


        /////////////////////////// fetchContactTemplate ///////////////////////////
        [sendContract.pending]: (state, action) => {
            state.loading = true;
            state.conMessage = null;
            state.contractSend = false
        },
        [sendContract.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.contractSend = true
                state.conMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            } else {
                state.loading = false;
                state.contractSend = false
                state.conMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            }
        },
        [sendContract.rejected]: (state, action) => {
            state.loading = false;
            state.contractSend = false
            state.conMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },



        // ################################# sign contract ####################

        [signContract.pending]: (state, action) => {
            state.loading = true;
            state.contractSigned = false
            state.contractSignedData = []
        },
        [signContract.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.contractSigned = true
                state.contractSignedData = action.payload?.results ? action.payload.results : []

            } else {
                state.loading = false;
                state.contractSigned = false
                state.contractSignedData = []

            }
        },
        [signContract.rejected]: (state, action) => {
            state.loading = false;
            state.contractSigned = false
            state.contractSignedData = []


        },



    }
})

export default userManagment.reducer;
export const { setSuccessAfterSubmit, setTokenRequiredStatus, setStatesBlank, clearContractData, clearContractModalData } = userManagment.actions;