
import { IconButton, LinearProgress, Tooltip } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';

const Notesview = ({notesData}) => {
  const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)
    console.log("notes",notesData);
  return (
    <>
    <div>
        
          <h3>
            <SummarizeIcon /> Notes {" "}
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              
              <Tooltip title="Search Email">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Search Email">
                <IconButton>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              
            </div>
          </h3>
          {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}
        {
          notesData.length > 0 ?(
            notesData.map((item)=>{
              return(
              <div className="user-activity" key={item._id}>
              
                <ul className="ua-data-block">
                <li>Notes: {item.notes} </li>
                  <li>Added by: {item.added_name} </li>
                  <li>Added On: { format(item.createdon_datetime,"dd-MM-yyyy HH:mm:ss") } </li>
                </ul>
          
              
            </div>
              )
            })
          ):<p>No activity found!</p>
        }
        
  
       
        
      </div>
    </>
  )
}

export default Notesview