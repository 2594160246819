import React, { useEffect, useState } from "react";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from "react-redux";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { format } from 'date-fns'


import { getEmailTeamplatedata } from "../../EmailTemplateManagement/EmailTemplateReducer";
import EmailSendDrower from "../../LeadManagement/EmailSendDrower";
import { useCookies } from "react-cookie";
import store from "../../../../store";
import SearchIcon from '@mui/icons-material/Search';


const EmailVew = ({ emailData, showDiv, queryParamId }) => {
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [dataCopy, setDataCopy] = useState({});
  const [openDrowerForEmail, setOpenDrowerForEmail] = useState(false);

    const dataRef = useSelector(state => (state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1) ? state.tableSlice?.tempRowData?.userslist1 : {});
    const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)
    const emailtemplateData = useSelector(state => (state.EmailTemplateReducer?.emailtemplateListData && state.EmailTemplateReducer?.emailtemplateListData.length > 0) ? state.EmailTemplateReducer.emailtemplateListData : []);
    const loaderForsendmail = useSelector(state => state.LeadReducer?.loaderForsendmail);
    const submistionstate = useSelector(state => state.LeadReducer?.submistionstate);
    // console.log("showDiv",emailData);


useEffect(()=>{
  setOpenDrowerForEmail(false)
},[])

  const HandelEmail = () => {

    let reqbody = {
      "condition": {
        "limit": 5,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": {
        // "added_for": addedForId
      },
      "count": false,
      "source": "",
    }
    setDataCopy(dataRef)
    store.dispatch(getEmailTeamplatedata(reqbody));

  }

  useEffect(() => {

    if (emailtemplateData.length > 0) {
      setOpenDrowerForEmail(true)
    }

  }, [emailtemplateData])

  const closeDrower = () => {
    setOpenDrowerForEmail(false)
  };


  return (
    <>
      <div>
        {showDiv == "EmailSend" && (
          <h3>
            <ForwardToInboxIcon /> Email Send{" "}
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              <Tooltip title="Send Email">
                <IconButton
                  className="filtersearch"
                  onClick={() => HandelEmail()}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Search Email">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </div>
          </h3>
        )}
        {showDiv == "EmailSendBy" && (
          <h3>
            <MailOutlineIcon /> Email Send By
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>

              <Tooltip title="Send Email">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </div>
          </h3>
        )}
        {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}
        {emailData.length > 0 &&
          emailData.map((x) => (
            <div className="user-activity" key={x._id}>
              {showDiv == "EmailSend" && (
                <ul className="ua-data-block">
                  <li>Mail: {x.email_subject} </li>
                  <li>Mail Sent Time: {x.createdon_datetime} </li>
                  <li>Mail Sent By: {x.sent_by_name} </li>
                  <li>Mail Receive To: {x.sent_to_name} </li>

                  <li>
                    Email Status :{" "}
                    {x.email_open === 1
                      ? x?.email_opened_at
                        ? `Email Open On ${format(
                          x?.email_opened_at,
                          "dd-MM-yyyy HH:mm:ss"
                        )}`
                        : " "
                      : "Email deliverd but not open yet"}
                  </li>
                  <li>
                    Mail Tracking: {x.email_tracking ? "Active" : "Inactive"}{" "}
                  </li>
                </ul>
              )}
              {showDiv == "EmailSendBy" && (
                <ul className="ua-data-block">
                  <li>Mail: {x.email_subject} </li>

                  <li>Mail Receive Time: {x.createdon_datetime} </li>

                  <li>Mail Receive To: {x.sent_to_name} </li>
                  <li>Mail Sent By: {x.sent_by_name} </li>
                  {/* <li>Email Status : {x.email_open===1 ? `Email Open On ${format(x?.email_opened_at, 'dd-MM-yyyy HH:mm:ss')}`:"Email deliverd but not open yet"}</li> */}
                </ul>
              )}
            </div>
          ))}
        {emailData.length === 0 && <p> No Data Found !</p>}
      </div>


      {/* ---- Email Send Drawer --- */}
      {openDrowerForEmail ? <EmailSendDrower
        open={openDrowerForEmail}
        close={closeDrower}
        data={emailtemplateData}
        addedById={userInfo?.userinfo?._id}
        addedForId={queryParamId}
        loading={loaderForsendmail}
        submistionstate={submistionstate}
      /> : ""}
      {/* --------------------------- */}
    </>
  );
};

export default EmailVew;
