import { LinearProgress, Paper } from '@mui/material'

import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setIpinfo } from '../../login/loginReducer';


import LandingPage1 from '../LandingPage/LandingPage1/LandingPage1'
import LandingPage2 from '../LandingPage/LandingPage2/LandingPage2'
import LandingPage3 from '../LandingPage/LandingPage3/LandingPage3';
import LandingPage4 from '../LandingPage/LandingPage4/LandingPage4';
import LandingPage5 from '../LandingPage/LandingPage5/LandingPage5';
import LandingPage6 from '../LandingPage/LandingPage6/LandingPage6';




function Home() {

  const pathname = useLocation();
  const location = useLocation();
  const dispatch = useDispatch();
  // console.log('Landdd',process.env);

  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=9797c42b93078a")
      .then((results) => results.json())
      .then((data) => {
        console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
        dispatch(setIpinfo(data));
      });
  }, []);
  return (
    <>
      {/* <Noaccesspage /> */}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'dxiSvO69jQgjH') && <LandingPage1 />}

      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'rQLIpATi8DDSn') && <LandingPage2 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 's6UVNi1CVhKMa') && <LandingPage5 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == '0sAQwKXAmAzB1') && <LandingPage4 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && (process.env.REACT_APP_LANDINGPAGE_SLUG == 'CKPMHcnh0ugN3' || process.env.REACT_APP_LANDINGPAGE_SLUG == 'DSqUHfhYLszrA')) && <LandingPage3 />}
      {(process.env.REACT_APP_LANDINGPAGE_SLUG && (process.env.REACT_APP_LANDINGPAGE_SLUG == 'FbqQrFdY3dNIC' || process.env.REACT_APP_LANDINGPAGE_SLUG == 'jLiUc37STmcj8')) && <LandingPage6 />}

      {/* <LandingPage1/>  */}
      {/* {location.pathname == "/landing-page2" ?   <LandingPage2/>: null}   */}

      {/* <p>Home page 1    </p> */}

      {/* <LandingPage1/>     
      <LandingPage2/>  
          */}


    </>
  )

}

export default Home
