import { Chip, IconButton, LinearProgress, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SegmentIcon from "@mui/icons-material/Segment";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { FetchTagDetails } from "../userList/userListReducer";
import ConfigureTagsModal from "../userList/configureTagsModal";
const TagLifeCycle = ({ LifeCycleDataTag,folderdata}) => {
  const [openTagModal,setOpenTagModal]=useState(false)
  const dispatch = useDispatch()
  const loading = useSelector((state) =>
    state?.userFolderViewReducer?.loading
      ? state.userFolderViewReducer.loading
      : false
  );
  const TagManageModalData = useSelector(state => (state.userManagment?.FetchTagDetailsData) ? state.userManagment?.FetchTagDetailsData : []);

const handelAddTag = () =>{
  let roles = Object.keys(folderdata?.roles).map((key) => key)
  const data = {
    "_id": folderdata._id,
    "roles": { $in: roles },
    "project": {
        "subject": 1
    },
}
dispatch(FetchTagDetails(data))
}
useEffect(() => {
  if (TagManageModalData.length > 0) {
    setOpenTagModal(true)

  }
}, [TagManageModalData])




  console.log("TagLifeCycle", LifeCycleDataTag);
  return (
    <>
      <div className="blocks">
        <h3>
          <SegmentIcon /> Tag Life Cycle{" "}
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Search Email">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Tag">
                <IconButton onClick={handelAddTag} >
                  <LocalOfferIcon  />
                </IconButton>
              </Tooltip>
          </div>
        </h3>
        {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}

        {LifeCycleDataTag.length > 0 ? (
          <div>
            { 
              LifeCycleDataTag?.map((x) => (
                <ul className="Fv-tags">
                  <li className="tagsBlck">
                    <Chip label={x.tag_name} variant="outlined" />
                    <QuestionMarkIcon className="qIcon" />
                    {/* <p className="chipDesBuble">
                      {x.tag_description} <br />
                      Added by : {x.added_by_name} On{" "}
                      {format(x.createdon_datetime, "dd-MM-yyyy HH:mm")}
                    </p> */}
                  </li>
                </ul>
              ))
            }
          </div>
        ) : (
          <p> No tag assigned!</p>
        )}
      </div>


      {/* {openTagModal ? (<ConfigureTagsModal
                toogle={openTagModal}
                // tooglefunc={setOpenTagModal}
                tags={TagManageModalData}
                UserId={folderdata._id}
                Userinfo={folderdata}
            />) : ""} */}






    </>
  );
};

export default TagLifeCycle;
