import { Box, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import store from '../../store';
function Success() {

  return (
   <div>
     <div className='thankYou_wrpr'>

            <div className="bgdesgn1"></div>
            <div className="bgdesgn2"></div>
            <div className="bgdesgn3"></div>
            <div className="bgdesgn4"></div>
            <div className="bgdesgn5"></div>
            <div className="bgdesgn6"></div>
            <div className="bgdesgn7"></div>
            <div className="bgdesgn8"></div>
         <div className='thankyou-container'>
                    <div className='thankyou-block'>

                        <div className='content center'>
      <h1>Thank You For Submited Form</h1> 
      <p>Greeting for your interest in Aspire. Our team will verify your details and contact you as soon as possible.</p>
   </div>
   </div>
   </div>
   </div>
   </div>
  );
}

export default Success
