import { Form } from '../../../../form/Form';
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { getroledata, clearroledata, getfaqcategorydata, cleareditfaqdata, geteditfaqdata } from './FaqReducer';
import store from '../../../../store';
import { IconButton, Modal } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { Box } from '@mui/system';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import {
    removeField,
    resetExternalFormData,
    setExternalFormData,
    setField,
} from "../../../../form/formReducer";

import { cloneDeep } from "lodash";
import { FetchRoles } from "../../userManagement/userList/userListReducer";
import { setSnackbar } from '../../../Layout/layoutReducer';
import { useLocation, useParams } from 'react-router-dom';

function AddEditFaq() {
    const [selecttypeArry, setselecttypeArry] = useState([]);
    const [rolArry, setrolArry] = useState([]);
    const [openMoadl, setOpenMoadl] = React.useState(false);
    const dispatch = useDispatch();
    const [extrnalformeditdata, setExtrnalformeditdata] = useState(null);
    let leadroleObj = { val: "is_lead", name: "Lead" };
    const pathname = useLocation();
    const { id } = useParams();



    // let roleData = cloneDeep(getRoles);
    // roleData?.push(leadroleObj);


    const closeModal = () => {
        setOpenMoadl(false);
    };
    const openModal = () => {
        setOpenMoadl(true);
    };
    let reqbodyforrole = {
        "condition": {
            "limit": 100,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": { "status": 1 },
        "project": {},
        "token": ""
    }

    let roledata = useSelector(state => (state.FaqReducer?.roledata && state.FaqReducer?.roledata?.length > 0) ? state.FaqReducer.roledata : []);
    let faqcategorydata = useSelector(state => (state.FaqReducer?.faqcategorydata && state.FaqReducer?.faqcategorydata?.length > 0) ? state.FaqReducer.faqcategorydata : []);
    
    const attachmentBtnClick = useSelector((state) => state.formSlice?.formCustomButtonClicked?.faqAddEditForm?.attachment ? state.formSlice.formCustomButtonClicked?.faqAddEditForm?.attachment
            : null
    );

    const selectFileType = useSelector((state) =>
        state.formSlice?.formData?.informationFileSent?.type
            ? state.formSlice?.formData?.informationFileSent?.type
            : ""
    );

    const externalFormSubmissionState = useSelector((state) =>
        state.formSlice.formSubmissionState?.informationFileSent
            ? state.formSlice.formSubmissionState.informationFileSent
            : 0
    );
    const externalFormData = useSelector((state) =>
        state.formSlice.formData?.informationFileSent
            ? state.formSlice.formData.informationFileSent
            : null
    );

    



    const editdata = useSelector(state => (state.FaqReducer?.editfaqdata && state.FaqReducer?.editfaqdata.length > 0) ? state.FaqReducer.editfaqdata[0] : null); // ########## Fetch Edit Value From Store Which Display in Form ########### //
    const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);    
    const resolved = useSelector(state => state.ResolveReducer?.resolved[`/edit-faq/${id}`] ? state.ResolveReducer.resolved[`/edit-faq/${id}`] : false)
    const submitbtnclick = useSelector(state => state.formSlice.formSubmissionState.faqAddEditForm ? state.formSlice.formSubmissionState.faqAddEditForm : 0)

    useEffect(() => {
        if (id !== undefined && id !== null && editdata !== null) {
            if (submitbtnclick == 2) {
                dispatch(setSnackbar({ open: true, message: "Update Successfully !", status: 'success' }))
            };
        }
        else {
            if (submitbtnclick == 2) {
                dispatch(setSnackbar({ open: true, message: "Add Successfully !", status: 'success' }))
            };
        }


    }, [submitbtnclick])

    useEffect(() => {

        if (!resolved) {

            if (id !== undefined && pathname !== null && pathname.pathname !== undefined) {
                console.log("ppp>>", editdata)
                store.dispatch(geteditfaqdata(id));

            }

        }

        return () => {
            store.dispatch(cleareditfaqdata())
            store.dispatch(resetresolvenew('/edit-faq/:id'))
        }
    }, [])
    console.log("editdata", editdata);
 




    useEffect(() => {
        if (attachmentBtnClick) {
            setOpenMoadl(true);
        }
    }, [attachmentBtnClick]);




    useEffect(() => {
        console.log("runEffect-------->", selectFileType);
        if (selectFileType === "video-youtube") {
            console.log("runEffect-------->1", selectFileType);

            const field1 = {
                id: 15,
                heading: "YouTube Video",
                label: "Youtube Link Address",
                name: "youtube",
                className: "inputBlock inputBlock1line",
                type: "youtube",

                defaultValue:
                    extrnalformeditdata && extrnalformeditdata[0]?.youtube
                        ? extrnalformeditdata[0].youtube
                        : null,
            };

            dispatch(
                setField({
                    formId: "informationFileSent",
                    after: "type",
                    fieldData: field1,
                })
            );

            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "vimeo" }));
            }, 100);

            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "video" }));
            }, 200);
            setTimeout(() => {
                dispatch(
                    removeField({ formId: "informationFileSent", name: "videofile" })
                );
            }, 300);
            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "file" }));
            }, 400);
        } else if (selectFileType === "video-vimeo") {
            const field = {
                id: 13,
                // heading: "Vimio",
                label: "Vimeo",
                name: "vimeo",
                className: "inputBlock inputBlock3line",
                type: "text",
                defaultValue:
                    extrnalformeditdata && extrnalformeditdata[0]?.vimeo
                        ? extrnalformeditdata[0].vimeo
                        : null,
            };

            dispatch(
                setField({
                    formId: "informationFileSent",
                    after: "type",
                    fieldData: field,
                })
            );

            setTimeout(() => {
                dispatch(
                    removeField({ formId: "informationFileSent", name: "youtube" })
                );
            }, 100);

            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "video" }));
            }, 200);
            setTimeout(() => {
                dispatch(
                    removeField({ formId: "informationFileSent", name: "videofile" })
                );
            }, 300);
            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "file" }));
            }, 400);
        } else if (selectFileType === "video-link") {
            const field = {
                id: 23,
                // heading: "Vimio",
                label: "Video",
                name: "video",
                className: "inputBlock inputBlock3line",
                type: "text",

                // defaultValue:
                // editdata &&
                // Object.keys(editdata).length > 0 &&
                // editdata.attachment !== undefined
                //   ? editdata.attachment
                //   : undefined,
            };

            dispatch(
                setField({
                    formId: "informationFileSent",
                    after: "type",
                    fieldData: field,
                })
            );

            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "vimeo" }));
            }, 100);

            setTimeout(() => {
                dispatch(
                    removeField({ formId: "informationFileSent", name: "youtube" })
                );
            }, 200);
            setTimeout(() => {
                dispatch(
                    removeField({ formId: "informationFileSent", name: "videofile" })
                );
            }, 300);
            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "file" }));
            }, 400);
        } else if (selectFileType === "video-upload") {
            const field = {
                id: 16,
                heading: "Upload File",
                label: "Upload File",
                name: "videofile",
                className: "inputBlock inputBlock2line",
                type: "fileUploader",
                accept: "video/*", //////// Write properly /////////
                bucket: "uploaded-files-react",
                path: "aspireuploadimage/",
                base_url: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
                endpoint: "requestUploadURL",
                deleteEndpoint: "deletefilefromBucket",

                // defaultValue:
                // editdata &&
                // Object.keys(editdata).length > 0 &&
                // editdata.file !== undefined
                //   ? editdata.file
                //   : undefined,
            };

            dispatch(
                setField({
                    formId: "informationFileSent",
                    after: "type",
                    fieldData: field,
                })
            );

            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "vimeo" }));
            }, 100);

            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "video" }));
            }, 200);
            setTimeout(() => {
                dispatch(
                    removeField({ formId: "informationFileSent", name: "youtube" })
                );
            }, 300);
            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "file" }));
            }, 400);
        } else if (selectFileType === "file") {
            const field = {
                id: 14,
                heading: "Upload File",
                label: "File Link",
                name: "file",
                className: "inputBlock inputBloc2kline",
                type: "fileUploader",
                accept: ".pdf,image/*,.doc,.docx,video/*,audio/*", //////// Write properly /////////
                bucket: "uploaded-files-react",
                path: "aspireuploadimage/",
                base_url: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
                endpoint: "requestUploadURL",
                deleteEndpoint: "deletefilefromBucket",

                // defaultValue:
                // editdata &&
                // Object.keys(editdata).length > 0 &&
                // editdata.attachment !== undefined
                //   ? editdata.attachment
                //   : undefined,
            };

            dispatch(
                setField({
                    formId: "informationFileSent",
                    after: "type",
                    fieldData: field,
                })
            );

            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "vimeo" }));
            }, 100);

            setTimeout(() => {
                dispatch(removeField({ formId: "informationFileSent", name: "video" }));
            }, 200);
            setTimeout(() => {
                dispatch(
                    removeField({ formId: "informationFileSent", name: "videofile" })
                );
            }, 300);
            setTimeout(() => {
                dispatch(
                    removeField({ formId: "informationFileSent", name: "youtube" })
                );
            }, 400);
        }
        console.log("runEffect-------->13", selectFileType);
    }, [selectFileType]);

    const findfileextenssion = (val) => {
        console.log("val11", val);
        var re = /(?:\.([^.]+))?$/;

        let extension = re.exec(val)[1];
        console.log("val11==", extension);
        if (
            extension != "" &&
            extension !== undefined &&
            (extension == "webp" ||
                extension == "WEBP" ||
                extension == "png" ||
                extension == "PNG" ||
                extension == "jpg" ||
                extension == "JPG" ||
                extension == "jpeg" ||
                extension == "JPEG" ||
                extension == "gif" ||
                extension == "GIF" ||
                extension == "bmp" ||
                extension == "BMP" ||
                extension == "ico" ||
                extension == "ICO" ||
                extension == "svg" ||
                extension == "SVG" ||
                extension == "tif" ||
                extension == "TIF" ||
                extension == "tiff" ||
                extension == "TIFF")
        ) {
            return "img";
        }
        if (
            extension != "" &&
            extension !== undefined &&
            (extension == "mp4" ||
                extension == "MP4" ||
                extension == "mkv" ||
                extension == "MKV" ||
                extension == "wemb" ||
                extension == "WEMB" ||
                extension == "flv" ||
                extension == "FLV" ||
                extension == "avi" ||
                extension == "AVI" ||
                extension == "mov" ||
                extension == "MOV" ||
                extension == "swf" ||
                extension == "SWF" ||
                extension == "wmv" ||
                extension == "WMV")
        ) {
            return "video";
        }
        if (
            extension != "" &&
            extension !== undefined &&
            (extension == "mp3" ||
                extension == "MP3" ||
                extension == "aif" ||
                extension == "AIF" ||
                extension == "cda" ||
                extension == "CDA" ||
                extension == "mid" ||
                extension == "MID" ||
                extension == "mpa" ||
                extension == "MPA" ||
                extension == "ogg" ||
                extension == "OGG" ||
                extension == "wav" ||
                extension == "WAV" ||
                extension == "wma" ||
                extension == "WMA" ||
                extension == "wpl" ||
                extension == "WPL")
        ) {
            return "audio";
        }
        if (
            extension != "" &&
            extension !== undefined &&
            (extension == "pdf" ||
                extension == "PDF" ||
                extension == "tex" ||
                extension == "TEX" ||
                extension == "txt" ||
                extension == "TXT")
        ) {
            return "pdf";
        }
        if (
            extension != "" &&
            extension !== undefined &&
            (extension == "doc" ||
                extension == "DOC" ||
                extension == "docx" ||
                extension == "DOCX" ||
                extension == "xlsx" ||
                extension == "XLSX" ||
                extension == "xlsm" ||
                extension == "XLSM" ||
                extension == "pptx" ||
                extension == "PPTX" ||
                extension == "csv" ||
                extension == "CSV" ||
                extension == "xml" ||
                extension == "XML" ||
                extension == "odp" ||
                extension == "ODP" ||
                extension == "ppt" ||
                extension == "PPT" ||
                extension == "pps" ||
                extension == "PPS" ||
                extension == "ods" ||
                extension == "ODS" ||
                extension == "xls" ||
                extension == "XLS" ||
                extension == "odt" ||
                extension == "ODT" ||
                extension == "rtf" ||
                extension == "RTF")
        ) {
            return "doc";
        }
        // return false;
    };

    useEffect(() => {
        if (externalFormSubmissionState === 4) {
            const data = cloneDeep(externalFormData);
            delete data.formId;
            console.log("externalFormSubmissionState===================>", data);
            const insertionData = Object.keys(data)
                .filter((key) => data[key] !== undefined)
                .map((key) => {
                    console.log("externalFormSubmissionState22222", key, data[key]);

                    const fieldData = attachmentform.current.fields.find(
                        (data) => data.name === key
                    );
                    // console.log("externalFormSubmissionState+++", fieldData);

                    return `<p><span>${fieldData?.label ? fieldData?.label : fieldData?.placeholder
                        } : </span><span> ${key == "youtube"
                            ? `<img src=${data[key]["thumbnail_link"]}>`
                            : findfileextenssion(data[key]["url"]) == "img"
                                ? `<img src=${data[key]["url"]}>`
                                : findfileextenssion(data[key]["url"]) == "video"
                                    ? `<video controls>
        <source src=${data[key]["url"]}>

        </video>`
                                    : findfileextenssion(data[key]["url"]) == "pdf"
                                        ? `<embed src="${data[key]["url"]}" width="800px" height="2100px" class='docshowifram'/>`
                                        : findfileextenssion(data[key]["url"]) == "doc"
                                            ? `<iframe src='https://view.officeapps.live.com/op/embed.aspx?src=${data[key]["url"]}' width='1366px' height='623px' frameborder='0' class='docshowifram'/>
        '</iframe>`
                                            : findfileextenssion(data[key]["url"]) == "audio"
                                                ? `<audio controls>
        <source src=${data[key]["url"]}>

        </audio>`
                                                : data[key]
                        } </span></p>`;
                });
            // console.log("insertionData===============>", insertionData, formDataSet)
            dispatch(
                setExternalFormData({
                    formId: "InformationAddEdit",
                    fieldName: "attachment",
                    formData: {
                        label: "Manage Attached Data",
                        value: data,
                        innerHtml: insertionData.join(""),
                    },
                    index: extrnalformeditdata ? extrnalformeditdata[0] : null,
                    multiple: true,
                })
            );
            // setOpenExternalForm(false)
            // setFormDataSet(null)
            setOpenMoadl(false);
            // setTimeout(() => {
            //   dispatch(removeField({ formId: "informationFileSent", name: "vimeo" }))
            // }, 200);

            // setTimeout(() => {
            //   dispatch(removeField({ formId: "informationFileSent", name: "video" }))
            // }, 180);
            // setTimeout(() => {
            //   dispatch(removeField({ formId: "informationFileSent", name: "videofile" }))
            // }, 200);
            // setTimeout(() => {
            //   dispatch(removeField({ formId: "informationFileSent", name: "youtube" }))
            // }, 250);
            // setTimeout(() => {
            //   dispatch(removeField({ formId: "informationFileSent", name: "file" }))
            // }, 350);
        }
    }, [externalFormSubmissionState]);

    // useEffect(() => {
    //     if (editdata && id) {
    //         editdata?.attachment.forEach((data) => {
    //             const insertionData = Object.keys(data)
    //                 .filter((key) => data[key] !== undefined)
    //                 .map((key) => {
    //                     console.log("externalFormSubmissionState333", key, data[key]);

    //                     const fieldData = attachmentform.current.fields.find(
    //                         (data) => data.name === key
    //                     );
    //                     console.log("externalFormSubmissionState+++", fieldData);

    //                     return `<p><span>${fieldData?.label ? fieldData?.label : fieldData?.placeholder
    //                         } : </span><span> ${key == "youtube"
    //                             ? `<img src=${data[key]["thumbnail_link"]}>`
    //                             : findfileextenssion(data[key]["url"]) == "img"
    //                                 ? `<img src=${data[key]["url"]}>`
    //                                 : findfileextenssion(data[key]["url"]) == "video"
    //                                     ? `<video controls>
    //     <source src=${data[key]["url"]}>

    //     </video>`
    //                                     : findfileextenssion(data[key]["url"]) == "pdf"
    //                                         ? `<embed src="${data[key]["url"]}" width="800px" height="2100px" class='docshowifram'/>`
    //                                         : findfileextenssion(data[key]["url"]) == "doc"
    //                                             ? `<iframe src='https://view.officeapps.live.com/op/embed.aspx?src=${data[key]["url"]}' width='1366px' height='623px' frameborder='0' class='docshowifram'/>
    //     '</iframe>`
    //                                             : findfileextenssion(data[key]["url"]) == "audio"
    //                                                 ? `<audio controls>
    //     <source src=${data[key]["url"]}>

    //     </audio>`
    //                                                 : data[key]
    //                         } </span></p>`;
    //                 });

    //             // console.log("insertionData===============>", insertionData, formDataSet) : key=='file'? ` <h2>hi </h2>`

    //             dispatch(
    //                 setExternalFormData({
    //                     formId: "InformationAddEdit",
    //                     fieldName: "attachment",
    //                     formData: {
    //                         label: "Manage Attached Data",
    //                         value: data,
    //                         innerHtml: insertionData.join(""),
    //                     },
    //                     multiple: true,
    //                 })
    //             );
    //         });

    //         return () => {
    //             dispatch(
    //                 resetExternalFormData({
    //                     formId: "InformationAddEdit",
    //                     fieldName: "attachment",
    //                 })
    //             );
    //             // dispatch(cleareditdata());
    //         };
    //     }
    // }, [editdata]);




    useEffect(() => {
        store.dispatch(getroledata(reqbodyforrole));
        store.dispatch(getfaqcategorydata(reqbodyforrole));
       
    }, [])
    

    useEffect(() => {

        if (roledata && roledata.length > 0) {
            let arr = [];
            for (let i in roledata) {
                arr.push({ val: roledata[i]._id, name: roledata[i].name })
            }
            setrolArry(arr)
        }

    }, [roledata])
    console.log("roledata---->>", rolArry);


    useEffect(() => {

        if (faqcategorydata && faqcategorydata.length > 0) {
            let arr = [];
            for (let i in faqcategorydata) {
                arr.push({ val: faqcategorydata[i]._id, name: faqcategorydata[i].category_name })
            }
            setselecttypeArry(arr)
        } else {
            let arr = [];
            arr.push({ val: '', name: 'No Active Category Found' })
            setselecttypeArry(arr)
        }
        
    }, [faqcategorydata])
    
    
    console.log("selecttypeArry>>>", selecttypeArry);
   


    useEffect(() => {

    }, [selecttypeArry])

   

  

    const dataform = {
        id: 'faqAddEditForm',
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "manage-faq/add-update-faq",
        urlPathOnSuccessfulFormSubmission: "/faq",
        submitBtnName: "Submit",
        cancelBtnName: "Cancel",
        formButtonClass: "Formsubmit_button",
        formWrapperClass: "backend_addform addAdmin",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        formAdditionalSubmissionData: {

            _id: (id !== undefined && id !== null) ? id : undefined,
            added_by: user_id
        },

        fields: [
            {
                id: 0,
                heading: "Question",
                label: "Question",
                name: "question",
                // className: "inputBlock inputBlock1line",
                className: 'inputBlock inputBlock1line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
                //   minLength: "Minimum length should be 15",
                //   custom: "Value is Invalid"
                defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.question !== undefined) ? editdata.question : undefined,

            },
            {
                id: 1,
                heading: "Answer",
                label: "SunEditor",
                name: "answer",
                type: "sunEditor",
                className: "inputBlock1line",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    //   minLength: "Minimum length should be 15",
                    //   custom: "Value is Invalid"
                },

                placeholder: "Please type here...",
                ////////////////////////////// Mandatory or no options will be available /////////////////////
                editorWidth: 300,
                buttonListOptions: [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                ],
                defaultValue: (editdata && editdata.answer !== undefined) ? editdata.answer : undefined,

            },
            {
                id: 2,
                heading: "Select Caregory",
                label: "Select Caregory",
                name: "category",
                className: ' inputBlock inputBlock2line',
                type: 'select',
                // multiple: true,
                checkbox: false,
                other: false,
                rules: { required: true },
                values: selecttypeArry,
                defaultValue: (editdata && editdata.category !== undefined) ? editdata.category : undefined,
                errorMessage: {
                    required: "This Field is Required",
                },

            },
            {
                id: 3,
                heading: "Select User Roll",
                label: "Select User Roll",
                name: "user_role",
                className: ' inputBlock inputBlock2line',
                type: 'select',
                // multiple: true,
                checkbox: false,
                other: false,
                rules: { required: true },
                values: rolArry,
                defaultValue: (editdata && editdata.user_role !== undefined) ? editdata.user_role : undefined,
                errorMessage: {
                    required: "This Field is Required",
                },

            },
            {
                id: 19,
                label: "Attachments",
                name: "attachment",
                // className: "inputBlock inputBlock2ine",
                className: "button ",
                type: "button",
                // startIcon: "edit",
                // endIcon: "delete",
                // defaultValue:
                //     editdata &&
                //         Object.keys(editdata).length > 0 &&
                //         editdata.attachment !== undefined
                //         ? editdata.attachment
                //         : undefined,
                // rules: { required: true },
                // errorMessage: {
                //     required: "This Field is Required",
                // },
            },



            {
                id: 5,
                heading: "Priority",
                label: "priority",
                name: "priority",
                className: 'inputBlock inputBlock2line',
                type: "text",
                inputType: "number",/////////////// If not mentioned default will be text //////////////
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },
                defaultValue: (editdata && editdata.priority !== undefined) ? editdata.priority : undefined,
            },
            {
                id: 4,
                // heading: "Status",
                // label: "Status",
                name: "status",
                className: 'inputBlock1line',
                type: 'singleCheckbox',
                values: { key: 1, val: 'Active' },
                // rules: { required: true },
                // errorMessage: {
                //   required: "This Field is Required",
                // },
                defaultValue: (editdata && editdata.status !== undefined) ? Boolean(editdata.status) : false,

            },


        ],
        customButtons: [
            {
                id: 'jobgoback001',
                label: 'Go Back',
                className: 'default',
                type: 'button',
            },
        ]


    }

    const attachmentform = useRef({
        id: "informationFileSent",
        // api_url: process.env.REACT_APP_API_URL,
        // endPoint: "info-link/add-update-info-link",
        // urlPathOnSuccessfulFormSubmission: "/list-information",
        submitBtnName: "Submit",
        cancelBtnName: "Cancel",
        formButtonClass: "Formsubmit_button",
        formWrapperClass: "backend_addform addAdmin attachfile",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",

        // formAdditionalSubmissionData: {
        //   _id: id !== undefined && id !== null ? id : undefined,
        // },

        fields: [
            {
                id: 0,
                // heading: "TITLE",
                label: "Title",
                name: "title",
                className: "inputBlock inputBlock1line",
                type: "text",

                // defaultValue:editdata &&Object.keys(editdata).length > 0 &&editdata.title !== undefined
                //     ? editdata.title
                //     : undefined,

                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
            },

            {
                id: 1,
                // heading: "Description",
                placeholder: "Description",
                name: "description",
                className: "inputBlock inputBlock1line",
                type: "textarea",
                // rules: { required: true },
                // errorMessage: {
                //   required: "This Field is Required",
                // },
                // defaultValue:
                //     extrnalformeditdata && extrnalformeditdata[0]?.description
                //         ? extrnalformeditdata[0].description
                //         : null,

                //   minLength: "Minimum length should be 15",
                //   custom: "Value is Invalid"
            },
            {
                id: 2,
                heading: "Choose File Type",
                label: "Options",
                name: "type",
                className: "inputBlock inputBlock1line",
                type: "select",
                multiple: false,
                // checkbox: true,
                // other: true,
                // rules: { required: true },
                values: [
                    { val: "video-youtube", name: "video-youtube" },
                    { val: "video-vimeo", name: "video-vimeo" },
                    { val: "video-link", name: "video-link" },
                    { val: "video-upload", name: "video-upload" },
                    { val: "file", name: "file" },
                ],
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid",
                },
                sx: { m: 1, minWidth: 120 },
            },
        ],
    });
    return (
        <>
            <h1 className='page_heading'>ADD FAQ</h1>
            <div className="dataform2_wrapper">
                <div className='adminformbody'>
                    <Form formData={dataform} />
                </div>
            </div>

            <div>
                <Modal
                    classname="image_card_modal modalBaseStyle"
                    open={openMoadl}
                    onClose={closeModal}
                    closeAfterTransition
                >
                    <Box className="modalBaseBox largeModal">
                        <IconButton className="modal_icon_button" onClick={closeModal}>
                            <CloseIcon className="modal_close_icon" />
                        </IconButton>

                        <div className="heading">
                            <h2>Attachments</h2>
                        </div>

                        <div className="dataform1 addedit_wrp">
                            <div className="dataform1_wrapper">
                                <div className="adminformbody">
                                    <Form formData={attachmentform.current} />
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default AddEditFaq
