import React, { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FolderIcon from '@mui/icons-material/Folder';
import { format } from 'date-fns'

import LoginIcon from '@mui/icons-material/Login';
import { IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';

export const FollowUpCall = ({followUpCallList}) => {
    const [open, setOpen] = React.useState(false); // snackbar const
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    console.log("followUpCallList", followUpCallList)
    const loaderForFollowUp = useSelector(state=>state.LeadReducer?.loaderForFollowUp?state.LeadReducer.loaderForFollowUp:false)
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        // setErrPopup(false);
        // setErrMsgCopy(null);
    };

    console.log(followUpCallList,"followUpCallList");
    
  return (
    <>

            <div>
                <h3>
                    <LocalPhoneIcon /> Follow Up Call
                    <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              <Tooltip title="Search Email">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </div>
                </h3>
                {loaderForFollowUp ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


                {followUpCallList.length > 0 ? followUpCallList.map((x) =>(
                <div className='user-activity'>

                       <ul className='ua-data-block'> 
                           {/* <li className='heading'>  {x.activity_type == 'login' ? (<LoginIcon/>) : x.activity_type == 'tag_assigned' ? (<LocalOfferIcon />) : x.activity_type == 'folder_accessed' || x.activity_type == 'folder_accessed_by' ? (<FolderIcon />) : ' '}    {x.activity_type ? x.activity_type : 'N/A'} </li>  */}
                            {/* <li>Added By:  {format(x.added_by, 'dd-MM-yyyy HH:mm:ss')} </li> */}
                            <li>Added For:  {x.added_for_name } </li>
                            <li>Added By:  {x.added_by_name} </li>
                            <li>Date:  { moment(x.date).format('MMMM Do YYYY') } </li>
                            <li>Time:  {x.start_time} </li>
                            <li>Notes:  {x.notes} </li>
                             {/* <li>Activity: {x.activity}</li>  */}
                        </ul> 

                   </div>)) : <p> No Data Found !</p>}

            </div>




            {open ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={queryParamId ? "Updated Sucessfully" : " "}
            
            />) : ""}

        </>
  )
}
