import { Paper, TableContainer } from '@mui/material';
import React, { useRef,useEffect } from 'react'
import { useSelector } from 'react-redux';
import ListingTable from '../../../listing/listing';

export default function EmailSentList({listData,setSearchGridData}) {

  let mindate = useSelector((state) =>
  state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {}
);
let maxDate = useSelector((state) =>
  state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {}
);

let gridData = useSelector(state=>state.tableSlice?.otherResData?.EmailtableDataDashboard?.grid_data ? state.tableSlice.otherResData.EmailtableDataDashboard.grid_data: [])

      var modifyTableHeaders = [
        { val: "sent_by_name", name: "Sender" },
        { val: "sent_by_email", name: "Sender's Email" },
        { val: "sent_to_name", name: "Recipient" },
        { val: "sent_to_email", name: "Recipient's Email" },
        { val: "sent_by_phone", name: "Sender's Phone" },
        { val: "sent_to_phone", name: "Recipient's Phone" },
    
      ];

      useEffect(()=>{
        setSearchGridData(gridData)
        console.log("gridData",gridData);
      },[JSON.stringify(gridData)])

      let EmailtableDataDashboard = useRef({
        tableId: "EmailtableDataDashboard",
        tableTitle: "Email Sent List View",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
          url: process.env.REACT_APP_API_URL,
          endPoint: "users/admin-dashboard-list-grid",
          deleteSingleUserEndpoint: "",
          tableCountEndpoint: "users/admin-dashboard-list-grid",
        },
    
        reqBody: {
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            field: "_id",
            type: "desc",
          },
          searchcondition: {
            createdon_datetime: {
              $lte: maxDate,
            },
          },
          source:"assign_emails",
          type:"email_sent",
          project: {},
          token: "",
          count: false,
        },
        deleteSingleUserReqBody: {
          source: "users",
          secret: "AZ|lepL`",
        },
        deleteMultipleReqBody: {
          source: "users",
          secret: "AZ|lepL`",
        },
    
        //******************************** By Default Rows per page option will be available**************************//
    
        rowsPerPageOptions: [5],
        sortFields: ["sent_by_name", "sent_by_email", "sent_to_name", "sent_to_email"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
          {
            id: "delete",
            label: "Delete",
            type: "api",
            category: "modal",
            icon_type: "delete",
            className: "delete_data",
            modalClassName: "delete_modal",
            modalTitle:
              "<p>Are you sure that you want to delete this record(s)?</p>",
            btn_label: ["Yes", "No"],
            api_data: {
              base_url: process.env.REACT_APP_API_URL,
              endpoint: "",
              body: {
                source: "users",
                secret: "AZ|lepL`",
                token:
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
              },
            },
          },
          {
            id: "status",
            label: "Change Status",
            type: "api",
            category: "status",
            icon_type: "toggle_off",
            className: "status_data",
            modalClassName: "delete_modal",
            modalHeader: "<p> Alert !!</p>",
            modalTitle: "<p>Are you sure you want to change the status ?</p>",
            btn_label: ["Active", "Inactive"],
            api_data: {
              base_url: process.env.REACT_APP_API_URL,
              endpoint: "",
              body: {
                source: "users",
                secret: "AZ|lepL`",
                token:
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
              },
            },
          },
        ],
        buttons: [
       
          {
            id: "summarize_icon",
            label: "Notes",
            type: "other",
            name: "summarize_icon",
            title: "Notes",
          },
          {
            id: "folder_icon",
            label: "Folder View",
            type: "other",
            name: "folder_icon",
            title: "Folder View",
          },
          {
            id: "assessment_icon",
            label: "Training Report",
            type: "other",
            name: "assessment_icon",
            title: "Training Report",
          },
          {
            id: "streetview_icon",
            label: "Leads",
            type: "other",
            name: "streetview_icon",
            title: "Leads",
          },
        ],
        deleteModal: {
          modalClassName: "modalblock",
        },
    
        searchData: {
          heading: "Email Sent Search",
          type: "drawer",
          formData: {
            id: 'searchForm',
            formWrapperClass: "formWrapperClass",
            formInlineCLass: "userForm", 
            formButtonClass: "submitbtnsection",
            submitBtnName: "Search",
            resetBtnName: "Refresh",
            submitBtnEndIcon: "search",
            resetBtnEndIcon: "replay",
            resetForm: true,
            formAdditionalSubmissionData: {},
            fields: [
              {
                id: 1,
                label: "Search By Sender",
                name: "name",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col2 ',
                base_url: process.env.REACT_APP_API_URL + "dashboard-cards/email-sent",
                reqBody: {
                  "condition": {
                      "limit": 5,
                      "skip": 0
                  },
                  "sort": {
                      "type": "asc",
                      "field": "email"
                  },
                  "searchcondition": {
                    createdon_datetime: {
                      $lte: maxDate,
                    },
                     
                  },
                  "type": "sent_by_name",
                  "source": "assign_emails"
              },
                sx: { m: 1, minWidth: 300 },
                // payloadFormat: { key: "_id", param: "$regex", options: "i" },
                payloadFormat: { key: "added_by" },
    
              },
           
              {
                id: 2,
                label: "Search By Sender's Email ",
                name: "email",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col2 ',
                base_url: process.env.REACT_APP_API_URL + "dashboard-cards/email-sent",
                reqBody:{
                  "condition": {
                      "limit": 5,
                      "skip": 0
                  },
                  "sort": {
                      "type": "asc",
                      "field": "email"
                  },
                  "searchcondition": {
                    createdon_datetime: {
                      $lte: maxDate,
                    },
                     
                  },
                  "type": "sent_by_email",
                  "source": "assign_emails"
              },
                sx: { m: 1, minWidth: 300 },
                payloadFormat: { key: "added_by" },
    
              },


              {
                  id: 3,
                  label: "Search By Recipient",
                  name: "rename",
                  type: "autoComplete",
                  className: 'list_search_item countDiv__column--col6 ',
                  base_url: process.env.REACT_APP_API_URL + "dashboard-cards/email-sent",
                  reqBody: {
                    "condition": {
                        "limit": 5,
                        "skip": 0
                    },
                    "sort": {
                        "type": "asc",
                        "field": "email"
                    },
                    "searchcondition": {
                      createdon_datetime: {
                        $gte: mindate,
                        $lte: maxDate,
                      },
                       
                    },
                    "type": "sent_to_name",
                    "source": "assign_emails"
                },
                  sx: { m: 1, minWidth: 300 },
                  payloadFormat: { key: "added_for" },
      
                },


                {
                  id: 4,
                  label: "Search By Recipient's Email ",
                  name: "reemail",
                  type: "autoComplete",
                  className: 'list_search_item countDiv__column--col6 ',
                  base_url: process.env.REACT_APP_API_URL + "dashboard-cards/email-sent",
                  reqBody: {
                    "condition": {
                        "limit": 5,
                        "skip": 0
                    },
                    "sort": {
                        "type": "asc",
                        "field": "email"
                    },
                    "searchcondition": {
                      createdon_datetime: {
                        $lte: maxDate,
                      },
                       
                    },
                    "type": "sent_to_email",
                    "source": "assign_emails"
                },
                  sx: { m: 1, minWidth: 300 },
                  payloadFormat: { key: "added_for" },
      
                },
    
             
             
            ]
          }
        },
      });
  return (
    <>
    <TableContainer component={Paper}>
          <ListingTable
            tableData={EmailtableDataDashboard.current}
            dataset={listData}
            modifyHeaders={modifyTableHeaders}
          />
        </TableContainer>
    </>
  )
}
