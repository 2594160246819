import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import StateArray from '../../../../assets/json/state';
import fetchUserDataReducer, { fetchUserData } from '../fetchUserDataReducer';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { UserActivity } from './folderViewReducer';
import { FetchRoles } from '../userList/userListReducer';
import Interview from './Interview';
import LoginIcon from '@mui/icons-material/Login';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';

const FolderViewAccess = (props) => {
    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 

    // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

    // ---- States here -----
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    const [open, setOpen] = React.useState(false); // snackbar const



    // ---- geeting and setting QueryParam -----
    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [paramId])

    // -------------------------------


    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)

    
    // --- loader --- 
    const loader = useSelector(state => (state.fetchUserDataReducer?.loading
    ) ? state.fetchUserDataReducer.loading
        : null);

    const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

    const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)

    const UserActivityData = useSelector(state => (state?.userFolderViewReducer?.userActivityList) ? state?.userFolderViewReducer?.userActivityList : [])


    // console.warn("<<<<<<<<<< folderdata goes here >>>>>>>>>", folderdata)



    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        // setErrPopup(false);
        // setErrMsgCopy(null);
    };



    return (
        <>

            <div>
                <h3><FolderIcon /> {props.type}
                <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              <Tooltip title="Search Email">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </div>
                </h3>
                {loading ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


                {props.activity?.length > 0 ? props.activity.map((x) =>
                    <div className='user-activity'>

                        <ul className='ua-data-block'>
                            <li className='heading'>  {x.activity_type == 'login' ? (<LoginIcon />) : ' '}    {x.activity_type ? x.activity_type : 'N/A'} </li>
                            <li>Activity Time:  {format(x.activity_time, 'dd-MM-yyyy HH:mm:ss')} </li>
                            <li>Activity: {x.activity}</li>
                        </ul>

                    </div>) : (
                        // <Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>
                    <p> No Data Found !</p>)}

            </div>




            {open ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={queryParamId ? "Updated Sucessfully" : " "}
            // action={action}
            />) : ""}

        </>
    )
}

export default FolderViewAccess;






