import { Drawer, IconButton, Paper, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '../../../../form/Form';



const FDSearch = ({ type,dataform }) => {
  const [openDrawer, setOpenDrawer] = React.useState(true);
 console.log("This FDSearch for login info");


  // const dataform = {
  //   id: 'SearchForm',
  //   submitBtnName: "Search",
  //   submitBtnEndIcon: 'search_icon',
  //   resetForm: true,
  //   resetBtnName: "Refresh",
  //   resetBtnEndIcon: 'refresh',
  //   endPointBodyWrapper: "searchcondition",
  //   formButtonClass: "submitbtnsection",
  //   formWrapperClass: "formWrapperClass formCls",
  //   formInlineClass: "backendTableInline_addform testwrpr trainingcata",
  //   formAdditionalSubmissionData: {

  //   },


  //   fields: [
      
  //     {
  //       id: 8,
  //       label: "Search by Start Date",
  //       name: "start_date",
  //       className: 'inputBlock inputBlock3line',
  //       type: 'datePicker',
  //       sx: { m: 1, width: 300 },
  //       dateFormat: 'dd/MM/yyyy',
       

  //     },
  //     {
  //       id: 9,
  //       label: "Search by End Date",
  //       name: "end_date",
  //       className: 'datePicker',
  //       type: 'datePicker',
  //       fullDay: true,
  //       sx: { m: 1, width: 300 },
  //       dateFormat: 'dd/MM/yyyy',
  //       className: 'inputBlock inputBlock3line',
  //       errorMessage: {
  //         dateCheck: "End Date can not be less than Start Date",
  //       },
  //     },



  //   ],

  // }



  const handleCloseDrawer = () => {
    setOpenDrawer(false)
}


  return (
    <Drawer
    anchor="bottom"
    open={openDrawer}
    onClose={handleCloseDrawer}
>

<Paper elevation={7} className='list_search team_management_list_search search_img'>


<div className="searchBarHead DrawerBox">
  <h2 className="searchBarHeading">Search &nbsp; {type}</h2>
  <span className="searchBarCloseIcon">
    <Tooltip title='Close' >
      <IconButton >
        <CloseIcon className="closeDrawer" onClick={() => handleCloseDrawer()} />
      </IconButton>
    </Tooltip>
  </span>
</div>

<Form formData={dataform} />
</Paper>
</Drawer>
   
  )
}

export default FDSearch