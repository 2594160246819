import React, { useEffect, useRef, useState } from 'react'
import { CookiesProvider, withCookies, Cookies, useCookies } from "react-cookie";
import store from '../../../store'
import { useSelector, useDispatch } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddTaskIcon from '@mui/icons-material/AddTask';
import LinkIcon from '@mui/icons-material/Link';
import copy from "copy-to-clipboard";
import { setSnackbar } from '../../Layout/layoutReducer';
import { Badge, Button, Fade, LinearProgress, Modal, Tooltip } from '@mui/material';
import UserNameCreate from './../../CampaignManagement/UserNameCreate';
import CampaingList from './../../CampaignManagement/CampaingList';
import CampainManageModal from './CampainManageModal';
import { clearcampaignDatadata, getCampaignListData, getAllCampaignListData } from '../../CampaignManagement/CampaignReducer';
import { Box } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';

const CustomLinks = (props) => {




    let divLoader = useSelector(state => (state.camPaignManagementReducer?.loading) ? state.camPaignManagementReducer.loading : false);

    const cookies = new Cookies();
    // console.log("cookies in layout==>", cookies.getAll());
    const [landingLiksArray, setlandingLiksArray] = useState(null);
    const [getcookies, setgetcookies] = useState(cookies.getAll());
    // const getcookies = cookies.getAll();
    console.log("getcookies==>", getcookies);
    const dispatch = useDispatch();
    const uniqueUserName = useSelector((state) => state.loginSlice.userInfo?.user_name ? state.loginSlice.userInfo.user_name : null);
    const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);
    const user_name = useSelector((state) => state.loginSlice.userInfo?.user_name ? state.loginSlice.userInfo.user_name : null);
    const user_data = useSelector((state) => state.loginSlice.userInfo ? state.loginSlice.userInfo : null);
    console.log('USERNAME>>', uniqueUserName)

    const [openUserModal, setUserModal] = useState(false);
    const [openCampainModal, setCampainModal] = useState(false);
    const [openCampainModalList, setCampainModalList] = useState(false);
    const [valueOfCampainModal, setvalueOfCampainModal] = useState(false);
    const [redundentFlag, setRedundentFlag] = useState(false);
    const [pagadataObj, setpagadataObj] = useState({});
    
    // const [divLoader, setdivLoader] = useState(false);

    const campainData = useSelector((state) => state.camPaignManagementReducer?.allcampaignData && state.camPaignManagementReducer?.allcampaignData.length > 0 ? state.camPaignManagementReducer.allcampaignData : []);
    let flag = true;
    const copyToClipboard = (e) => {
        let copyValue = ''
        if (e.user_name != undefined) {
            if (e.sub_id != undefined) {
                copyValue = e.domain_url + e.user_name + e.sub_id;
            }
            else {
                copyValue = e.domain_url + e.user_name;
            }
        } else {
            copyValue = e.domain_url;
        }
        copy(copyValue);
        dispatch(setSnackbar({ open: true, message: "Copied To Clipboard !", status: 'success' }))
    }

    const campainMnage = (val, url, p_name) => {
        setpagadataObj({ page_id: val, p_url: url, p_name: p_name })
        if (typeof user_data !== 'undefined' && typeof user_data.user_name !== 'undefined' && user_data.user_name !== null) {
            let obj = { page_id: val, p_url: url, p_name: p_name };
            setvalueOfCampainModal(obj);
            findCampainSingleValue(val);
            setCampainModal(true);
            // campainData.length = 0;
        }
        else {
            setUserModal(true);

        }
    }

    const setUsernameOption = (value, id) => {
        console.warn("setUsernameOption>>>>", value, id);

        let addlandingLiksArray = landingLiksArray
        addlandingLiksArray = { ...addlandingLiksArray }
        // landingLiksArray.forEach(element => {
        //     if(element._id == id){
        //         element.user_name = value;
        //     }
        // });


        for (const k in addlandingLiksArray) {
            if (addlandingLiksArray[k]._id == id) {
                addlandingLiksArray[k].user_name = value;

            }
        }
        const result = Object.keys(addlandingLiksArray).map((key) => addlandingLiksArray[key]);
        setlandingLiksArray(result);
    }

    const handleCloseUserModal = (val,user_name) => {
        console.log("val++++",val,pagadataObj)
        if(val==1 && Object.keys(pagadataObj).length>0){
            console.log("HITTTTTT",user_data);
            if(typeof user_data !== 'undefined' && (user_data.user_name|| user_name)) {
                console.log("HITTTTTT 2");
                let obj = pagadataObj;
                setvalueOfCampainModal(obj);
                findCampainSingleValue(val);
                setCampainModal(true);
                // campainData.length = 0;
            }
        }
        setUserModal(false)
    };

    const handleCloseCampaignModal = () => {
        setCampainModal(false)
        findCampainValue();
        // setTimeout(() => {
        console.warn(campainData, "campainData && getcookies==>", getcookies);
        if (campainData !== undefined && campainData.length > 0) {
            setArrayValue(campainData, user_data?.user_name)
        }
        // }, 3000);


    };

    useEffect(() => {
        console.log('divLoader', divLoader)

    }, [divLoader])


    useEffect(() => {
        // console.log("RoleList", resolve);
        findCampainValue()


        return () => {
            //  store.dispatch(clearcampaignDatadata());
        };
    }, []);

    useEffect(() => {
        if (campainData !== undefined && campainData.length > 0) {

            setArrayValue(campainData, user_data.user_name)
        } else {
            if (!redundentFlag)
                setArrayValue(campainData, user_data?.user_name)

        }

        // campCountVal(campCount)
    }, [campainData])

    console.log('landingLiksArray', campainData)


    const setArrayValue = (campainData, username) => {
        let campCount = 0;
        let campArray = [];
        let defaultUser = null
        console.warn("LIST && CAMPAIN ======>", campainData, landingLiksArray, props.customlinksData);
        let addlandingLiksArray = props.customlinksData
        addlandingLiksArray = { ...addlandingLiksArray }
        if (campainData !== undefined && campainData.length > 0) {
            for (let i in addlandingLiksArray) {
                for (const key in campainData) {
                    // addlandingLiksArray[i] = { ...addlandingLiksArray[i], count: campCount, user_name: username ,  campDeatis: campArray};
                    if (addlandingLiksArray[i]._id == campainData[key].landing_page_id) {
                        campCount++;
                        campArray.push({ name: campainData[key].name, user_name: campainData[key].user_name })
                        if(typeof campainData[key].default != 'undefined' && campainData[key].default == true){
                            defaultUser = campainData[key].user_name
                        }
                        if(defaultUser != null){
                        addlandingLiksArray[i] = { ...addlandingLiksArray[i], count: campCount, user_name: defaultUser, campDeatis: campArray };

                        }else{
                        addlandingLiksArray[i] = { ...addlandingLiksArray[i], count: campCount, user_name: campainData[key].user_name, campDeatis: campArray };
                        }

                    }
                    // else {
                    //     addlandingLiksArray[i] = { ...addlandingLiksArray[i], count: campCount, user_name: username ,  campDeatis: campArray};
                    // }

                }
                campCount = 0;
                campArray = [];
                defaultUser = null
            }


        }




        if (!redundentFlag && username != undefined) {
            for (let i in addlandingLiksArray) {
                addlandingLiksArray[i] = { ...addlandingLiksArray[i], user_name: username };
            }
        }
        const result = Object.keys(addlandingLiksArray).map((key) => addlandingLiksArray[key]);
        setlandingLiksArray(result);
        setRedundentFlag(true)

        // setdivLoader(false)
        console.log('LIST && CAMPAIN2', result);


    }


    const findCampainValue = () => {
        // store.dispatch(clearcampaignDatadata());
        let reqbody = {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "updatedon_datetime",
                type: "desc",
            },
            searchcondition: {
                // "user_name": getcookies?.userinfo?.user_name,
                "user_id": user_id,
            },
            project: {},
            token: "",
        };
        store.dispatch(getAllCampaignListData(reqbody));
    }

    const findCampainSingleValue = (val) => {
        // store.dispatch(clearcampaignDatadata());
        let reqbody = {
            condition: {
                limit: 10,
                skip: 0,
            },
            sort: {
                field: "updatedon_datetime",
                type: "desc",
            },
            searchcondition: {
                // "user_name": getcookies?.userinfo?.user_name,
                "user_id": user_id,
                "landing_page_id": val?val:undefined
            },
            project: {},
            token: "",
        };
        store.dispatch(getCampaignListData(reqbody));
    }

    const [modalDatat, setmodalDatat] = useState(null);


    const [openText, setOpenText] = React.useState(false);
    const handleOpenText = (modalDatat) => {
        setOpenText(true);

        console.log('modalDatat', modalDatat)
        if (modalDatat && modalDatat !== undefined) { setmodalDatat(modalDatat) }
    };
    const handleCloseText = () => {
        setOpenText(false);
    };


    console.log('modalDatat', modalDatat)


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    // const [height, setHeight] = useState(0);
    // const elementRef = useRef(null);

    // useEffect(() => {
    //     if (elementRef.current){
    //         console.log("elementRef.current.clientHeight", elementRef.current.clientHeight);
    //         setHeight(elementRef.current.clientHeight);

    //     }
    // }, [elementRef]); //empty dependency array so it only runs once at render




    return (
        <>




            <Modal
                open={openText}
                onClose={handleCloseText}>
                <Box sx={style} className="BoxMui_modal">

                    <div className='landingmodalCon'>
                        <h1 className='h1textcon'>{modalDatat && modalDatat.name}</h1>
                        <p>{modalDatat && modalDatat.description}</p>
                    </div>


                    <Button onClick={handleCloseText} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>

            {divLoader ? <Box sx={{ width: "100%", marginTop: '10px', marginBottom: "10px" }}>
                <LinearProgress />
            </Box> : ''}


            {landingLiksArray && landingLiksArray.length > 0 ?
                landingLiksArray.map((response, index) => {
                    return (
                        <div key={index} className='customlinksData_block'>
                            <div className='customlinksData_con'>
                                <div className='customlinksData_IMG'>
                                    <img src={response.thumbnail_url}></img>

                                </div>
                                <div className='customlinksData_text'>
                                    <div className='customlinksData_text_con'>

                                        <label><span>{response.name}</span> </label>


                                        {/* <p>
                                            <span> {response.description} </span> 
                                            {response.description.length > 250 ? < Button onClick={() => handleOpenText(response)}> <RemoveRedEyeIcon /> </Button> : ""}                                            
                                            </p> */}

                                        <p>
                                            <span> {response.description} </span>
                                            <Button onClick={() => handleOpenText(response)}> <RemoveRedEyeIcon /> </Button>
                                        </p>









                                    </div>
                                    <div className='customlinksData_text_wrapper'>
                                        {(response?.user_name || user_name) ?

                                            <div className="customlinksData_text_link"><span><LinkIcon /></span>

                                                <p className="linktext">  {response.domain_url}{response.user_name?response.user_name:user_name}</p>


                                            </div> :

                                            <div className="customlinksData_text_link"><span><LinkIcon /></span> <p className="linktext">  {response.domain_url}</p></div>
                                        }

                                        <div className='customlinksData_select'>

                                            {response?.campDeatis &&
                                                <Select  defaultValue={response.user_name }>
                                                    {response.campDeatis.map((campainDataf) => {

                                                        return (

                                                            <MenuItem onClick={() => setUsernameOption(campainDataf.user_name, response._id)} value={campainDataf.user_name}>{campainDataf.name}</MenuItem>


                                                        )

                                                    })
                                                    }


                                                </Select>
                                            }
                                        </div>

                                        <div className='customlinksData_text_icon'>

                                            <span onClick={() => copyToClipboard(response)} ><Tooltip title="Copy"><ContentCopyIcon /></Tooltip></span>
                                            {/* <span onClick={() => campainMnage(response._id)}><Tooltip title="Add Campain"><AddTaskIcon /></Tooltip></span> */}


                                            <span onClick={() => campainMnage(response._id, response.domain_url, response.name)}>
                                                <Badge badgeContent={response.count}
                                                    color="primary">
                                                    <Tooltip title="Add Campain"><AddTaskIcon /></Tooltip>
                                                </Badge>
                                            </span>




                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })

                :
                <div className='errortextcss'>
                    <p>No Data Found !</p>
                </div>

            }

            <UserNameCreate
                open={openUserModal}
                close={handleCloseUserModal}
                user_id={user_id}
            />

            <CampainManageModal
                open={openCampainModal}
                close={handleCloseCampaignModal}
                user_id={user_id}
                user_name={user_data?.user_name}
                landingpage_id={valueOfCampainModal}
            />



            {/* <CampaignAddEdit open={openCampainModal} userName={uniqueUserName}/> */}

        </>
    )
}

export default CustomLinks