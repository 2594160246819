import { Outlet, Link, useMatch, matchPath, useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { withCookies, Cookies, useCookies } from "react-cookie";
// import BackendHeader from './Pages/Layout/BackendHeader';
// import BackendFooter from './Pages/Layout/BackendFooter';

import store from "./store.js";
import { Alert, Box, IconButton, LinearProgress, Snackbar } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Tooltip } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';

import { useDispatch, useSelector } from "react-redux";
import Footer from "./Pages/Layout/Footer.js";
import BackendHeader from "./Pages/Layout/BackendHeader.js";
import BackendLeft from "./Pages/Layout/BackendLeft.js";
import debounce from 'lodash.debounce';
import Frontendfooter from "./Pages/Layout/Frontendfooter.js";
import { setSnackbar } from "./Pages/Layout/layoutReducer.js";
import { Close } from "@mui/icons-material";

let storedata = null;

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation();
  const location = useLocation();
  const calendarmatch = useMatch("/calendar/:lead_id/:orgrole/:orgid");
  const calendarReschedulematch = useMatch("/reschedule-slot/:identifier/:orgrole/:orgid");
  const thankyoumatch = useMatch("/thankyou/:page/:lead_id");
  const cancelslotmatch = useMatch("/cancel-slot/:identifier");

  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);


  // ------ Loading during any action -----
  const userinfo = useSelector(state => (state.fetchUserDataReducer?.userInfo
  ) ? state.fetchUserDataReducer.userInfo
    : {});

  const open = useSelector((state) =>
    state.layoutSlice.open ? state.layoutSlice.open : false
  );
  const message = useSelector((state) =>
    state.layoutSlice.message ? state.layoutSlice.message : ""
  );
  const status = useSelector((state) =>
    state.layoutSlice.status ? state.layoutSlice.status : "success"
  );

  useEffect(() => {
    setTimeout(() => {
      if (open === true) dispatch(setSnackbar({ open: false, message: "", }));
    }, 7000);
  }, [open]);



  useEffect(() => {

    if (Object.keys(userinfo)?.length > 0) {
      // console.warn("<<<<<<<< User info chnaged >>>>>>", typeof (userInfo));
      setuserInfoCookie(
        "userinfo",
        JSON.stringify(userinfo),
        { path: "/" }
      );


    }

  }, [userinfo])





  // console.log("pathname",pathname);

  // const [loading, setLoading] = useState(false);
  // const loading = useSelector((state) => (state.layoutSlice?.loader !== undefined && state.layoutSlice?.loader != null) ? state.layoutSlice.loader : false);
  // useEffect(() => {
  // store.subscribe(() => {
  //   storedata = store.getState();
  //   // console.log(
  //   //   "storeData layout",
  //   //   storedata.loginSlice,
  //   //   "islogg",
  //   //   storedata.loginSlice.isLoggedIn
  //   // );
  //   if (storedata.loginSlice && storedata.loginSlice.isLoggedIn == null) {
  //     if (
  //       !storedata.loginSlice.loginRouteClicked
  //     ) {
  //       // navigate("/");
  //     }
  //   }
  // });
  // }, []);

  useEffect(() => {
    // if (!traingonlyidMatch && pathname !== "/training-center") {
    // console.log("scroll hit");
    // window.scrollTo({ top: 0, behavior: 'smooth' })
    // }

  }, [pathname])

  const [scroll, setScroll] = useState(null);
  const divRef = useRef();

  // const handleScroll = (e) => {
  //   console.log("showwwwwww");
  //   const scrolledFromTop = divRef.current.scrollTop;
  //   console.log(scrolledFromTop);
  //   setScroll(scrolledFromTop > 10);


  // };
  console.log("location", location.pathname);

  const prevScrollY = useRef(0);

  const [goingUp, setGoingUp] = useState(false);

  const debouncedSetFormData = useCallback(debounce((value) => {

    setScroll(value > 20)
  }, 200), []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      debouncedSetFormData(currentScrollY)
      // console.log(goingUp, currentScrollY);

    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  console.log('Landdd////', process.env);

  // let landingpage1location = ""
  const { uniquename } = useParams();
  console.log("uniquename", uniquename);


  return (
    <>


      <div className={(!location.pathname.includes("/") || !location.pathname.includes("/home")) && !thankyoumatch && !cancelslotmatch && scroll ? "hideTop" : "showTop"}>

        <div className="OutletBody" >
          {location.pathname == "/" || location.pathname == '/home' || location.pathname == '/' + uniquename || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' || location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' || calendarmatch || calendarReschedulematch || thankyoumatch || cancelslotmatch || location.pathname == '/thankyou' ? null : <div className="BackendLeft"> <BackendLeft /></div>}

          {/* {(process.env.REACT_APP_LANDINGPAGE_SLUG && process.env.REACT_APP_LANDINGPAGE_SLUG == 'dxiSvO69jQgjH') ? null : <BackendLeft />} */}

          <div className={location.pathname == "/" || location.pathname == '/' + uniquename || location.pathname == '/home' || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' | location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' || calendarmatch || calendarReschedulematch || thankyoumatch || cancelslotmatch || location.pathname == '/thankyou' ? "" : "BackendBody"}>

            {location.pathname == "/" || location.pathname == '/home' || location.pathname == '/' + uniquename || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' || location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' || calendarmatch || calendarReschedulematch || thankyoumatch || cancelslotmatch || location.pathname == '/thankyou' ? null : <div className="BackendHeader"><BackendHeader /></div>}

            <div className={location.pathname == "/" || location.pathname == '/home' || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' | location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' || calendarmatch || calendarReschedulematch || thankyoumatch || cancelslotmatch || location.pathname == '/thankyou' ? "" : "BackendOutlet"} >
              <Outlet />
              {location.pathname == "/" || location.pathname == '/home' || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' || location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' || location.pathname == '/thankyou' ? null : <Footer />}
              {(location.pathname == "/" && !process.env.REACT_APP_IS_BACKEND) || location.pathname == '/home' || location.pathname == '/landing-page1' || location.pathname == '/landing-page6' || location.pathname == '/landing-page2' || location.pathname == '/landing-page2Video' || location.pathname == '/landing-page3' || location.pathname == '/landing-page4' || location.pathname == '/landing-page5' || location.pathname == '/landing-page3Video' || location.pathname == '/landing-page4Video' ? <Frontendfooter /> : null}

            </div>

          </div>
        </div>

      </div>
      <Snackbar
        className="form_error_snackbar"
        open={open}
        autoHideDuration={6000}
        onClose={() => dispatch(setSnackbar({ open: false, message: "" }))}
        // message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() =>
              dispatch(setSnackbar({ open: false, message: "" }))
            }
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert onClose={() => dispatch(setSnackbar({ open: false, message: "" }))} severity={(status == 'success' || status == 'Success') ? "success" : (status == 'error' || status == 'Error') ? "error" : status == 'info' ? 'info' : 'success'} >{message} </Alert>

      </Snackbar>

    </>
  );
};

export default withCookies(Layout);
