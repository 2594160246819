import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import { getroledata, clearroledata, getfaqcategorydata } from './FaqReducer';
import store from '../../../../store';


function AddEditFaqCategory() {

    const { id } = useParams();
    const [rolArry, setrolArry] = useState([]);


    let reqbodyforrole = {
        "condition": {
            "limit": 100,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": { "status": 1 },
        "project": {},
        "token": ""
    }

    const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);
    let roledata = useSelector(state => (state.FaqReducer?.roledata && state.FaqReducer?.roledata?.length > 0) ? state.FaqReducer.roledata : []);

    useEffect(() => {
        store.dispatch(getroledata(reqbodyforrole));

    }, [])

    useEffect(() => {

        if (roledata && roledata.length > 0) {
            let arr = [];
            for (let i in roledata) {
                arr.push({ val: roledata[i]._id, name: roledata[i].name })
            }
            setrolArry(arr)
        }

    }, [roledata])
    console.log("roledata---->>", rolArry);



    const dataform = {
        id: 'faqCategoryAddEditForm',
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "manage-faq-category/add-update-faq-category",
        urlPathOnSuccessfulFormSubmission: "/faq-category",
        submitBtnName: "Submit",
        cancelBtnName: "Cancel",
        formButtonClass: "Formsubmit_button",
        formWrapperClass: "backend_addform addAdmin",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        formAdditionalSubmissionData: {

            _id: (id !== undefined && id !== null) ? id : undefined,
            added_by: user_id
        },

        fields: [
            {
                id: 0,
                heading: "Category Name",
                label: "Category Name",
                name: "category_name",
                // className: "inputBlock inputBlock1line",
                className: 'inputBlock inputBlock1line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
                //   minLength: "Minimum length should be 15",
                //   custom: "Value is Invalid"
                // defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.category_name !== undefined) ? editdata.category_name : undefined,

            },
            {
                id: 1,
                heading: "Description",
                label: "SunEditor",
                name: "desc",
                type: "sunEditor",
                className: "inputBlock1line",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    //   minLength: "Minimum length should be 15",
                    //   custom: "Value is Invalid"
                },

                placeholder: "Please type here...",
                ////////////////////////////// Mandatory or no options will be available /////////////////////
                editorWidth: 300,
                buttonListOptions: [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                ],
                // defaultValue: (editdata && editdata.desc !== undefined) ? editdata.desc : undefined,

            },
            {
                id: 3,
                heading: "Select User Roll",
                label: "Select User Roll",
                name: "user_role",
                className: ' inputBlock inputBlock2line',
                type: 'select',
                // multiple: true,
                checkbox: false,
                other: false,
                rules: { required: true },
                values: rolArry,
                // defaultValue: (editdata && editdata.user_role !== undefined) ? editdata.user_role : undefined,
                errorMessage: {
                    required: "This Field is Required",
                },

            },

           

            {
                id: 4,
                heading: "Priority",
                label: "priority",
                name: "priority",
                className: 'inputBlock inputBlock2line',
                type: "text",
                inputType: "number",/////////////// If not mentioned default will be text //////////////
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },
                // defaultValue: (editdata && editdata.priority !== undefined) ? editdata.priority : undefined,
            },
            {
                id: 5,
                // heading: "Status",
                // label: "Status",
                name: "status",
                className: 'inputBlock1line',
                type: 'singleCheckbox',
                values: { key: 1, val: 'Active' },
                // rules: { required: true },
                // errorMessage: {
                //   required: "This Field is Required",
                // },
                // defaultValue: (editdata && editdata.status !== undefined) ? Boolean(editdata.status) : false,

            },


        ],
        customButtons: [
            {
                id: 'jobgoback001',
                label: 'Go Back',
                className: 'default',
                type: 'button',
            },
        ]


    }

  return (
    <>
          <h1 className='page_heading'>ADD FAQ CATEGORY</h1>
          <div className="dataform2_wrapper">
              <div className='adminformbody'>
                  <Form formData={dataform} />
              </div>
          </div>
    </>
  )
}

export default AddEditFaqCategory
