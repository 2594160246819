import { TableContainer } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import ListingTable from '../../../listing/listing';
import Paper from '@mui/material/Paper';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
const OpportunityCreated = ({listData,setSearchGridData}) => {
    
  const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);  

    let userID = useSelector((state) =>
    state.loginSlice.userInfo ? state.loginSlice.userInfo?._id : null
  );  
  let mindate = useSelector((state) =>
  state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {}
);
let maxDate = useSelector((state) =>
  state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {}
);
let gridData = useSelector(state=>state.tableSlice?.otherResData?.OptableDataDashboard?.grid_data ? state.tableSlice.otherResData.OptableDataDashboard.grid_data: [])
   
  var modifyTableHeaders = [
    { val: "name", name: "Name" },
    { val: "email", name: "Email" },
    { val: "phone", name: "Phone No." },
    { val: "landing_page_name", name: "Source" },
    { val: "hr_name", name: "Hiring Manager" },
    { val: "bdd_names", name: "BDD" },  
      { val: "program_name", name: "Program Name" },  
    //  { val: "campaign_name", name: "Campaign Name" },  
    // { val: "status", name: "Status" },    
];

useEffect(()=>{
  setSearchGridData(gridData)
  console.log("gridData",gridData);
},[JSON.stringify(gridData)])

let OptableDataDashboard = useRef({
    tableId: "OptableDataDashboard",
    tableTitle: "Opportunities List View",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "users/admin-dashboard-list-grid",
        deleteSingleUserEndpoint: "users/admin-dashboard-list-grid",
        tableCountEndpoint: "users/admin-dashboard-list-grid",
    },

    reqBody: {
        condition: {
            limit: 5,
            skip: 0
        },
        sort: {
            field: "_id",
            type: "desc"
        },
        searchcondition: {
          createdon_datetime: {
            $gte: mindate,
            $lte: maxDate,
          },
        },
        project: {},
        token: "",
        source:"google_events",
        type:"opportunities",
        count: false
    },
    deleteSingleUserReqBody: {
        source: "users",
        secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
        source: "users",
        secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5],
    sortFields: ["name", "email", "landing_page_name","hr_name","bdd_names","program_name","campaign_name"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [

        {
            id: "delete",
            label: "Delete",
            type: "api",
            category: "modal",
            icon_type: "delete",
            className: "delete_data",
            modalClassName: "delete_modal",
            modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
            btn_label: ['Yes', "No"],
            api_data: {
                base_url: process.env.REACT_APP_API_URL,
                endpoint: "",
                body: {
                    "source": "users",
                    "secret": "AZ|lepL`",
                    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                }
            }
        },
        {
            id: "status",
            label: "Change Status",
            type: "api",
            category: "status",
            icon_type: "toggle_off",
            className: "status_data",
            modalClassName: "delete_modal",
            modalHeader: "<p> Alert !!</p>",
            modalTitle: "<p>Are you sure you want to change the status ?</p>",
            btn_label: ['Active', "Inactive"],
            api_data: {
                base_url: process.env.REACT_APP_API_URL,
                endpoint: "",
                body: {
                    "source": "users",
                    "secret": "AZ|lepL`",
                    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                }
            }
        },

    ],
    buttons: [
       
      {
        id: "summarize_icon",
        label: "Notes",
        type: "other",
        name: "summarize_icon",
        title: "Notes",
      },
        {
            id: "folder_icon",
            label: "Folder View",
            type: "other",
            name: "folder_icon",
            title: "Folder View",
        },
        {
            id: "assessment_icon",
            label: "Training Report",
            type: "other",
            name: "assessment_icon",
            title: "Training Report",
        },
        {
            id: "streetview_icon",
            label: "Leads",
            type: "other",
            name: "streetview_icon",
            title: "Leads",
        }



    ],
    deleteModal: {
        modalClassName: "modalblock",
    },

    searchData: {
        heading: "Search Opportunities List ",
        type: "drawer",
        formData: {
          id: 'searchForm',
          formWrapperClass: "formWrapperClass",
          formInlineCLass: "userForm", 
          formButtonClass: "submitbtnsection",
          submitBtnName: "Search",
          resetBtnName: "Refresh",
          submitBtnEndIcon: "search",
          resetBtnEndIcon: "replay",
          resetForm: true,
          formAdditionalSubmissionData: {},
          fields: [
            {
              id: 1,
              label: "Search By Name",
              name: "name",
              type: "autoComplete",
              className: 'list_search_item countDiv__column--col2 ',
              base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-opportunity-created",
              reqBody: {
                "condition": {
                    "limit": 5,
                    "skip": 0
                },
                "sort": {
                    "type": "asc",
                    "field": "name"
                },
                "searchcondition": {
                  createdon_datetime: {
                    $lte: maxDate,
                  },
                   
                },
                "type": "name",
                "source": "google_events"
            },
              sx: { m: 1, minWidth: 300 },
              // payloadFormat: { key: "_id", param: "$regex", options: "i" },
              payloadFormat: { key: "_id" },
  
            },
         
            {
              id: 2,
              label: "Search By Email ",
              name: "email",
              type: "autoComplete",
              className: 'list_search_item countDiv__column--col2 ',
              base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-opportunity-created",
              reqBody: {
                "condition": {
                    "limit": 5,
                    "skip": 0
                },
                "sort": {
                    "type": "asc",
                    "field": "name"
                },
                "searchcondition": {
                  createdon_datetime: {
                    $lte: maxDate,
                  },
                 
                },
                "type": "email",
                "source": "google_events"
            },
              sx: { m: 1, minWidth: 300 },
              payloadFormat: { key: "_id" },
  
            },


            {
                id: 3,
                label: "Search By Hiring Manager",
                name: "hiring_manager",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col6 ',
                base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-opportunity-created",
                reqBody: {
                  "condition": {
                      "limit": 5,
                      "skip": 0
                  },
                  "sort": {
                      "type": "asc",
                      "field": "name"
                  },
                  "searchcondition": {
                    createdon_datetime: {
                      $lte: maxDate,
                    },
                    
                     
                  },
                  "type": "hiring_manager",
                  "source": "google_events"
              },
                sx: { m: 1, minWidth: 300 },
                // payloadFormat: { key: "_id", param: "$regex", options: "i" },
                payloadFormat: { key: "hiring_manager" },
    
              },


              {
                id: 4,
                label: "Search By Source ",
                name: "landing_page",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col6 ',
                base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-opportunity-created",
                reqBody: {
                  "condition": {
                      "limit": 5,
                      "skip": 0
                  },
                  "sort": {
                      "type": "asc",
                      "field": "name"
                  },
                  "searchcondition": {
                    createdon_datetime: {
                      $gte: mindate,
                      $lte: maxDate,
                    },
                     
                  },
                  "type": "landing_page",
                  "source": "google_events"
              },
                sx: { m: 1, minWidth: 300 },
                payloadFormat: { key: "_id" },
    
              },

              {
                id: 5,
                label: "Search By BBD ",
                name: "BDD",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col6 ',
                base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-opportunity-created",
                reqBody: {
                  "condition": {
                      "limit": 5,
                      "skip": 0
                  },
                  "sort": {
                      "type": "asc",
                      "field": "name"
                  },
                  "searchcondition": {
                    createdon_datetime: {
                      $gte: mindate,
                      $lte: maxDate,
                    },
                   
                  },
                  "type": "BDD",
                  "source": "google_events"
              },
                sx: { m: 1, minWidth: 300 },
                payloadFormat: { key: "associated_bdd" },
    
              },

              {
                id: 6,
                label: "Search By Program ",
                name: "program",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col6 ',
                base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-opportunity-created",
                reqBody: {
                  "condition": {
                      "limit": 5,
                      "skip": 0
                  },
                  "sort": {
                      "type": "asc",
                      "field": "name"
                  },
                  "searchcondition": {
                    createdon_datetime: {
                      $lte: maxDate,
                    },
                    
                  },
                  "type": "program",
                  "source": "google_events"
              },
                sx: { m: 1, minWidth: 300 },
                payloadFormat: { key: "associated_program" },
    
              },

              {
                id: 7,
                label: "Search By Campaign ",
                name: "campaing",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col6 ',
                base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-opportunity-created",
                reqBody: {
                  "condition": {
                      "limit": 5,
                      "skip": 0
                  },
                  "sort": {
                      "type": "asc",
                      "field": "name"
                  },
                  "searchcondition": {
                    createdon_datetime: {
                      $lte: maxDate,
                    },
                    
                  },
                  "type": "camp",
                  "source": "google_events"
              },
                sx: { m: 1, minWidth: 300 },
                payloadFormat: { key: "campaign_id" },
    
              },

             
  
           
           
          ]
        }
      },
});

 

  return (
    <TableContainer component={Paper}>
      {listData && (
        <ListingTable
          tableData={OptableDataDashboard.current}
          dataset={listData}
          modifyHeaders={modifyTableHeaders}
        />
      )}
    </TableContainer>
  )
}

export default OpportunityCreated