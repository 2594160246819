import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment";



export const getDataForHmDashboardGridList = createAsyncThunk("hm-dashboard-grid-list", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/hm-dashboard-grid-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const getDataForHmDashboardChart = createAsyncThunk("hm-dashboard-chart", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/hm-dashboard-chart", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})




export const getDataForHmDashboardCard = createAsyncThunk("hm-dashboard-card", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/hm-dashboard-card", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const getDataFoAdminDashboardGridList = createAsyncThunk("admin-dashboard-grid-list", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/admin-dashboard-grid-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})



export const getDataFoAdminDashboardChart = createAsyncThunk("admin-dashboard-chart", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/admin-dashboard-chart", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const getDataFoAdminDashboardCard = createAsyncThunk("admin-dashboard-card", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/admin-dashboard-card", requestOptions);
    const respdata = await response.json();
     console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const getlandingpageForRep = createAsyncThunk("getlandingpage", async (reqBody) => {

    console.log("reqbody==>", reqBody);

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        }
    }


    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/landing-page-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const getDataForNotes = createAsyncThunk("dataForNotes", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "note/note-list-count", requestOptions);
    const respdata = await response.json();
    console.log("getDataForNotes==>", respdata);
    return respdata;


})





const DashboardReducer = createSlice({
    name: 'DashboardReducer',
    initialState: {

        mindate: moment().subtract(7, "days").valueOf(),
        maxDate: moment().valueOf(),

        submitting: false,
        loading: false,
        loaderForHmData: false,
        hmChartData: [],
        hmGridData: [],
        hmCardData: [],
        hmListData: null,



        adminChartData: [],
        adminGridData: [],
        adminCardData: [],
        adminListData: null,
        loaderForAdminData:false,
        DNotesData:[],

        getlandingpage: [],



    },
    reducers: {
        clearHmDashboardData(state, action) {

            // state.hmChartData = []
            state.hmGridData = []
            // state.hmCardData = []
            state.hmListData = null

        },
       

        clearAdminDashboardData(state, action) {

            state.adminChartData = []
            state.adminGridData = []
            state.adminCardData = []
            state.adminListData = null
        },
        clearAdminDashboardUserListData(state, action) {

            state.adminChartData = []
            state.adminGridData = []
            // state.adminCardData = []
            state.adminListData = null
        },
        setMinMaxTime(state, action) {

            state.mindate = (moment(action.payload[0]).valueOf())
            state.maxDate = (moment(action.payload[1]).valueOf())
            console.log("action11111", state.mindate, state.maxDate);

        }
    },
    extraReducers: {
        [getDataForHmDashboardGridList.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
            state.loaderForHmData = false;

        },
        [getDataForHmDashboardGridList.pending]: (state, action) => {


            console.log("getDataForHmDashboardGridList=========================>")
            state.loading = true;
            state.loaderForHmData = true;
            


        },
        [getDataForHmDashboardGridList.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.loading = false;
                state.hmGridData = action.payload.results.grid_data;
                state.hmListData = action.payload.results.list_data;
                state.loaderForHmData = false;

            }

        },



        [getDataFoAdminDashboardGridList.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
            state.loaderForAdminData = false;

        },
        [getDataFoAdminDashboardGridList.pending]: (state, action) => {


            console.log("getDataFoAdminDashboardGridList=========================>")
            state.loading = true;
            state.loaderForAdminData = true;


        },
        [getDataFoAdminDashboardGridList.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success'){
                state.loading = false;
                state.adminGridData = action.payload.results.grid_data;
                state.adminListData = action.payload.results.res;
                state.loaderForAdminData = false;
            }
        },




        [getDataFoAdminDashboardCard.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
            state.loaderForAdminData = false;

        },
        [getDataFoAdminDashboardCard.pending]: (state, action) => {


            console.log("getDataFoAdminDashboardCard=========================>")
            state.loading = true;
            state.loaderForAdminData = true;


        },
        [getDataFoAdminDashboardCard.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
                state.adminCardData = action.payload.results.card_data;
                console.log("adminCardData",state.adminCardData);
                state.loaderForAdminData = false;

            }

        },



        [getDataFoAdminDashboardChart.rejected]: (state, action) => {

            state.successAction = false;
            state.loaderForAdminData = false;

        },
        [getDataFoAdminDashboardChart.pending]: (state, action) => {

            state.loaderForAdminData = true;


        },
        [getDataFoAdminDashboardChart.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
                state.adminChartData = action.payload.results;

                state.loaderForAdminData = false;

            }

        },




        [getDataForHmDashboardCard.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
            state.loaderForHmData = false;

        },
        [getDataForHmDashboardCard.pending]: (state, action) => {


            console.log("getDataForHmDashboardCard=========================>")
            state.loading = true;
            state.loaderForHmData = true;


        },
        [getDataForHmDashboardCard.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {


                state.loading = false;

                state.hmCardData = action.payload.results.card_data ;
                state.loaderForHmData = false;

            }

        },


        [getDataForHmDashboardChart.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false

            state.successAction = false;
            state.loaderForHmData = false;

        },
        [getDataForHmDashboardChart.pending]: (state, action) => {

            state.loaderForHmData = true;


        },
        [getDataForHmDashboardChart.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
                state.hmChartData = action.payload.results;
                state.loaderForHmData = false;

            }

        },




        [getlandingpageForRep.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
            state.getlandingpage = [];

        },
        [getlandingpageForRep.pending]: (state, action) => {
            // state.loading = true;
            state.getlandingpage = [];
        },
        [getlandingpageForRep.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.getlandingpage = action.payload?.results?.res;
                // state.loading = false;
            }
        },

        [getDataForNotes.rejected]: (state, action) => {
            state.loading=false
            state.DNotesData=[]
           
        },
        [getDataForNotes.pending]: (state, action) => {
           state.loading=true
        },
        [getDataForNotes.fulfilled]: (state, action) => {
           
                state.DNotesData = action.payload?.results?.res;
                state.loading=false
                
               
            
        },


    }

})

export default DashboardReducer.reducer;
export const { clearHmDashboardData, clearAdminDashboardData, setMinMaxTime ,clearAdminDashboardUserListData,clearHmGridData } = DashboardReducer.actions;

