// import React from 'react'
// import "./LandingPage2Video.css"

// function LandingPage2Video() {
//   return (
//     <div>

//     </div>
//   )
// }

// export default LandingPage2Video


import React, { useEffect, useState } from 'react'
import "./LandingPage2Video.css"
import { Form } from '../../../../form/Form';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import StateArray from '../../../../assets/json/state';
import store from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { fetchCalenderEvents } from '../../../Calendar/calenderReducer';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { setLeadinfo } from '../../../login/loginReducer';
import { getlandingpageoneuserdata, addlandingpageonedata, clearlandingpagesubmittionstate, clearaddedinfo, addlandingpagetwodatalead, getlandingpagedata, updateStages, updateTags } from '../LandingpageReducer';
import { setSnackbar } from '../../../Layout/layoutReducer';





export default function LandingPage2Video() {




    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [locationArry, setLocationArry] = useState([]);
    const [submissionState, setSubmissionState] = useState(0);
    const [loading, setloading] = useState(false);

    const formInputData = useSelector((state) =>
        state.formSlice.formData.userForm2 ? state.formSlice.formData.userForm2 : null
    );

    const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer?.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});
    const landingpageformsubmitionstate = useSelector(state => (state.LandingpagefontReducer?.landingpagesubmittionstate) ? state.LandingpagefontReducer.landingpagesubmittionstate : 0)
    const landingpageformdeta = useSelector(state => (state.formSlice?.formData?.userForm2) ? state.formSlice.formData.userForm2 : null)
    const addedData = useSelector(state => (state.LandingpagefontReducer?.addedinfo && Object.keys(state.LandingpagefontReducer?.addedinfo).length > 0) ? state.LandingpagefontReducer.addedinfo : {});



    // console.log("loading", loading)

    let currTime = new Date().getTime();
    let dayAfterTomorrow = (currTime + (48 * 3600 * 1000));
    const [slotListBody, setSlotListBody] = useState({
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "start_datetime_unix",
            "type": "desc"
        },
        "searchcondition": {
            // userid: getcookies.userInfo?._id,
            "start_datetime_unix": {
                "$gte": dayAfterTomorrow
            }
        },
        // "slot_creation_type": props.type === 'np' ? 2 : 1
    })

    useEffect(() => {
        let Newarr = [];
        if (StateArray && StateArray.length > 0) {
            for (let i in StateArray) {
                Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
            }
            setLocationArry(Newarr);
        }
    }, []);

    // useEffect(() => {
    //     if (submissionState && submissionState === 4) {
    //         addapicall(formInputData);
    //     }
    // }, [submissionState]);

    // const addapicall = async (dataccc) => {
    //     let data = dataccc;
    //     console.log("websiteInformation data", data);
    //     setloading(true);
    //     // formSubmission(true)

    //     if (data != null) {
    //         // data.website_information']=websiteInformation
    //         const requestOptions = {
    //             method: "POST",
    //             headers: { "Content-type": "application/json" },
    //             body: JSON.stringify(data),
    //         };
    //         const response = await fetch(
    //             process.env.REACT_APP_API_URL + "lead-manage/addorupdatelead",
    //             requestOptions
    //         );
    //         // console.log("++++response+++++++",response);

    //         const respdata = await response.json();
    //         console.log("++++cv+++++++", respdata);

    //         if (respdata && respdata.status === "success") {

    //             resolvefunction(navigate, `/calendar/${respdata.results.res._id}`, [
    //                 fetchCalenderEvents(slotListBody),
    //             ]);
    //             dispatch(setLeadinfo(respdata.results.res))
    //             // setloading(false);
    //         } else {
    //             // props.formSubmission(false);
    //             setloading(false);
    //         }
    //     }
    // };

    const { uniquename } = useParams();
    console.log("uniquename******", uniquename);
    let reqbodyforjobcategory = {}
    if (typeof uniquename != 'undefined') {
        reqbodyforjobcategory = {
            "uniquename": uniquename,
        }
    }
    else {
        reqbodyforjobcategory = {
            "page_slug": process.env.REACT_APP_LANDINGPAGE_SLUG,
        }
    }



    console.log("userData", userData);





    useEffect(() => {
        if (uniquename) {
            store.dispatch(getlandingpageoneuserdata(reqbodyforjobcategory));
        }
        else {
            store.dispatch(getlandingpagedata(reqbodyforjobcategory));
        }
    }, [])

    // useEffect(()=>{
    //     console.log("userData", userData);
    //    },[userData])




    useEffect(() => {
        if (submissionState && submissionState === 4) {
            addapicall(formInputData);
            console.log("formInputData", formInputData, submissionState);
        }
    }, [submissionState]);

    const addapicall = async (val) => {
        console.log("val>>>", val);

        val = { ...val }
        let body = {
            "firstname": val.firstname,
            "lastname": val.lastname,
            "email": val.email,
            "phone": val.phone,
            "city": val.city,
            "state": val.state,
            "zip": val.zip,
            "address": val.address,
            "campaign_id": userData.campaign_id,
            "associated_program": userData.associated_program,
            "page_id": userData.landing_page_id,
            "page_slug": userData.landing_page_slug,
            "user_id": userData.user_id,
            "rep_id": userData.rep_id,
            "hiring_manager": userData.hm_id,
            "createdon_datetime": new Date().getTime(),
            "status": 0,
            // is_lead: true,


        }

        if (Object.keys(body).length > 0) {
            console.log("body-----", body);

            setLoader(true)
            setloading(true)

            await store.dispatch(addlandingpagetwodatalead(body));
            // setActive(false)
            setLoader(false)
            setloading(false)

            // if(){

            // }
        }



    };


    useEffect(() => {
        console.log("userData", landingpageformsubmitionstate, addedData);
        if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
            dispatch(setSnackbar({ open: true, message: "Thank you for Submitting !", status: 'success' }))
            if (userData.calendar_access && userData.calendar_access == 1) {
                slotListBody['_id'] = addedData._id;
                resolvefunction(navigate, `/calendar/${addedData._id}`, [
                    fetchCalenderEvents(slotListBody),
                ]);
            }
            else {
                navigate(`/thankyou`);
            }
            //   resolvefunction(navigate, `/calendar/${addedData._id}`, [
            //     fetchCalenderEvents(slotListBody),
            //   ]);
            dispatch(setLeadinfo(addedData));
            store.dispatch(clearlandingpagesubmittionstate())





        }
        //  return () => {
        //   store.dispatch(clearlandingpagesubmittionstate())
        // //   store.dispatch(resetresolvenew('/:uniquename'))
        // }

    }, [landingpageformsubmitionstate])

    const [dataCopy, setDataCopy] = useState({});  //--- dataCopy  ----//

    useEffect(() => {
        console.log("addedData", addedData);

    }, [addedData])

    useEffect(() => {
        if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
            setDataCopy(addedData)
            const data = {
                stage_slug: '5QH6nS8FXNawY',
                added_by: addedData._id,
                added_for: addedData._id,
                is_delete: 0,
                status: 1
            }
            console.log("data===========================>", data)
            // return
            dispatch(updateStages(data));


            const tagdata = {
                added_by: addedData._id,
                added_for: addedData._id,
                key: "Wnv14tRS9FlmV",
                label: "hm-policiesandcompliance-signed-success",
                is_delete: 0,
                status: 1
            }
            console.log("tagdata===========================>", tagdata)
            // return
            dispatch(updateTags(tagdata));


        }


    }, [landingpageformsubmitionstate])







    const formDataL2 = {
        id: 'userForm2',
        formtype: "add",
        // api_url: process.env.REACT_APP_API_URL,
        // endPoint: "lead-manage/addorupdatelead",
        // endPointBodyWrapper: "data",
        // urlPathOnSuccessfulFormSubmission: "/lead-list",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "l2submitbtnsection",
        submitBtnName: "Submit",
        // submitBtnStartIcon: 'image_search_icon',
        // submitBtnEndIcon: 'search_icon',
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
            type: "admin",
            status: "active",
        },
        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////

            {
                id: 7,
                label: "Title",
                name: "prospectTitle",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },

            {
                id: 0,
                // heading: "Full Name",
                label: "First Name",
                name: "firstname",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            {
                id: 1,
                // heading: "Full Name",
                label: "Last Name",
                name: "lastname",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            {
                id: 2,
                // heading: "Full Name",
                label: "Email",
                name: "email",
                className: 'formfild',
                type: "text",
                inputType: "email",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 3,
                // heading: "Full Name",
                label: "Contact no",
                name: "phone",
                className: 'formfild',
                type: "text",
                inputType: "phone",
                rules: { required: true, minLength: 10 },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },

            // {
            //     id: 4,
            //     label: "Prospect Medical Facility Business Name",
            //     name: "prospectBusinessName",
            //     className: 'formfild',
            //     type: "text",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },

            // },

            // {
            //     id: 5,
            //     label: "Prospect First Name",
            //     name: "prospectFirstname",
            //     className: 'formfild',
            //     type: "text",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },

            // },

            // {
            //     id: 6,
            //     label: "Prospect Last Name",
            //     name: "prospectLastname",
            //     className: 'formfild',
            //     type: "text",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },

            // },

        

            // {
            //     id: 8,
            //     label: "Prospect Email",
            //     name: "prospectEmail",
            //     className: 'formfild',
            //     type: "text",
            //     inputType: "email",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },

            // },

            // {
            //     id: 9,
            //     label: "Type of medical facility (medical practice, surgical center, urgent care, hospital, medical practice group):",
            //     name: "medicalFacilityType",
            //     className: 'formfild',
            //     type: "text",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },

            // },

            // {
            //     id: 10,
            //     label: "What is the nature of your relationship with the prospect?",
            //     name: "relationship",
            //     className: 'formfild',
            //     type: "text",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },

            // },

            // {
            //     id: 11,
            //     label: "Approximate gross receipts/medical billing with 3 top insures ($2M minimum)",
            //     name: "approximate",
            //     className: 'formfild',
            //     type: "text",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },

            // },

            // {
            //     id: 12,
            //     label: "Any other information we should know?",
            //     name: "otherInformation",
            //     className: 'formfild',
            //     type: "textarea",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },

            // },


            {
                id: 12,
                // heading: "Any other information we should know?",
                name: "otherInformation",
                className: 'formfilddec',
                type: 'textarea',
                // label: "TextArea Label",
                placeholder: 'Any other information we should know?',
              
                rules: { required: true},
                errorMessage: {
                    required: "This Field is Required",
                    // custom: "Value is Invalid"
                },
              
              },




            // {
            //     id: 4,
            //     // heading: "Full Name",
            //     label: "Address",
            //     name: "address",
            //     className: 'formfild',
            //     type: "text",
            //     inputType: "text",/////////////// If not mentioned default will be text //////////////
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },
            // },




            // {
            //     id: 5,
            //     // heading: "Full Name",
            //     label: "City",
            //     name: "city",
            //     className: 'formfild',
            //     type: "text",
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },

            // },
            // {
            //     id: 6,
            //     // heading: "Full Name",
            //     label: "State",
            //     name: "state",
            //     className: 'formfild state',
            //     type: "select",
            //     // other: true,
            //     values: locationArry,
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },
            //     defaultValue: "",
            // },
            // {
            //     id: 7,
            //     // heading: "Full Name",
            //     label: "Zip",
            //     name: "zip",
            //     className: 'formfild',
            //     type: "text",
            //     inputType: "text",
            //     rules: { required: true, maxLength: 5, minLength: 5 },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid",
            //         maxLength: "Maxlength length 5 allowed",
            //         minLength: "Minlength length 5 allowed",
            //     },

            // },

        ]

    };

    store.subscribe(() => {
        const storeData = store.getState();
        if (storeData && storeData.formSlice && storeData.formSlice.formSubmissionState && storeData.formSlice.formSubmissionState.userForm2) {
            setSubmissionState(storeData.formSlice.formSubmissionState.userForm2);
        }
        // console.log(setSubmissionState, "setSubmissionState")
        // console.log("landing store", storeData)
    })

    const goToTop = () => {
        const element = document.getElementById('sectionForm');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="LandingPage2Video Videolanding4 Videolanding3">
                <div className='LandingPage2body'>
                    <div className='LandingPage2BG'>

                        <div className='l2_top_banner'>
                            <div className='l2_top_bannerBG'>
                                <div className='l2Wrapper'>

                                    <div className='l2_top_banner_left'>
                                        <div className='l2_logowrapper'>
                                            <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_logo.webp' />
                                        </div>
                                        <div className='l2_top_banner_text'>
                                            <h2>Aspire Med Max Billing</h2>
                                        </div>

                                        <div class="lpage_banner_sub_wrapper"><div class="lpage_banner_sub_video"><img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/landing_page2_vd0_aspire.png" /></div></div>

                                        <div className='l2_top_banner_arrowCon'>

                                            <h4>Recover Revenue & Maximize Reimbursement</h4>
                                            {/* <h4>Aspire Med Max Billing</h4> */}

                                        </div>


                                    </div>

                                    <div className='l2_top_banner_right'>
                                        <div className='l2form_style' id='sectionForm'>
                                            <div className='l2form_box'>
                                                <div className='l2form_style_heading'>
                                                    <h2>Fill Out The Form <br />Below To Speak With One <br />Of Our Team Members</h2>
                                                </div>

                                                <Form formData={formDataL2} />
                                                {/* {!loading ? <LinearProgress /> : null} */}

                                                {loading ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>) : (null)}

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className='l2_block2'>

                            <div className='l2Wrapper'>
                                <div className='l2_block2_bg'>

                                    <div className='l2_block2_text'>

                                        <h2>What is Aspire Med Max Billing’  Recovery System?</h2>
                                        <h3><span>“Aspire Med Max Billing”</span> is a unique marketplace. </h3>
                                        <p>First and foremost, EVERY <span>“Would be”</span> competitor actually focuses on other activities as their primary function and offers underpayment review and recapture as an ancillary service. The service reality is, either you’re an expert or you’re not. <span>“We can also take a look”</span> denotes anything but expertise which is demonstrated by the fact that their purported results are a fraction of ours.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='l2_block3'>
                            <div className='l2Wrapper'>
                                <div className='l2_block3_con'>

                                    <div className='l2_block3_text1'>
                                        <p>Secondly, and equally if not more important is the absolute expertise, knowledge and sheer volume of contracting data (we’ve reviewed and integrated 1,000’s of commercial agreements with every imaginable variation into our proprietary software) we control which is necessary to accurately and effectively evaluate underpayments. We have reviewed and embedded Virtually All contract methodologies, <span>billing and payment systems along with associated tracking;</span> into our proprietary software which allows us to analyze all aspects of your financial circumstance in a matter of seconds. This reality slashes the time required to accurately analyze your contracts to ensure that you are being paid in accordance to same, that said contract(s) are appropriate for your unique circumstances, activities and procedures, point our areas of amendment or alteration and may other associated considerations.</p>

                                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block3_img1.webp' className='underpatment_block3_img1'></img>
                                    </div>



                                    <div className='l2_block3_text3'>
                                        <div className='l2_block3_text3_con'>

                                            <p>The software will also ensure that your billings and associated payments are coded properly (a very common issue) and finally,
                                                will remove all the inherent and common shortfalls associated with human error - thereby ensuring the fastest, most accurate and irrefutable results in the industry.</p>

                                        </div>
                                    </div>





                                </div>

                            </div>

                        </div>

                        <div className='l2_block4'>

                            <div className='l2_block4_main'>
                                <div className='l2_block4_BG'>

                                    <div className='l2Wrapper'>

                                        <div className='l2_block4_con1'>
                                            <h2>What is needed from a client?</h2>

                                            <ul>
                                                <li><strong>Copy of the Payer agreement</strong> (Usually the top 4 Payers or top 4 problem Payers)</li>
                                                <li><strong>Up to the past 2 years of their Electronic Claim Payments </strong> (referred to as ERA or 835 files.)</li>
                                            </ul>
                                            <button className='newscrollbtn' onClick={goToTop}>Recover & Maximize Reimbursement Now</button>
                                        </div>

                                        <div className='l2_block4_con2'>
                                            <h2>How Aspire Med Max Billing can help</h2>

                                            <ul>
                                                <li>Will automatically review every payment received over the past 2 years to verify that they were paid in full according to the payer agreements.</li>
                                                <li>Our team of experienced contract specialists will analyze, load, and verify all contract details have been entered correctly into the platform.
                                                </li>
                                                <li>Once the uploaded your Electronic Claims are uploaded into the platform, the engine will provide the following details:</li>
                                            </ul>

                                            <div className='l2_block4_con2_sub'>

                                                <label>Any claim setup issues.</label>
                                                <label>Breakdown of Underpayments by Payer according to the Claim Adjustment Reasoning.</label>
                                                <label>Group all Underpayments by collectability.</label>
                                                <label>Begin collections on past underpaid claims.</label>
                                                <label>Goal is to initiate collections within 10 days of receiving all relevant information.</label>

                                            </div>



                                        </div>

                                        <div className='l2_block4_con3'>
                                            <h2>What happens after?</h2>
                                            <h3>Partnering with Aspire Med Max Billing as your go to for all contracting issues will facilitate the following:</h3>

                                            <ul>

                                                <li>Gives your staff or billing company the Real-Time TOOLS needed to stay on top of your revenue and keep the payers honest at all times.
                                                </li>
                                                <li>Gives your staff access to our Contract Team for questions / concerns / corrections to any denials or appeals.</li>
                                                <li>Maintain or update your Contracts <br />
                                                    <label>Any new language  </label>
                                                    <label>Carve-outs</label>
                                                    <label>Fee Schedule updates </label>
                                                </li>
                                                <li>Contract Modeling tool<br />
                                                    <label>See in Real-Time what changes to your current agreements would mean to your bottom line.</label>
                                                </li>
                                                <li>Contract Negotiation<br />
                                                    <label>Our Contract </label>
                                                </li>

                                                <li>Product Modeler (All products affect healthcare providers differently in accordance with their individual contracts.)<br />
                                                    <label>It’s very common for the material department, financial department, and even the CEO of the hospital not to understand how given products and/or procedures align with their contracts economically.</label>
                                                </li>
                                            </ul>

                                            <button className='newscrollbtn' onClick={goToTop}>Recover & Maximize Reimbursement Now</button>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className='l2_block5'>
                            <div className='l2Wrapper'>
                                <div className='l2_block5_body'>
                                    <div className='l2_block5_body_BG'>
                                        <div className='l2_block5_body_BG2'>

                                            <div className='l2_block5_text1'>

                                                <div className='l2_block5_text1_textcon'>
                                                    <h2>Forensic Analysis</h2>
                                                    <p>Our team of contract specialists load the payment rates and terms of the healthcare providers existing Payer Agreements in the Contract Module. <span>We work with the healthcare providers Billing Department and claim Clearinghouse to electronically upload your ANSI ASC X12 837/835 files using our HIPPA compliant, proprietary encryption platform.</span> (837 and 835 files are the industry standard files used for the electronic submission of healthcare claim and payment information). Using these 2 sources of critical data, our team of experts and software logic identifies variances in the payment reimbursement and the healthcare providers contractual agreement with the Payer. The resulting data is organized in an easy to use and understandable format. As the healthcare providers partner we will work closely with their staff to strategize and devise a plan to proceed with recovering revenue lost due to underpaid, suspended and incorrectly denied claims.</p>
                                                </div>
                                                <div className='l2_block5_text1_img'> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block5_img1.webp' /></div>

                                            </div>
                                            <div className='l2_block5_text2'>
                                                <div className='l2_block5_text2_body'>
                                                    <div className='l2_block5_text2_block'>
                                                        <h3>Contracting</h3>
                                                        <p>The Aspire Med Max Billing Contract Modeler is designed to <span>"plug and play"</span> proposed reimbursement scenarios, rates and contract terms using actual historical claim utilization data to determine revenue outcomes based on a provider's specific case/service mix. This valuable tool allows the healthcare provider to determine the impact of the Payer's contract proposal as well as identify areas of opportunity to maximize revenue. Our team of specialists are experienced professionals having worked in administration, operations and contracting with major Payers. Consulting services as well as contract negotiation services are available.</p>
                                                    </div>
                                                    <div className='l2_block5_text2_block'>
                                                        <h3>Analytics/Reporting</h3>
                                                        <p><span>All data elements captured in the electronic 837/835 files as defined by CMS are housed in a secure client-specific environment on your own HIPPA compliant/encrypted server.</span> Aspire Med Max Billing offers a suit of standard claim utilization reports as well as the ability to offer customizable user-friendly reports. This comprehensive capture of all data related to a healthcare service experience provides a picture healthcare providers need to evaluate current and future operations.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='l2_block5_bottom'>
                                <div className='l2Wrapper'>
                                    <div className='l2_block5_bottom_text'>
                                    <button className='newscrollbtn' onClick={goToTop}>Recover & Maximize Reimbursement Now</button>
                                        <h2>Case Studies</h2>
                                        <p>We have tested our software by analyzing hundreds of millions in billed claims. <span>These claims were a collection of dead files, non-payments and underpayments</span>. We found that a range of 10%-29% of claims are paid incorrectly</p>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='l2_block6'>
                            <div className='l2Wrapper'>

                                <div className='l2_block6_wrapper'>

                                    <div className='l2_block6_block'>
                                        <div className='l2_block6_block_img'>
                                            <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block7_img1.webp' />
                                        </div>
                                        <div className='l2_block6_block_text'>
                                            <h2>Small Rural Hospital in Texas</h2>
                                            <p><span>This small hospital collected less than $19,000,000</span> in the last year (BCBS only).  Once they started working with us, we found and collected on $4,048,555.44 in underpaid
                                                claims for BCBS Only!</p>

                                            <button onClick={goToTop}>Maximize Your Reimbursement Now</button>


                                        </div>


                                    </div>
                                    <div className='l2_block6_block'>
                                        <div className='l2_block6_block_img'>
                                            <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block7_img2.webp' />
                                        </div>
                                        <div className='l2_block6_block_text'>
                                            <h2>Large Hospital Group in PA</h2>
                                            <p>When working with a large hospital group, we identified and collected on their 4th largest payor, Aetna, of <span>over $1,200,000 which represented 18% of their collections for the payor.</span>  Additionally, we also identified another $895,000 per year in potential revenue due to allowed charge master adjustments.</p>

                                            <button onClick={goToTop}> Revenue Now</button>


                                        </div>


                                    </div>
                                    <div className='l2_block6_block'>
                                        <div className='l2_block6_block_img'>
                                            <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block7_img3.webp' />
                                        </div>
                                        <div className='l2_block6_block_text'>
                                            <h2>Hospital Group in Ohio</h2>
                                            <p>This hospital's contract allowed for 90% of billed charges for BCBS,  we demonstrated that their actual payments paid on average 68% of billed charges.</p>

                                            <button onClick={goToTop}>Recover & Maximize Reimbursement Now</button>

                                        </div>


                                    </div>
                                    <div className='l2_block6_block'>
                                        <div className='l2_block6_block_img'>
                                            <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage2/underpatment_block7_img4.webp' />
                                        </div>
                                        <div className='l2_block6_block_text'>
                                            <h2>Hospital in St. Louis</h2>
                                            <p><span>We identified over $10,000,000/year</span> in Medicare/Medicaid for unpaid and underpaid claims for this hospital.</p>

                                            <button onClick={goToTop}>Maximize Your Reimbursement Now</button>


                                        </div>


                                    </div>



                                </div>
                            </div>
                        </div>


                        <div className='l2_block7'>
                            <div className='l2Wrapper'>
                                <div className='l2_block7_con'>
                                    <h2><strong>Start collecting <br />more</strong> <span>underpayments</span> <strong>today</strong></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
