
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import ListingTable from '../../../../listing/listing'

const AccessREP = ({ listData,setSearchGridData }) => {


  let mindate = useSelector((state) =>
    state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {}
  );
  let maxDate = useSelector((state) =>
    state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {}
  );

  let gridData = useSelector(state=>state.tableSlice?.otherResData?.tableAccessedREP?.grid_data ? state.tableSlice.otherResData.tableAccessedREP.grid_data: [])

  var modifyTableHeaders = [
    { val: "used_for_name", name: "Name" },
    { val: "used_for_email", name: "Accessed For Email" },
    { val: "used_by_name", name: "Accessed By" },
    { val: "used_by_email", name: "Accessed By Email" },
    { val: "used_for_phone", name: "Accessed For Phone" },
    { val: "used_by_phone", name: "Accessed By Phone" },

  ];

  useEffect(()=>{
    setSearchGridData(gridData)
    console.log("gridData",gridData);
  },[JSON.stringify(gridData)])

  let tableAccessedREP = useRef({
    tableId: "tableAccessedREP",
    tableTitle: "Folder Access Rep List View  ",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "users/admin-dashboard-list-grid",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "users/admin-dashboard-list-grid",
    },

    reqBody: {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        createdon_datetime: {
          $lte: maxDate,
        },
      },
      source: "folder_access_records",
      type: "folder_access_rep",
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5],
    sortFields: ["used_for_name", "used_for_email", "used_by_name", "used_by_email"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [

      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "status",
        label: "Change Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },

    ],
    buttons: [

      {
        id: "summarize_icon",
        label: "Notes",
        type: "other",
        name: "summarize_icon",
        title: "Notes",
      },
      {
        id: "folder_icon",
        label: "Folder View",
        type: "other",
        name: "folder_icon",
        title: "Folder View",
      },
      {
        id: "assessment_icon",
        label: "Training Report",
        type: "other",
        name: "assessment_icon",
        title: "Training Report",
      },
      {
        id: "streetview_icon",
        label: "Leads",
        type: "other",
        name: "streetview_icon",
        title: "Leads",
      }



    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "SEARCH ACCESS REP",
      type: "drawer",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",
        formInlineCLass: "userForm",
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 1,
            label: "Search Accessed For",
            name: "forname",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col2 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/folder-access-rep",
            reqBody: {
              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $lte: maxDate,
                },

              },
              "type": "accessed_for_name",
              "source": "folder_access_records"
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "user_for" },

          },

          {
            id: 2,
            label: "Search Accessed For Email ",
            name: "foremail",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col2 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/folder-access-rep",
            reqBody: {
              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $lte: maxDate,
                },

              },
              "type": "accessed_for_email",
              "source": "folder_access_records"
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "user_for" },

          },


          {
            id: 3,
            label: "Search Accessed By",
            name: "byname",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/folder-access-rep",
            reqBody: {
              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $lte: maxDate,
                },

              },
              "type": "accessed_by_name",
              "source": "folder_access_records"
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "user_by" },

          },


          {
            id: 4,
            label: "Search Accessed By Email ",
            name: "byemail",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/folder-access-rep",
            reqBody: {
              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $lte: maxDate,
                },

              },
              "type": "accessed_by_email",
              "source": "folder_access_records"
            }

            ,
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "user_by" },

          },



        ]
      }
    },


  });



  return (
    <>

      <div className='rolelist_mainwrp ' style={{ width: "100%" }}>

        <div className='rolelist_mainwrp_Table'>


          <ListingTable

            tableData={tableAccessedREP.current}
            dataset={listData}
            modifyHeaders={modifyTableHeaders}
          />

        </div>

      </div>


    </>
  )
}

export default AccessREP

