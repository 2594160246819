import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const getlandingpageoneuserdata = createAsyncThunk("getlandingpageoneuserdata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/get-user-info", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


export const addlandingpageonedata = createAsyncThunk("addlandingpageonedata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/add-user", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const addlandingpagetwodatalead = createAsyncThunk("addlandingpagetwodatalead", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/addorupdatelead", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


export const getlandingpagedata = createAsyncThunk("getlandingpagedata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/get-page-info", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

/////////////////////////////////  AssignStages //////////////////////////////////
export const updateStages = createAsyncThunk("landing/updatestages", async (reqbody) => {
    console.warn("<<<<<<<< payload from AssignStages thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "assign-stage/assign-stage", requestOptions);
    const updateStages = await response.json();

    return updateStages;
});

/////////////////////////////////  updateTags //////////////////////////////////
export const updateTags = createAsyncThunk("landing/updateUserTags", async (reqbody) => {
    console.warn("<<<<<<<< payload from updateTags thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-tag-add", requestOptions);
    const updateTags = await response.json();

    return updateTags;
});
export const addconverstiondata = createAsyncThunk("addconverstiondata", async (reqbody) => {
    console.warn("<<<<<<<< payload from updateTags thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/add-conversion", requestOptions);
    const updateTags = await response.json();

    return updateTags;
});


const LandingpagefontReducer = createSlice({
    name: 'LandingpagefontReducer',
    initialState: {
        landingpageoneuserdata: [],
        loader:false,
        landingpagesubmittionstate:0,
        addedinfo:[],
        Stagesdata:[],
        FetchStages: [],
        FetchTagDetailsData: [],


    },
    reducers: {
        clearLandingpageReducer(state, action) {
            state.landingpageoneuserdata = []
        },
        clearaddedinfo(state, action) {
            state.addedinfo = 0
        },
        clearlandingpagesubmittionstate(state, action) {
            state.landingpagesubmittionstate = 0
        },
    },
    extraReducers: {

        /////////////////////////// updateTags ///////////////////////////
        [updateTags.pending]: (state, action) => {

            // state.loading = true;
            state.resMessage = null;
            state.tagUpdated = false;

        },
        [updateTags.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.tagUpdated = true;
                state.FetchTagDetailsData.find((data) => data.key === action.meta.arg.tag_data.key).status = action.meta.arg.tag_data.status
            } else {
                state.loading = false;
                state.resMessage = 'Something Went Wrong';
                state.tagUpdated = false;

            }
        },
        [updateTags.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchTagDetailsData = []
            state.tagUpdated = false;



        },



        /////////////////////////// AssignStages ///////////////////////////
        [updateStages.pending]: (state, action) => {

            // state.loading = true;
            state.resMessage = null;

        },
        [updateStages.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;

                console.warn("<<<<<<<<<<<< state.FetchStages >>>>>>>>", [...state.FetchStages])

                state.FetchStages = state.FetchStages.map((stages) => ({ ...stages, status: stages.key === action.meta.arg.stage_slug ? 1 : 0 }))

                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

            }
        },
        [updateStages.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },




        
        [addlandingpageonedata.rejected]: (state, action) => {
            state.landingpagesubmittionstate = 3
        },
        [addlandingpageonedata.pending]: (state, action) => {

            state.landingpagesubmittionstate = 1
        },
        [addlandingpageonedata.fulfilled]: (state, action) => {
            state.landingpagesubmittionstate = 2
            state.addedinfo =  action.payload.results.res;
        },



        [getlandingpageoneuserdata.rejected]: (state, action) => {
           state.loader=false
        },
        [getlandingpageoneuserdata.pending]: (state, action) => {
            state.loader=true
        },
        [getlandingpageoneuserdata.fulfilled]: (state, action) => {
            state.loader=false   
            state.landingpageoneuserdata = action.payload.results;         
        },

        [addlandingpagetwodatalead.rejected]: (state, action) => {
            state.landingpagesubmittionstate = 3
        },
        [addlandingpagetwodatalead.pending]: (state, action) => {

            state.landingpagesubmittionstate = 1
        },
        [addlandingpagetwodatalead.fulfilled]: (state, action) => {
            state.landingpagesubmittionstate = 2
            state.addedinfo =  action.payload.results.res;
        },

        


        [getlandingpagedata.rejected]: (state, action) => {
           state.loader=false
        },
        [getlandingpagedata.pending]: (state, action) => {
            state.loader=true
        },
        [getlandingpagedata.fulfilled]: (state, action) => {
            state.loader=false   
            state.landingpageoneuserdata = action.payload.results;         
        },
    }
})

export default LandingpagefontReducer.reducer;
export const { clearLandingpageReducer, clearlandingpagesubmittionstate, clearaddedinfo } = LandingpagefontReducer.actions;












