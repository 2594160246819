import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Drawer, Grid, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import LoginIcon from '@mui/icons-material/Login';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from './FDSearch';
import IconButton from '@mui/material/IconButton';
// import SearchIcon from '@mui/icons-material/Search';

const FDLoginInfo = (props) => {
   
    // const [loginData,setLoginData]=useState(props.logininfo)
    console.log("Fdlogininfo props-----", props.logininfo);
    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 

    // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

    // ---- States here -----
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    const [open, setOpen] = React.useState(false); // snackbar const  
    const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  




    // ---- geeting and setting QueryParam -----
    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [paramId])

    // -------------------------------


    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)


    // --- loader --- 
    const loader = useSelector(state => (state.fetchUserDataReducer?.loading
    ) ? state.fetchUserDataReducer.loading
        : null);

    const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

    const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)

    const UserActivityData = useSelector(state => (state?.userFolderViewReducer?.userActivityList) ? state?.userFolderViewReducer?.userActivityList : [])


    // console.warn("<<<<<<<<<< folderdata goes here >>>>>>>>>", folderdata)



    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        // setErrPopup(false);
        // setErrMsgCopy(null);
    };

//    const formField={
//     <TextField id="filled-basic" label="Filled" variant="filled" />
//    }

 
    return (
        <>

            <div>
                <h3><FolderIcon /> {props.type}
                    <div className='srchfld_wrp' style={{marginLeft:"auto"}}>
                        <Tooltip title="Search">
                            <IconButton className='filtersearch'  >
                                <SearchIcon onClick={() => setSearchDraw(true)} />
                            </IconButton>
                        </Tooltip>

                    </div> </h3>
                {loading ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


                {props.logininfo?.length > 0 ? props.logininfo.map((x) =>
                    <div className='user-activity' key={x._id}>

                        <ul className='ua-data-block'>
                            {/* <li>Email: {x.email ? x.email : "N/A"}</li> */}
                            <li>Login Time:  {format(x.login_time, 'dd-MM-yyyy HH:mm:ss')} </li>
                            <li>Location : {x.city ? x.city : "N/A"}, {x.region ? x.region : "N/A"}{`(${x.postal ? x.postal : "N/A"})`},{x.country ? x.country : "N/A"}</li>
                            <li>IP: {x.ip ? x.ip : "N/A"}</li>
                            <li>ISP: {x.org ? x.org : "N/A"}</li>
                            <li>Host Name: {x.hostname ? x.hostname : "N/A"}</li>
                        </ul>

                    </div>) : (
                   
                    <p> No Data Found !</p>)}

            </div>



            {open ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={queryParamId ? "Updated Sucessfully" : " "}
            // action={action}
            />) : ""}


          
        {searchDraw?<FDSearch 
        fields=""
        type="Login info "/>:""}
        </>
    )
}

export default FDLoginInfo;






