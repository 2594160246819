import { Paper, TableContainer } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ListingTable from "../../../listing/listing";

export default function CompletedTrainingList({listData,setSearchGridData}) {


  let mindate = useSelector((state) =>
  state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {}
);
let maxDate = useSelector((state) =>
  state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {}
);


let gridData = useSelector(state=>state.tableSlice?.otherResData?.ComdashboardTable?.grid_data ? state.tableSlice.otherResData.ComdashboardTable.grid_data: [])

useEffect(()=>{
  setSearchGridData(gridData)
  console.log("gridData",gridData);
},[JSON.stringify(gridData)])



  var modifyTableHeaders = [
    { val: "name", name: "Name" },
    { val: "email", name: "Email" },
    { val: "training_name", name: "Training Name" },
    { val: "total_lesson_count", name: "Total Lesson" },  
    { val: "done_training_count", name: "Lesson Completed" }, 
    { val: "done_training_percentage", name: "Training Percentage" }, 
];




  let ComdashboardTable = useRef({
    tableId: "ComdashboardTable",
    tableTitle: "Training List View",
    showReload: true,
    showFilter: true,
    
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "users/admin-dashboard-list-grid",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "users/admin-dashboard-list-grid",
    },

    reqBody: {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        createdon_datetime: {
          $gte: mindate,
          $lte: maxDate,
        },
      },
      source:"google_events",
        type:"training",
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5],
    sortFields: ["name", "email", "training_name"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Change Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    buttons: [
    
      {
        id: "summarize_icon",
        label: "Notes",
        type: "other",
        name: "summarize_icon",
        title: "Notes",
      },
      {
        id: "folder_icon",
        label: "Folder View",
        type: "other",
        name: "folder_icon",
        title: "Folder View",
      },
      {
        id: "assessment_icon",
        label: "Training Report",
        type: "other",
        name: "assessment_icon",
        title: "Training Report",
      },
      {
        id: "streetview_icon",
        label: "Leads",
        type: "other",
        name: "streetview_icon",
        title: "Leads",
      },
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "SEARCH TRAINING LIST",
      type: "drawer",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",
        formInlineCLass: "userForm", 
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 1,
            label: "Search By Name",
            name: "name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col2 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-training",
            reqBody: {
              "condition": {
                  "limit": 5,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "name"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
                 
              },
              "type": "user_name",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },

          },
       
          {
            id: 2,
            label: "Search By Email ",
            name: "data_base_variable",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col2 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-training",
            reqBody: {
              "condition": {
                  "limit": 5,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "name"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
                 
              },
              "type": "email",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },

          },


          {
              id: 3,
              label: "Search By Training Names",
              name: "name",
              type: "autoComplete",
              className: 'list_search_item countDiv__column--col6 ',
              base_url: process.env.REACT_APP_API_URL + "dashboard-cards/auto-complete-training",
              reqBody:
              {
                  "condition": {
                      "limit": 5,
                      "skip": 0
                  },
                  "sort": {
                      "type": "asc",
                      "field": "name"
                  },
                  "searchcondition": {
                    createdon_datetime: {
                      $gte: mindate,
                      $lte: maxDate,
                    },
                     
                  },
                  "type": "training_name",
                  "source": "done_training_list"
              },
              sx: { m: 1, minWidth: 300 },
              // payloadFormat: { key: "_id", param: "$regex", options: "i" },
              payloadFormat: { key: "training_id" },
  
            },


           

         
         
        ]
      }
    },
  });

  return (
    <>
        <TableContainer component={Paper}>
          <ListingTable
            tableData={ComdashboardTable.current}
            dataset={listData}
            modifyHeaders={modifyTableHeaders}
          />
        </TableContainer>
    </>
  );
}
