import * as React from 'react';
import { Suspense } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { accesscontroll } from '../helper/helperFunctions';

import Layout from "../Layout";
import Contract from '../Pages/Backend/ContractManagement/Contract/Contract';
import ContractAddEdit from '../Pages/Backend/ContractManagement/Contract/ContractAddEdit';
import ContractSlag from '../Pages/Backend/ContractManagement/ContractSlag/ContractSlag';
import ContractSlagAddEdit from '../Pages/Backend/ContractManagement/ContractSlag/ContractSlagAddEdit';
import Dashboard from '../Pages/Backend/Dashboard/Dashboard';
import JobticketsCategory from '../Pages/Backend/Jobtickets/JobticketsCategory/JobticketsCategory';
import JobticketsCategoryAddEdit from '../Pages/Backend/Jobtickets/JobticketsCategory/JobticketsCategoryAddEdit';
import AddEditJobtickets from '../Pages/Backend/Jobtickets/ManageJobtickets/AddEditJobtickets';
import ManageJobtickets from '../Pages/Backend/Jobtickets/ManageJobtickets/ManageJobtickets';
import Profile from '../Pages/Backend/myaccount/profile';
import { TrainingCenter } from '../Pages/Backend/Training/TrainingCenter/TrainingCenter';
import FolderView from '../Pages/Backend/userManagement/folderView/folderview';
import AddEdituser from '../Pages/Backend/userManagement/userAddEdit/userAddEdit';
import UserManagement from '../Pages/Backend/userManagement/userList/userList';
import Home from '../Pages/Frontend/Home/Home';
import LandingPage1 from '../Pages/Frontend/LandingPage/LandingPage1/LandingPage1';
import LandingPage2 from '../Pages/Frontend/LandingPage/LandingPage2/LandingPage2';
import LandingPage3 from '../Pages/Frontend/LandingPage/LandingPage3/LandingPage3';
import LandingPage4 from '../Pages/Frontend/LandingPage/LandingPage4/LandingPage4';
import ForgotPassword from '../Pages/login/forgotPassword';
import { Miscellaneous } from '../Pages/Calendar/Miscellaneous/Miscellaneous';
// import { BookedSlot } from '../Pages/Calendar/BookedSlot';

// Import Email Related Component from EmailTemplateManagement Folder...

import EmailSlug from '../Pages/Backend/EmailTemplateManagement/EmailSlug/EmailSlug';
import EmailSlugAddEdit from '../Pages/Backend/EmailTemplateManagement/EmailSlug/EmailSlugAddEdit';

// import Home from '../pages/Frontend/Home/Home';

import Login from '../Pages/login/login';
import ResetPassword from '../Pages/login/resetPassword';
import Noaccesspage from '../Pages/Backend/Noaccesspage'
// import NoPage from '../Pages/404page';

// import { ResetPassword } from '../Pages/ForgetPassword/ResetPassword';
// import { ForgotPassword } from '../Pages/ForgetPassword/ForgotPassword';
import store from '../store';
import ReplyJobticket from '../Pages/Backend/Jobtickets/ManageJobtickets/ReplyJobticket';
import TagList from '../Pages/Backend/TagManagement/Tag/TagList';
import TagAddEdit from '../Pages/Backend/TagManagement/Tag/TagAddEdit';
import HmDashboard from '../Pages/Backend/HmDashboard/HmDashboard';
import RepAndAreaDashboard from '../Pages/Backend/RepAndAreaDashboard/RepAndAreaDashboard';
import ManageRoundRodin from '../Pages/Backend/ManageRoundRobin/ManageRoundRodin';
import HiringManagment from '../Pages/Backend/HirinManagement/HiringManagment';
import { ThankyouPage } from '../Pages/Frontend/Thankyou';
import Success from '../Pages/Frontend/Success';
import CancelSlot from '../Pages/Frontend/CancelSlot';
import AuditAnalyze from '../Pages/Frontend/LandingPage/LandingPage3/AuditAnalaize/AuditAnalyze';
import AudtingFrim from '../Pages/Frontend/LandingPage/LandingPage3/AudtingFrim.js/AudtingFrim';
import AuditTestimonial from '../Pages/Frontend/LandingPage/LandingPage3/AuditTestimonial/AuditTestimonial';
import LandingPage5 from '../Pages/Frontend/LandingPage/LandingPage5/LandingPage5';
import NoPage from '../Pages/404page';
import Auditbanner from '../Pages/Frontend/LandingPage/LandingPage3/AuditBanner/Auditbanner';
import OngoingManagement from '../Pages/Frontend/LandingPage/LandingPage3/OngoingManagement/OngoingManagement';
import BDDdashboard from '../Pages/Backend/BDDdashboard/BDDdashboard';
import AnalysisImplementation from '../Pages/Frontend/LandingPage/LandingPage3/AnalysisImplementation/AnalysisImplementation';
import OurPromises from '../Pages/Frontend/LandingPage/LandingPage3/OurPromises/OurPromises';
import WorkComp from '../Pages/Frontend/LandingPage/LandingPage3/WorkComp/WorkComp';
import AerDashboard from '../Pages/Backend/AerDashboard/AerDashboard';
import RepDashboard from '../Pages/Backend/RepDashboard/RepDashboard';
import LandingPage6 from '../Pages/Frontend/LandingPage/LandingPage6/LandingPage6';
import Patnership from '../Pages/Frontend/LandingPage/LandingPage6/Patnership/Patnership';
import BrandTestimonial from '../Pages/Frontend/LandingPage/LandingPage6/BrandTestimonial/BrandTestimonial';
import ClintInfoForm from '../Pages/ClintInfoForm/ClintInfoForm';
import LandingPage3Video from '../Pages/Frontend/LandingPage/LandingPage3Video/LandingPage3Video';
import LandingPage4Video from '../Pages/Frontend/LandingPage/LandingPage4Video/LandingPage4Video';
import LandingPage2Video from '../Pages/Frontend/LandingPage/LandingPage2Video/LandingPage2Video';
import ViewContractDetails from '../Pages/Backend/ContractManagement/Contract/ViewContractDetails';
import Faq from '../Pages/Backend/LeadManagement/FaqManagement/Faq';
import FaqCategory from '../Pages/Backend/LeadManagement/FaqManagement/FaqCategory';
import HmGroupAddEdit from '../Pages/Backend/HmGroup/HmGroupAddEdit';
import HmGroupList from '../Pages/Backend/HmGroup/HmGroupList';
import Informationtemplate from '../Pages/Backend/InformationlinkModule/Informationtemplate';
import AddEditFaqCategory from '../Pages/Backend/LeadManagement/FaqManagement/AddEditFaqCategory';
import AddEditFaq from '../Pages/Backend/LeadManagement/FaqManagement/AddEditFaq';
import ContractSuccess from '../Pages/Backend/ContractManagement/Contract/ContractSuccess';



// import TrainingMaterialCentarList from '../Pages/Backend/Training/Training Material Centar/TrainingMaterialCentarList';



// ---- Back End Lazy Loading -----
// const Dashboard = React.lazy(() => import('../Pages/Backend/dashboard/dashboard'))
const RoleAddEdit = React.lazy(() => import('../Pages/Backend/Role/RoleAddEdit'))
const AddEditProgram = React.lazy(() => import('../Pages/Backend/Program/AddEditProgram'))
const AddTraining = React.lazy(() => import('../Pages/Backend/Training/TrainingCategory/AddTrainingCategory'));
const AddLesson = React.lazy(() => import('../Pages/Backend/Training/TrainingLesson/AddTrainingLesson'));
const TrainingList = React.lazy(() => import('../Pages/Backend/Training/TrainingCategory/TrainingCategoryList'));
const LessonList = React.lazy(() => import('../Pages/Backend/Training/TrainingLesson/TrainingLessonList'));
const RoleList = React.lazy(() => import('../Pages/Backend/Role/RoleList'));
const LandingPageAddEdit = React.lazy(() => import('../Pages/Backend/LandingPage/LandingPageAddEdit'))
const LandingPageList = React.lazy(() => import('../Pages/Backend/LandingPage/LandingPageList'))

const InformationAddEdit = React.lazy(() => import('../Pages/Backend/InformationlinkModule/InformationAddEdit'))
const InformationList = React.lazy(() => import('../Pages/Backend/InformationlinkModule/InformationList'))





const ProgramList = React.lazy(() => import('../Pages/Backend/Program/ProgramList'))
const LeadList = React.lazy(() => import('../Pages/Backend/LeadManagement/LeadList'))
const LeadEdit = React.lazy(() => import('../Pages/Backend/LeadManagement/LeadEdit'))
// const LeadBulkAddEdit = React.lazy(()=> import('../Pages/Backend/LeadManagement/LeadBulkAddEdit'))
const TrainingMaterialList = React.lazy(() => import('../Pages/Backend/Training/TrainingMaterial/TrainingMaterialList'));
const AddEditMaterial = React.lazy(() => import('../Pages/Backend/Training/TrainingMaterial/AddEditMaterial'));
const TrainingMaterialCentarList = React.lazy(() => import('../Pages/Backend/Training/Training Material Centar/TrainingMaterialCentarList'));

const QuizList = React.lazy(() => import("../Pages/Backend/Training/Quizzes/QuizList"));
const QuizAddEdit = React.lazy(() => import("../Pages/Backend/Training/Quizzes/QuizAddEdit"));
const LeadFolderView = React.lazy(() => import('../Pages/Backend/LeadManagement/LeadFolderView/LeadFolderView'))
const CampaignAddEdit = React.lazy(() => import('../Pages/CampaignManagement/CampaignAddEdit'))
const CampaignList = React.lazy(() => import('../Pages/CampaignManagement/CampaingList'))

const CreateEvent = React.lazy(() => import('../Pages/Calendar/CreateEvent'))
const AvaibilityList = React.lazy(() => import('../Pages/Calendar/AvaibilityList'));
const AvailableSlots = React.lazy(() => import('../Pages/Calendar/AvailableSlot'));
const Calendar = React.lazy(() => import('../Pages/Frontend/Calendar'));
const BookedSlot = React.lazy(() => import('../Pages/Calendar/BookedSlot'));

const StageList = React.lazy(() => import('../Pages/Backend/StageDistribution/StageList'));
const StageAddEdit = React.lazy(() => import('../Pages/Backend/StageDistribution/StageAddEdit'));
const EmailTemplateAddEdit = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/EmailTemplate/EmailTemplateAddEdit'));
const EmailTemplateList = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/EmailTemplate/EmailTemplateList'));
// const BookedSlot = React.lazy(() => import('../Pages/Calendar/BookedSlot'));
const AddEditFormModule = React.lazy(() => import('../Pages/Backend/FormManagement/FormModule/AddEditForm'))
const AddEditFieldModule = React.lazy(() => import('../Pages/Backend/FormManagement/FieldModule/AddEditField'))
const FieldList = React.lazy(() => import('../Pages/Backend/FormManagement/FieldModule/FieldList'))
const FormList = React.lazy(() => import('../Pages/Backend/FormManagement/FormModule/FormList'))
const AddEditGroupModule = React.lazy(() => import('../Pages/Backend/FormManagement/GroupModule/GroupAddEdit'))
const GroupList = React.lazy(() => import('../Pages/Backend/FormManagement/GroupModule/GroupList'))
const TriggerManagement = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/TriggerManagement/TriggerManagement'))
const TriggerAddEdit = React.lazy(() => import('../Pages/Backend/EmailTemplateManagement/TriggerManagement/TriggerAddEdit'))
const cookies = new Cookies();

console.warn("<<<< cookie details from routes >>>>", cookies.cookies)


// -----------------------

// ---- Front End Lazy Loading -----
// const Home = React.lazy(() => import('../Pages/FrontEndPages/Home'))
// -------------------------------------
console.warn(" <<<<< loginin >>>>>", store.getState().loginSlice.isLoggedIn)

const useAuth = () => {


  if (store.getState().loginSlice.isLoggedIn || (store.getState().loginSlice?.userInfo && Object.keys(store.getState().loginSlice.userInfo).length > 0)) {
    return true;
  }
}

const PrivateRoute = ({ children }) => {
  console.log("authed", useAuth());
  const auth = useAuth();

  return (
    auth === true ? children : <Navigate to="/login" replace={true} />
  )
}

const AccessControll = ({ children, path }) => {
  console.log("path", path, children);

  const isacceable = accesscontroll(path, cookies.getAll());
  console.log("isacceable", isacceable);

  console.log("authed", useAuth());
  const auth = useAuth();

  return (
    auth === true ? isacceable ? children : <Noaccesspage /> : <Navigate to="/login" replace={true} />
  )

}

const LoginRoute = ({ children, redirectPath }) => {
  const auth = useAuth();
  return (auth !== true) ? children : <Navigate to={redirectPath} replace={true} />
}






// const [userInfo,    setuserInfoCookie] = useCookies(["userinfocookies"]);
// console.warn("<<< userinfocookies goes here >>>>", userInfo.userinfocookies)

const GetRoutes = () => {

  console.log(" process.env", process.env);

  return (
    <BrowserRouter>
      <Routes>

        {/* --------------------Front-End Pages-------------------- */}

        {/* <Route path="/home" element={<Suspense fallback={<></>}><Home /></Suspense>} />{" "} */}

        {/* 
        <Route path="/home"  element={<Home />} />{" "} */}
        <Route path="login" element={<Login />} />{" "}
        <Route path="forget-password" element={<ForgotPassword />} />{" "}
        <Route path="reset-password" element={<ResetPassword />} />{" "}
        <Route path="viewcontract/:user_id/:contract_id" element={<ViewContractDetails />} />{" "}
        <Route path="contract-success" element={<ContractSuccess />} />{" "}
        {/* <Route path="/404page" element={<NoPage />} />{" "}  */}

        <Route path='/noaccess' element={<Noaccesspage />} />
        <Route path="/cif-from/:lead_id/:form_id" element={<Suspense fallback={<></>}><ClintInfoForm /></Suspense>} />

        {/* ----- Backend Pages ------ */}
        <Route path="/" element={<Layout />}>
          <Route path="/" index element={(process.env.REACT_APP_IS_BACKEND && process.env.REACT_APP_IS_BACKEND == 'true') ? <Login /> : <Home />} />
          <Route path=":uniquename" element={<Home />} />
          <Route path="landing-page2" element={<LandingPage2 />} />
          <Route path="landing-page2Video" element={<LandingPage2Video />} />

          <Route path="landing-page3" element={<LandingPage3 />} />
          <Route path="landing-page3Video" element={<LandingPage3Video />} />

          <Route path="landing-page3/html" element={< Patnership />} />
          <Route path="landing-page3/html1" element={<BrandTestimonial />} />
          <Route path="landing-page4" element={<LandingPage4 />} />
          <Route path="landing-page5" element={<LandingPage5 />} />
          <Route path='thankyou' element={<Success />} />
          <Route path='landing-page6' element={<LandingPage6 />} />
          <Route path='landing-page6-video' element={<LandingPage6 />} />
          <Route path='landing-page4Video' element={<LandingPage4Video />} />
          {/* -------------------- JobTickets -------------------- */}

          <Route path="/jobticket-category" element={<PrivateRoute><Suspense fallback={<></>}><JobticketsCategory /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-jobtickets-category" element={<PrivateRoute><Suspense fallback={<></>}><JobticketsCategoryAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-jobtickets-category/:id" element={<PrivateRoute><Suspense fallback={<></>}><JobticketsCategoryAddEdit /></Suspense></PrivateRoute>} />{" "}



          <Route path="/manage-jobtickets" element={<PrivateRoute><Suspense fallback={<></>}><ManageJobtickets /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-Jobtickets" element={<PrivateRoute><Suspense fallback={<></>}><AddEditJobtickets /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-jobtickets/:id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditJobtickets /></Suspense></PrivateRoute>} />{" "}
          <Route path="/reply-jobticket/:id" element={<PrivateRoute><Suspense fallback={<></>}><ReplyJobticket /></Suspense></PrivateRoute>} />{" "}

{/* {----------------------- Faq Management -----------------------------------------} */}
          <Route path="/faq" element={<PrivateRoute><Suspense fallback={<></>}><Faq /></Suspense></PrivateRoute>} />{" "}
          <Route path="/faq-category" element={<PrivateRoute><Suspense fallback={<></>}><FaqCategory /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-faqcategory" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFaqCategory /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-faq" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFaq /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-faq/:id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFaq /></Suspense></PrivateRoute>} />{" "}


          {/* -------------------LANDING PAGE ROUTE-----------------------------*/}

          <Route path="/add-landingpage" element={<PrivateRoute><Suspense fallback={<></>}><LandingPageAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-landingpage/:id" element={<PrivateRoute><Suspense fallback={<></>}><LandingPageAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/list-landingpage" element={<PrivateRoute><Suspense fallback={<></>}><LandingPageList /></Suspense></PrivateRoute>} />{" "}

          {/* --------------------------------------------------------------------------------------------------- */}

          {/* ------------------------------------INFORMATION LINK MODULE------------------------ */}

          <Route path="/add-information" element={<PrivateRoute><Suspense fallback={<></>}><InformationAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-information/:id" element={<PrivateRoute><Suspense fallback={<></>}><InformationAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/list-information" element={<PrivateRoute><Suspense fallback={<></>}><InformationList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/information-link" element={<PrivateRoute><Suspense fallback={<></>}><Informationtemplate /></Suspense></PrivateRoute>} />{" "}

          {/* ------------------------------------------------------------------- */}








          {/* -------------------****CAMPAIGN MANAGEMENT PAGE ROUTE****-----------------------------*/}

          <Route path="/add-campaign" element={<PrivateRoute><Suspense fallback={<></>}><CampaignAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-campaign/:id" element={<PrivateRoute><Suspense fallback={<></>}><CampaignAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/list-campaign" element={<PrivateRoute><Suspense fallback={<></>}><CampaignList /></Suspense></PrivateRoute>} />{" "}

          {/* --------------------------------------------------------------------------------------------------- */}


          {/* -------------------------- USER MANAGEMENT ----------------------- */}

          <Route path="/useraddedit" element={<PrivateRoute><Suspense fallback={<></>}><AddEdituser /></Suspense></PrivateRoute>} />{" "}
          <Route path="/usermanagement" element={<PrivateRoute><Suspense fallback={<></>}><UserManagement /></Suspense></PrivateRoute>} />
          <Route path="/folderview" element={<PrivateRoute><Suspense fallback={<></>}><FolderView /></Suspense></PrivateRoute>} />

          {/* --------------------------------------------------------------------------------------------------- */}
          
          <Route path="/super_admindashboard" element={<PrivateRoute><Suspense fallback={<></>}><Dashboard /></Suspense></PrivateRoute>} />{" "}

          <Route path="/dashboard" element={<PrivateRoute><Suspense fallback={<></>}><Dashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/hm-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><HmDashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/rep-aer-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><RepAndAreaDashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/rep-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><RepDashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/aer-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><AerDashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/bdd-dashboard" element={<PrivateRoute><Suspense fallback={<></>}><BDDdashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-training" element={<PrivateRoute><Suspense fallback={<></>}><AddTraining /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-training/:_id" element={<Suspense fallback={<></>}><AddTraining /></Suspense>} />{" "}
          <Route path="/training-category/list" element={<PrivateRoute><Suspense fallback={<></>}><TrainingList /></Suspense> </PrivateRoute>} />{" "}
          <Route path="/add-lesson" element={<PrivateRoute><Suspense fallback={<></>}><AddLesson /></Suspense> </PrivateRoute>} />{" "}
          <Route path="/training-lesson/list" element={<PrivateRoute><Suspense fallback={<></>}><LessonList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-lesson/:_id" element={<Suspense fallback={<></>}><AddLesson /></Suspense>} />{" "}
          <Route path="/training-material" element={<PrivateRoute><Suspense fallback={<></>}><TrainingMaterialList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/material-add" element={<PrivateRoute><Suspense fallback={<></>}><AddEditMaterial /></Suspense></PrivateRoute>} />{" "}
          <Route path="/material-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditMaterial /></Suspense></PrivateRoute>} />{" "}
          {/* <Route path="/training-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditMaterial /></Suspense></PrivateRoute>} />{" "} */}
          <Route path="/training-material-centar-list" element={<PrivateRoute><Suspense fallback={<></>}><TrainingMaterialCentarList /></Suspense></PrivateRoute>} />{" "}

          <Route path="/quizzes" element={<PrivateRoute><Suspense fallback={<></>}><QuizList /></Suspense></PrivateRoute>} />
          <Route path="/quiz-add" element={<PrivateRoute><Suspense fallback={<></>}><QuizAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/quiz-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><QuizAddEdit /></Suspense></PrivateRoute>} />

          {/* <Route path="/role-add" element={<AccessControll path={'/role-add'}><Suspense fallback={<></>}><RoleAddEdit /></Suspense></AccessControll>} /> */}
          <Route path="/role-add" element={<PrivateRoute><Suspense fallback={<></>}><RoleAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/program-add" element={<PrivateRoute><Suspense fallback={<></>}><AddEditProgram /></Suspense></PrivateRoute>} />
          <Route path="/role-list" element={<PrivateRoute><Suspense fallback={<></>}><RoleList /></Suspense></PrivateRoute>} />
          <Route path="/program-list" element={<PrivateRoute><Suspense fallback={<></>}><ProgramList /></Suspense></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Suspense fallback={<></>}><Profile /></Suspense></PrivateRoute>} />


          <Route path="/role-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><RoleAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/program-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditProgram /></Suspense></PrivateRoute>} />
          <Route path="/training-center" element={<Suspense fallback={<></>}><TrainingCenter /></Suspense>} />
          <Route path="/training-center/:cat_id" element={<Suspense fallback={<></>}><TrainingCenter /></Suspense>} />
          <Route path="/training-center/:cat_id/:lesson_id" element={<Suspense fallback={<></>}><TrainingCenter /></Suspense>} />


          {/* -----------------------------------------------Calender Management--------------------------------------------------- */}

          <Route path="/available-slots" element={<PrivateRoute><Suspense fallback={<></>}><AvailableSlots /></Suspense></PrivateRoute>} />
          <Route path="/create-event" element={<PrivateRoute><Suspense fallback={<></>}><CreateEvent /></Suspense></PrivateRoute>} />
          <Route path="/avilibility-list" element={<PrivateRoute><Suspense fallback={<></>}><AvaibilityList /></Suspense></PrivateRoute>} />
          <Route path="/edit-event/:slotID" element={<PrivateRoute><Suspense fallback={<></>}><CreateEvent /></Suspense></PrivateRoute>} />
          <Route path="/booked-slot" element={<PrivateRoute><Suspense fallback={<></>}><BookedSlot /></Suspense></PrivateRoute>} />
          <Route path="/miscellaneous" element={<PrivateRoute><Suspense fallback={<></>}><Miscellaneous /></Suspense></PrivateRoute>} />{" "}
          <Route path="/calendar/:lead_id/:orgrole/:orgid" element={<Suspense fallback={<></>}><Calendar /></Suspense>} />{" "}
          <Route path="/reschedule-slot/:identifier/:orgrole/:orgid" element={<Suspense fallback={<></>}><Calendar /></Suspense>} />{" "}
          <Route path='/thankyou/:page/:lead_id' element={<Suspense fallback={<></>}><ThankyouPage /> </Suspense>} />{" "}
          <Route path="/cancel-slot/:identifier" element={<Suspense fallback={<></>}><CancelSlot /></Suspense>} />

          {/* -----------------------------------------------Contract Management--------------------------------------------------- */}

          <Route path="/contract-list" element={<PrivateRoute><Suspense fallback={<></>}><Contract /></Suspense></PrivateRoute>} />
          <Route path="/contract-slug-list" element={<PrivateRoute><Suspense fallback={<></>}><ContractSlag /></Suspense></PrivateRoute>} />
          <Route path="/add-contract" element={<PrivateRoute><Suspense fallback={<></>}><ContractAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/edit-contract/:id" element={<PrivateRoute><Suspense fallback={<></>}><ContractAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/add-contract-slug" element={<PrivateRoute><Suspense fallback={<></>}><ContractSlagAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/edit-contract-slug/:id" element={<PrivateRoute><Suspense fallback={<></>}><ContractSlagAddEdit /></Suspense></PrivateRoute>} />


          {/* -----------------------------------------------Lead Management--------------------------------------------------- */}
          <Route path="/lead-list" element={<PrivateRoute><Suspense fallback={<></>}><LeadList /></Suspense></PrivateRoute>} />
          <Route path="/lead-list/:role/:id" element={<PrivateRoute><Suspense fallback={<></>}><LeadList /></Suspense></PrivateRoute>} />
          <Route path="/lead-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><LeadEdit /></Suspense></PrivateRoute>} />
          <Route path="/lead-add" element={<PrivateRoute><Suspense fallback={<></>}><LeadEdit /></Suspense></PrivateRoute>} />
          <Route path="/lead-folder-view/:id" element={<PrivateRoute><Suspense fallback={<></>}><LeadFolderView /></Suspense></PrivateRoute>} />
          {/* <Route path="/lead-bulk-add" element={<PrivateRoute><Suspense fallback={<></>}><LeadEdit /></Suspense></PrivateRoute>} /> */}
          {/* -----------------------------------------------email template Management--------------------------------------------------- */}
          <Route path="/email-template-add" element={<PrivateRoute><Suspense fallback={<></>}><EmailTemplateAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/email-template-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><EmailTemplateAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/email-template-list" element={<PrivateRoute><Suspense fallback={<></>}><EmailTemplateList /></Suspense></PrivateRoute>} />

          {/*-------------------------------------------------Tag Management------------------------------------------------------*/}
          <Route path="/tag-list" element={<PrivateRoute><Suspense fallback={<></>}><TagList /></Suspense></PrivateRoute>} />
          <Route path="/tag-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><TagAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/tag-edit" element={<PrivateRoute><Suspense fallback={<></>}><TagAddEdit /></Suspense></PrivateRoute>} />

          {/*------------------------------------------Form Management---------------------------------------------------------- */}
          <Route path="/add-form" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFormModule /></Suspense></PrivateRoute>} />
          <Route path="/form-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFormModule /></Suspense></PrivateRoute>} />
          <Route path="/add-field" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFieldModule /></Suspense></PrivateRoute>} />
          <Route path="/field-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddEditFieldModule /></Suspense></PrivateRoute>} />
          <Route path="/field-module/list" element={<PrivateRoute><Suspense fallback={<></>}><FieldList /></Suspense></PrivateRoute>} />
          <Route path="/form-module/list" element={<PrivateRoute><Suspense fallback={<></>}><FormList /></Suspense></PrivateRoute>} />
          <Route path="/add-group" element={<PrivateRoute><Suspense fallback={<></>}><AddEditGroupModule /></Suspense></PrivateRoute>} />
          <Route path="/edit-group/:_id" element={<Suspense fallback={<></>}><AddEditGroupModule /></Suspense>} />{" "}
          <Route path="/group-module/list" element={<PrivateRoute><Suspense fallback={<></>}><GroupList /></Suspense></PrivateRoute>} />

          {/* ---------------------------------------------------Email Management--------------------------------------------------- */}

          <Route path="/email-slug" element={<PrivateRoute><Suspense fallback={<></>}><EmailSlug /></Suspense></PrivateRoute>} />
          <Route path="/email-slug-add" element={<PrivateRoute><Suspense fallback={<></>}><EmailSlugAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/email-slug-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><EmailSlugAddEdit /></Suspense></PrivateRoute>} />
          {/* ---------------------------------------------------Trigger Management--------------------------------------------------- */}
          <Route path="/trigger-list" element={<PrivateRoute><Suspense fallback={<></>}><TriggerManagement /></Suspense></PrivateRoute>} />
          <Route path="/trigger-add" element={<PrivateRoute><Suspense fallback={<></>}><TriggerAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/trigger-edit/:_id" element={<PrivateRoute><Suspense fallback={<></>}><TriggerAddEdit /></Suspense></PrivateRoute>} />



          {/* ---------------------------------------------------Stage Distribution Management--------------------------------------------------- */}


          <Route path="/stage-distribution-list" element={<PrivateRoute><Suspense fallback={<></>}><StageList /></Suspense></PrivateRoute>} />
          <Route path="/stage-add" element={<PrivateRoute><Suspense fallback={<></>}><StageAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/edit-stage/:id" element={<PrivateRoute><Suspense fallback={<></>}><StageAddEdit /></Suspense></PrivateRoute>} />


          {/* ---------------------------------------------------Manage Round Robin--------------------------------------------------- */}
          <Route path="/manage-round-rodin" element={<PrivateRoute><Suspense fallback={<></>}><ManageRoundRodin /></Suspense></PrivateRoute>} />


          {/* ---------------------------------------------------Hiring Management System--------------------------------------------------- */}

          <Route path="/hiring-managment" element={<PrivateRoute><Suspense fallback={<></>}><HiringManagment /></Suspense></PrivateRoute>} />
          {/* <Route path="/404page" element={<NoPage />} />{" "} */}

          {/* ---------------------------------------------------HM GROUP Management System--------------------------------------------------- */}

          <Route path="/hm-group-add" element={<PrivateRoute><Suspense fallback={<></>}><HmGroupAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/hm-group-edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><HmGroupAddEdit /></Suspense></PrivateRoute>} />
          <Route path="/hm-group-list" element={<PrivateRoute><Suspense fallback={<></>}><HmGroupList /></Suspense></PrivateRoute>} />


        </Route>
      </Routes>
    </BrowserRouter>
  );

}

export default GetRoutes;