import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import StateArray from '../../../../assets/json/state';
import fetchUserDataReducer, { fetchUserData } from '../fetchUserDataReducer';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ShowChartIcon from '@mui/icons-material/ShowChart';
// import { clearInterviewData, ContractLogsInfo, folderAccessInfo, interviewFormdata, StageLogsInfo, TagLogsInfo, UserActivity } from './folderViewReducer';
import { FetchRoles, FetchTrainingStatus, logInInfo, Notes } from '../userList/userListReducer';


import { clearInterviewData, ContractLogsInfo, folderAccessInfo, interviewFormdata, StageLogsInfo, TagLogsInfo, UserActivity, getMailInfo, getNotes, getStageLifeCycleData, getTagLifeCycleData, folderViewCounts } from './folderViewReducer';

// import { FetchRoles, FetchTrainingStatus } from '../userList/userListReducer';
import Interview from './Interview';
import LoginIcon from '@mui/icons-material/Login';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import UserActivityBlock from './userActivity';
import PersonalInfoBlock from './personalInfo';
import RightPanelBlock from './rightpanel';
import { startresolve } from '../../../../helper/ResolveReducer';
import FolderViewAccess from './folderViewAccess';
import { getDatafollowUpCallList } from '../../LeadManagement/LeadReducer';
// import FollowUpcall from '../../LeadManagement/FollowUpcall';
import { FollowUpCall } from './FollowUpCall';
import ContractManagement from './contractManage';
import TrainingProgress from './trainingProgress';
import ContractManage from './contractManage';
import FDLoginInfo from './FDLoginInfo';
import EmailVew from './EmailVew';
import Notesview from './Notesview';
import StageLifeCycle from './StageLifeCycle';
import TagLifeCycle from './TagLifeCycle';

const FolderView = (props) => {
  // const [users, setusers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const param = useParams();//---- userParam 

  // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

  // ---- States here -----

  const [userInfo, setUserInfo] = useState(null); // Set User Information from Cookie
  const [formSubmissionStateUserForm, setFormSubmissionStateUserForm] = useState(0) //**** FOR FORM SUBMIT ****//
  const [locationArry, setLocationArry] = useState([]);
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [programdataArry, setprogramdataArry] = useState([]);
  const [showDiv, setShowDive] = useState("UserActivity");
  const [open, setOpen] = React.useState(false); // snackbar const
  const [loaderr, setLoaderr] = useState(false);
  const [filteredRoles, setFilteredRoles] = useState([])


  // ---- geeting and setting QueryParam -----
  const queryParam = useLocation();
  const paramId = queryParam.search.split("=");
  // console.log("queryParamId", queryParamId, queryParam);


  useEffect(() => {
    if (paramId[1]) {
      setQueryParamId(paramId[1])
    }
  }, [JSON.stringify(paramId)])

  // -------------------------------


  // ---- Cookies here ----
  const [cookieData, setCookieData] = useCookies();
  // console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)



  // ----- On editDataRef trigger ----
  const goBackBtnClick = useSelector(state => (state.formSlice?.customButtonClicked?.userFolderViewForm && state.formSlice?.customButtonClicked?.userFolderViewForm?.goBack001) ? state.formSlice?.customButtonClicked?.userFolderViewForm?.goBack001 : false);

  // --- loader --- 
  const loader = useSelector(state => (state.fetchUserDataReducer?.loading
  ) ? state.fetchUserDataReducer.loading
    : null);

  const folderdata = useSelector(state => (state.fetchUserDataReducer?.fetchUserDataReducer) ? state.fetchUserDataReducer.fetchUserDataReducer : null);

  const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

  const loading = useSelector(state => (state?.userFolderViewReducer?.waiting) ? state.userFolderViewReducer.waiting : false)

  const UserActivityData = useSelector(state => (state?.userFolderViewReducer?.userActivityList) ? state?.userFolderViewReducer?.userActivityList : [])

  // ----- userRolesFetched From database  ----
  const userRoles = useSelector(state => (state.userManagment?.fetchRoles) ? state.userManagment?.fetchRoles : []);
  const interviewData = useSelector(state => (state.userFolderViewReducer?.interviewData) ? state.userFolderViewReducer?.interviewData[0] : []);

  // ------ interview form ---------
  const loaderForiInterviewForm = useSelector(state => (state.userFolderViewReducer?.loaderForiInterviewForm) ? state.userFolderViewReducer?.loaderForiInterviewForm : false);

  // ----- folderAccessInfo ------
  const folderAccessData = useSelector(state => (state?.userFolderViewReducer?.folderAccessData) ? state?.userFolderViewReducer?.folderAccessData : {})
  //---------- follow up call list data-----
  const followUpCallList = useSelector(state => (state.LeadReducer?.followUpCallData && state.LeadReducer?.followUpCallData.length > 0) ? state.LeadReducer.followUpCallData : [])

  // ----- trainingData ------
  const trainingData = useSelector(state => (state?.userManagment?.trainingData) ? state?.userManagment?.trainingData : {})

  // --- after Endpoint Call userTrainingData Fetched ----
  const userTrainingData = useSelector(state => (state.userManagment?.userTrainingData
  ) ? state.userManagment.userTrainingData
    : null);


  // --- after Endpoint Call TagLogsInfo Fetched ----
  const TagLogsData = useSelector(state => (state.userFolderViewReducer?.TagLogsInfo
  ) ? state.userFolderViewReducer.TagLogsInfo
    : null);


  // --- after Endpoint Call TagLogsInfo Fetched ----
  const stageData = useSelector(state => (state.userFolderViewReducer?.StageLogsData
  ) ? state.userFolderViewReducer.StageLogsData
    : null);


  // --- after Endpoint Call ContractLogsData Fetched ----
  const ContractLogsData = useSelector(state => (state.userFolderViewReducer?.ContractLogsData
  ) ? state.userFolderViewReducer.ContractLogsData
    : []);


  // ----- FDLoginInfo Fetch ------
  const logininfo = useSelector(state => (state.userManagment?.logininfo ? state.userManagment.logininfo : []))


  // --- after Endpoint Call getEmailData Fetched ----
  const emailData = useSelector(state => (state.userFolderViewReducer?.emailData
  ) ? state.userFolderViewReducer.emailData
    : []);


  // --------- FetchnotesData ------
  const notesData = useSelector(state => (state.userFolderViewReducer?.notesData
  ) ? state.userFolderViewReducer.notesData
    : []);



  // ----- folderViewCounts -----
  const folderCounts = useSelector(state => (state.userFolderViewReducer?.folderCounts
  ) ? state.userFolderViewReducer.folderCounts
    : {});

  const LifeCycleDataStage = useSelector(state => state.userFolderViewReducer?.LifeCycleDataStage ? state.userFolderViewReducer.LifeCycleDataStage : [])
  const LifeCycleDataTag = useSelector(state => state.userFolderViewReducer?.LifeCycleDataTag ? state.userFolderViewReducer.LifeCycleDataTag : [])



  useEffect(() => {
    console.log("folderdata", folderdata);
  }, [folderdata])

  // LifeCycleDataStage
  // console.warn("<<<<<<<<<< folderdata goes here >>>>>>>>>", folderdata)

  // useEffect(() => {
  //   if (showDiv === 1) {


  //       const data = {
  //         "condition": {
  //           "limit": 5,
  //           "skip": 0
  //         },
  //         "sort": {
  //           "field": "activity_time",
  //           "type": "asc"
  //         },
  //         "searchcondition": {
  //           "user_id": queryParamId ? queryParamId : undefined
  //         },
  //         "project": {},
  //         "token": "",
  //         "email": ""
  //       }

  //       setTimeout(() => {
  //         dispatch(UserActivity(data))

  //       }, 300);
  //   }
  // }, [showDiv])


  useEffect(() => {
    if (goBackBtnClick) {
      setShowDive("PersonalInformation")
    }
  }, [goBackBtnClick])






  const onPersonalInfo = (e) => {
    setShowDive(e);
  }

  useEffect(() => {
    if (cookieData && cookieData.userinfocookies) {
      setUserInfo(cookieData.userinfocookies);
    }
    const data = {
      "condition": {},
      "sort": {
        "field": "name",
        "type": "asc"
      },
      "searchcondition": {},
      "project": {
        "name": 1,
        "slug": 1,
        "status": 1,
      },
      "token": ""
    }

    dispatch(FetchRoles(data));
  }, []);


  // --------- filtering user roles based on which kind of user loggedin ----------
  useEffect(() => {
    if (userRoles.length > 0) {
      console.warn("<<<<<<< cookieData >>>>>>", cookieData)
      console.warn("<<<<<<< userRoles >>>>>>", userRoles)

      if (cookieData?.userinfo?.roles?.is_admin == 1) {
        setFilteredRoles(userRoles)

      } else if (cookieData?.userinfo?.roles?.is_bdd == 1) {

        const Roles = userRoles.filter((val) => val.val == 'hr_manager')
        setFilteredRoles(Roles)

      } else if (cookieData?.userinfo?.roles?.hr_manager == 1 || cookieData?.userinfo?.roles?.is_aer == 1) {

        const Roles = userRoles.filter((val) => val.val == 'is_rep')
        setFilteredRoles(Roles)

      }

      console.warn("<<<<<<< filteredRoles >>>>>>", filteredRoles)

    }
  }, [userRoles])
  // ---------------------------------------------------



  // --- setting state array 
  useEffect(() => {
    let Newarr = [];
    if (StateArray && StateArray.length > 0) {
      for (let i in StateArray) {
        Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
      }
      setLocationArry(Newarr);
    }

  }, [])
  // --------------------------


  //---- based on endpoint call ---
  useEffect(() => {
    if (endpointProcessState == 1) {
      setLoaderr(true);
      setOpen(false);
    } else if (endpointProcessState == 2) {
      setLoaderr(false);
      setOpen(true);

      onPersonalInfo("PersonalInformation")

      if (queryParamId != undefined && queryParamId != null) {
        dispatch(fetchUserData(queryParamId));

        if (showDiv === "UserActivity") {
          const data = {
            "condition": {
              "limit": 5,
              "skip": 0
            },
            "sort": {
              "field": "activity_time",
              "type": "desc"
            },
            "searchcondition": {
              "user_id": queryParamId ? queryParamId : undefined
            },
            "project": {},
            "token": "",
            "email": ""
          }
          console.log("UserActivity");
          dispatch(UserActivity(data))
        }


      }


    } else if (endpointProcessState == 3) {
      setLoaderr(false);

      // setErrMsgCopy(errMsg);
      // setErrPopup(true)

    }
  }, [endpointProcessState])


  // snackbar Close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    // setErrPopup(false);
    // setErrMsgCopy(null);
  };


  useEffect(() => {



    if (queryParamId != undefined && queryParamId != null) {
      dispatch(fetchUserData(queryParamId));


      if (showDiv === "UserActivity") {
        const data = {
          "condition": {
            "limit": 70,
            "skip": 0
          },
          "sort": {
            "field": "activity_time",
            "type": "desc"
          },
          "searchcondition": {
            "user_id": queryParamId ? queryParamId : undefined
          },
          "project": {},
          "token": "",
          "email": ""
        }
        console.log("UserActivity");

        dispatch(UserActivity(data))
      }

      // -------- fetch tag deatils which are assigned ------
      const payload = {
        "_id": queryParamId ? queryParamId : undefined
      }
      dispatch(TagLogsInfo(payload))
      // -----------------------------------------------

      // -------- fetch state deatils which are assigned ------
      const payloadNew = {
        "_id": queryParamId ? queryParamId : undefined
      }
      dispatch(StageLogsInfo(payloadNew))
      // -----------------------------------------------

      const data = {
        "_id": queryParamId ? queryParamId : undefined
      }

      console.warn("<<<<<<<<  folder view count >>>>>>>>>>", payload)

      dispatch(folderViewCounts(data))
    }

  }, [queryParamId])



  const UserEdit = [

    {
      id: 0,
      heading: "First Name",
      name: "firstname",
      className: "inputBlock inputBlock3line",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "Fast Name is Required",
      },
      //   minLength: "Minimum length should be 15",
      //   custom: "Value is Invalid"
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.firstname !== undefined) ? folderdata.firstname : '',

    },
    {
      id: 1,
      heading: "Last Name",
      name: "lastname",
      className: "inputBlock inputBlock3line",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "Last Name is Required",
      },
      //   minLength: "Minimum length should be 15",
      //   custom: "Value is Invalid"
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.lastname !== undefined) ? folderdata.lastname : '',

    },

    {
      id: 2,
      heading: "User Type",
      label: "Select Roles",
      name: "roles",
      className: 'inputBlock usertypebox inputBlock4line',
      type: 'select',
      multiple: true,
      checkbox: true,
      // other: true,
      rules: { required: true },
      values: filteredRoles,
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.roloesArr.length > 0) ? folderdata.roloesArr : '',
      sx: { m: 1, minWidth: 120 },
    },

    {
      id: 3,
      heading: "Email",
      name: "email",
      className: "inputBlock inputBlock3line",
      type: "text",
      rules: { required: true },
      disabled: true,
      errorMessage: {
        required: "Email is Required",
      },
      //   minLength: "Minimum length should be 15",
      //   custom: "Value is Invalid"
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.email !== undefined) ? folderdata.email : '',

    },

    {
      id: 4,
      // heading: "First Name",
      label: "Address",
      name: "address",
      className: 'inputBlock addressbox inputBlock1line',
      type: "textarea",
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.address !== undefined) ? folderdata.address : '',
      //inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },

    {
      id: 5,
      heading: "Phone",

      name: "phone",
      className: 'inputBlock inputBlock3line',
      type: "text",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "Phone No is Required",
      },
      //   minLength: "Minimum length should be 15",
      //   custom: "Value is Invalid"
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.phone !== undefined) ? folderdata.phone : '',


    },

    {
      id: 6,
      heading: "State",
      label: "Select State",
      name: "state",
      className: 'inputBlock usertypebox inputBlock4line',
      type: "select",
      other: true,
      values: locationArry,
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.state !== undefined) ? folderdata.state : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },

    {
      id: 7,
      heading: "City",
      label: "City",
      name: "city",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.city !== undefined) ? folderdata.city : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },

    {
      id: 8,
      heading: "Zip",
      label: "Zip",
      name: "zip",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.zip !== undefined) ? folderdata.zip : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },

    {
      id: 9,
      heading: "Secondary Phone",
      label: "Secondary Phone",
      name: "secondary_phone",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.secondary_phone !== undefined) ? folderdata.secondary_phone : '',

    },

    {
      id: 10,
      heading: "Secondary Email",
      label: "Secondary Email",
      name: "secondary_email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.secondary_email !== undefined) ? folderdata.secondary_email : '',


    },


    {
      id: 11,
      name: "status",
      className: " inputBlock1line statusinput",
      type: 'singleCheckbox',
      values: { key: 0, val: 'Active' },
      // rules: { required: true },
      // errorMessage: {
      //   required: "This Field is Required"
      // },
      defaultValue: (folderdata && Object.keys(folderdata).length > 0 && folderdata.status !== undefined) ? folderdata.status : false,

      //   minLength: "Minimum length should be 15",
      //   custom: "Value is Invalid"



    },

  ];


  const PasswordEdit = [
    {
      id: 0,
      heading: " New Password",
      label: "New Password",
      name: "new_password",
      className: 'inputBlock inputBlock4line passwordinput',
      type: 'password',
      defaultValue: '',
      rules: { required: true, pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/ },
      errorMessage: {
        required: "This Field is Required",
        errorMessageConfirmPassword: "Password does not match",
        pattern: "Password contain at least one numeric digit and a special character",
        custom: "Value is Invalid"
      },
    },

    {
      id: 1,
      heading: "Confirm Password",
      label: "Confirm Password",
      name: "confirm_new_password",
      className: 'inputBlock inputBlock4line passwordinput',
      type: 'password',
      defaultValue: '',
      rules: { required: true, pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/ },
      errorMessage: {
        required: "This Field is Required",
        errorMessageConfirmPassword: "Password does not match",
        pattern: "Password contain at least one numeric digit and a special character",
        custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
      },
    },
  ];


  const dataform = {
    id: 'userFolderViewForm',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: showDiv === "EditProfile" ? "users/add-update-user" : "profile/admin-password-change",

    // urlPathOnSuccessfulFormSubmission: `/lead-folder-view/${id}`,
    submitBtnName: "UPDATE",
    cancelBtnName: "Cancel",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {

      _id: (queryParamId !== undefined && queryParamId !== null) ? queryParamId : undefined,
      token: cookieData?.token ? cookieData.token : undefined

    },

    fields: showDiv === "EditProfile" ? UserEdit : PasswordEdit,

    customButtons: [
      {
        id: 'goBack001',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      },
    ]

  };










  useEffect(() => {

    if (queryParamId != undefined && queryParamId != null) {
      switch (showDiv) {
        case "UserActivity":
          let UserActivityPaylod = {
            "condition": {
              "limit": 5,
              "skip": 0
            },
            "sort": {
              "field": "activity_time",
              "type": "desc"
            },
            "searchcondition": {
              "user_id": queryParamId ? queryParamId : undefined
            },
            "project": {},
            "token": "",
            "email": ""
          }
          console.log("UserActivity");
          dispatch(UserActivity(UserActivityPaylod))
          break;
        case "InterviewForm":
          let InterviewFormPaylod = {
            "condition": {
              "limit": 5,
              "skip": 0
            },
            "sort": {
              "field": "activity_time",
              "type": "desc"
            },
            "searchcondition": {
              "user_id": queryParamId ? queryParamId : undefined
            },
            "project": {},
            "token": "",
            "email": ""
          }
          console.log("InterviewForm");
          dispatch(interviewFormdata(InterviewFormPaylod))
          break;
        case "FolderAccess":
          const FolderAccessPaylod = {
            "source": "",
            "condition": {
              "limit": 8,
              "skip": 0
            },
            "sort": {
              "type": "desc",
              "field": "_id"
            },
            "searchcondition": {
              "user_id": queryParamId ? queryParamId : undefined
            }
          }
          console.log("FolderAccess");
          dispatch(folderAccessInfo(FolderAccessPaylod))
          break;
        case "FolderAccessBy":
          const FolderAccessByPaylod = {
            "source": "",
            "condition": {
              "limit": 8,
              "skip": 0
            },
            "sort": {
              "type": "desc",
              "field": "_id"
            },
            "searchcondition": {
              "user_id": queryParamId ? queryParamId : undefined
            }
          }
          console.log("FolderAccessBy");
          dispatch(folderAccessInfo(FolderAccessByPaylod))
          break;
        case "FollowUpCall":
          let FollowUpCallPaylod = {
            "condition": {
              "limit": 5,
              "skip": 0
            },
            "sort": {
              "field": "_id",
              "type": "desc"
            },
            "searchcondition": {
              "added_for": queryParamId ? queryParamId : undefined
            },
            "count": false,
            "source": "",
          }
          console.log("FollowUpCall");
          store.dispatch(getDatafollowUpCallList(FollowUpCallPaylod));
          break;
        case "ContractLog":
          const ContractLogPaylod = {
            "condition": {},
            "sort": {
              "field": "updatedon_datetime",
              "type": "desc"
            },
            "searchcondition": {
              "_id": queryParamId ? queryParamId : undefined
            },
            "project": {
            }
          }
          console.log("ContractLogPaylod");
          dispatch(ContractLogsInfo(ContractLogPaylod))
          break;
        case ("EmailSend"):
          let EmailSendPaylod = {
            "condition": {
              "limit": 5,
              "skip": 0
            },
            "sort": {
              "field": "start_datetime_unix",
              "type": "desc"
            },
            "searchcondition": {
              "added_for": queryParamId ? queryParamId : undefined,
              // "added_by":cookieData?.userinfo?._id
            },

          }
          console.log("EmailSend");
          store.dispatch(getMailInfo(EmailSendPaylod));
          break;
        case "EmailSendBy":
          let EmailSendByPaylod = {
            "condition": {
              "limit": 5,
              "skip": 0
            },
            "sort": {
              "field": "start_datetime_unix",
              "type": "desc"
            },
            "searchcondition": {
              // "added_for":  ,
              "added_by": queryParamId ? queryParamId : undefined
            },
          }
          console.log("EmailSendBy");
          store.dispatch(getMailInfo(EmailSendByPaylod));
          break;
        case "TrainingStatus":
          const TrainingStatusPaylod = {
            "source": "lesson_list",
            "condition": {
              "limit": 10,
              "skip": 0
            },
            "sort": {
              "field": "_id",
              "type": "desc"
            },
            "searchcondition": {
              "user_id": queryParamId ? queryParamId : undefined
            },
            "project": {},
            "token": "",
            "email": ""
          }
          console.log("TrainingStatus");
          dispatch(FetchTrainingStatus(TrainingStatusPaylod))
          break;
        case "LoginInfo":
          let LoginInfoPaylod = {
            "condition": {
              "limit": 5,
              "skip": 0
            },
            "sort": {
              "field": "_id",
              "type": "desc"
            },
            "searchcondition": {
              "user_id": queryParamId ? queryParamId : undefined
            },
            // "project": {},
            // "token": "",
            "count": false,
            "source": "",
          }
          console.log("LoginInfo");
          dispatch(logInInfo(LoginInfoPaylod))
          break;
        case "notes":
          let notesPaylod = {
            "condition": {
              "limit": 5,
              "skip": 0
            },
            "sort": {
              "field": "_id",
              "type": "desc"
            },
            "searchcondition": {
              "notes_for": queryParamId ? queryParamId : undefined
            },
            // "project": {},
            // "token": "",
            "count": false,
            "source": "",
          }
          console.log("notes");
          // store.dispatch(getFdLoginInfo(data));
          dispatch(getNotes(notesPaylod))
          break;
        case "StageLifeCycle":
          let StageLifeCyclePaylod = {
            "_id": queryParamId ? queryParamId : undefined
          }
          console.log("StageLifeCycle");
          dispatch(getStageLifeCycleData(StageLifeCyclePaylod))
          break;
        case "TagLifeCycle":
          const TagLifeCyclePaylod = {
            "_id": queryParamId ? queryParamId : undefined
          }
          console.log("TagLifeCycle");
          dispatch(getTagLifeCycleData(TagLifeCyclePaylod))

          break;

        default:
          break;
      }


    }

    return () => {
      store.dispatch(clearInterviewData())
    }


  }, [showDiv])






  return (
    <>


      <div className='rolelist_mainwrp'>
        <div className='listing_heading'>
          <h1> User Folder View</h1>
        </div>
        <div className='rolelist_mainwrp_Table'>
          {loader || loaderr ? <Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box> : null}
          {loaderForiInterviewForm && <Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>}


          {folderdata ? (<div className='folder_view_body '>


            {/* -------- Left Block ----- */}
            <div className='folder_view_left'>
              <h3><PermContactCalendarIcon /> General Information</h3>

              <p>
                <strong>Name:</strong>
                {folderdata !== null ? <span>{folderdata.firstname + ' ' + folderdata.lastname}</span> : ''} </p>
              <p className='email'><strong>Email:</strong> {folderdata !== null ? <span>{folderdata.email}</span> : ''}</p>
              <p><strong>Phone:</strong> {folderdata !== null ? <span>{folderdata.phone}</span> : ''} </p>


              <div className='folder_view_left_btn'>
                <Button className={showDiv === "UserActivity" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("UserActivity")}>User Activity</Button>

                <Button className={showDiv === "PersonalInformation" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("PersonalInformation")}>Personal Information</Button>

                <Button className={showDiv === "EditProfile" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("EditProfile")}>Edit Profile</Button>

                <Button className={showDiv === "ChangePassword" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("ChangePassword")}>Change Password</Button>

                <Button className={showDiv === "Jobticket" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("Jobticket")}>Jobticket</Button>

                <Button className={showDiv === "InterviewForm" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("InterviewForm")}>Interview Form</Button>

                <Button className={showDiv === "FolderAccess" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("FolderAccess")}> Folder Access </Button>

                {folderdata.roles.is_rep == 1 || folderdata.roles.is_admin == 1 ? (<Button className={showDiv === "FolderAccessBy" ? 'submit_btn active_btn folder_btn' : 'submit_btn folder_btn'} onClick={() => onPersonalInfo("FolderAccessBy")}> Folder Accessed By <br />
                  <span className='uName'> ({folderdata.firstname + ' ' + folderdata.lastname})</span> </Button>) : ""}

                <Button className={showDiv === "FollowUpCall" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("FollowUpCall")}> Follow Up Calls </Button>

                <Button className={showDiv === "ContractLog" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("ContractLog")}> Contract Logs </Button>

                <Button className={showDiv === "EmailSend" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("EmailSend")}> Email Sent</Button>

                {folderdata.roles.is_rep == 1 || folderdata.roles.is_admin == 1 ? <Button className={showDiv === "EmailSendBy" ? 'submit_btn active_btn folder_btn' : 'submit_btn folder_btn'} onClick={() => onPersonalInfo("EmailSendBy")}> Email Sent by  <br />
                  <span className='uName'> ({folderdata.firstname + ' ' + folderdata.lastname})</span>  </Button> : ""}

                {folderdata.roles.is_rep == 1 || folderdata.roles.hr_manager == 1 ? <Button className={showDiv === "TrainingStatus" ? 'submit_btn active_btn folder_btn' : 'submit_btn folder_btn'} onClick={() => onPersonalInfo("TrainingStatus")}> Training Status

                  {folderCounts?.done_training_percentage > 0 ?
                    <Tooltip title="Trainig Percentage">
                      <span className='count'>
                        {folderCounts?.done_training_percentage}%
                      </span>
                    </Tooltip> : ""}

                </Button> : ""}

                <Button className={showDiv === "LoginInfo" ? 'submit_btn active_btn folder_btn' : 'submit_btn folder_btn'}
                  onClick={() => onPersonalInfo("LoginInfo")}>
                  Login Info

                  {folderCounts?.login_count > 0 ? (<Tooltip title="Login Count">
                    <span className='count'>
                      {folderCounts?.login_count}
                    </span>
                  </Tooltip>) : ""}

                </Button>


                <Button className={showDiv === "notes" ? 'submit_btn active_btn folder_btn' : 'submit_btn folder_btn'} onClick={() => onPersonalInfo("notes")}> Notes


                  {folderCounts?.notes_for_count > 0 ?
                    (<Tooltip title="Login Count">
                      <span className='count'>
                        {folderCounts?.notes_for_count}
                      </span>
                    </Tooltip>) : ""}

                </Button>
                <Button className={showDiv === "StageLifeCycle" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("StageLifeCycle")}>Stage life Cycle</Button>
                <Button className={showDiv === "TagLifeCycle" ? 'submit_btn active_btn' : 'submit_btn'} onClick={() => onPersonalInfo("TagLifeCycle")}>Tag Life Cycle</Button>

              </div>
            </div>
            {/* ------------------------- */}



            {/* ----- Middle block ---- */}
            <div className='folder_view_con'>


              {/* ---- User Activity Block ---- */}
              {showDiv === "UserActivity" ? (<UserActivityBlock name='User' activity={UserActivityData} />) : ""}
              {/* --------------- */}

              {/* --- Personal Info Block ----  */}
              {showDiv === "PersonalInformation" ? (<PersonalInfoBlock folderdata={folderdata} />) : ''}
              {/* ------------------------ */}

              {/* ----- Edit profile and edit password block ----- */}
              {showDiv === "EditProfile" || showDiv === "ChangePassword" ? (<div>
                <div className='adminformbody'>
                  <h3><EditIcon /> {showDiv === "EditProfile" ? "Edit Profile" : "Edit Password"}</h3>
                  <Form formData={dataform} />
                </div>
              </div>) : ''}
              {/* --------------------------------- */}

              {/* ------- JObticket Block ----- */}
              {showDiv === "Jobticket" ? <div>
                <h3><FeedbackIcon /> Job Ticket Details</h3>
                <p> No Data Found !</p>
              </div> : ''}
              {/* ------------------------------ */}

              {/* Interview Form */}
              {showDiv === "InterviewForm" ? <div>
                <Interview id={queryParamId} data={interviewData} />
              </div> : ''}
              {/* --------------- */}


              {/* Folder Access Block */}
              {showDiv === "FolderAccess" ? <div>
                <FolderViewAccess activity={folderAccessData.my_folder_accessed} type="Folder Access" />
              </div> : ''}
              {/* --------------- */}

              {/* Folder Access By Block*/}
              {showDiv === "FolderAccessBy" ? <div>
                <FolderViewAccess activity={folderAccessData.i_accessed} type="Folder Access By" />
              </div> : ''}
              {/* --------------- */}

              {/* Follow Up calles block*/}
              {showDiv === "FollowUpCall" ? <div>
                <FollowUpCall followUpCallList={followUpCallList} type="Folder Access By" />
              </div> : ''}
              {/* --------------- */}

              {/* Contract management Block*/}
              {showDiv === "ContractLog" ? <div>
                <ContractManage queryParamId={queryParamId} contractlogs={ContractLogsData} />
              </div> : ''}
              {/* --------------- */}



              {/* Training Progress Block*/}
              {showDiv === "TrainingStatus" ? <div>
                <TrainingProgress trainingData={trainingData} userdata={userTrainingData} />
              </div> : ''}
              {/* --------------- */}


              {/* Email send Block */}
              {showDiv === "EmailSend" ? <div>
                <EmailVew queryParamId={queryParamId} emailData={emailData} showDiv={showDiv} />
              </div> : ''}
              {/* --------------- */}
              {showDiv === "EmailSendBy" ? <div>
                <EmailVew emailData={emailData} showDiv={showDiv} />
              </div> : ''}
              {/* --------------- */}
              {/* Folder Access By Block*/}
              {showDiv === "LoginInfo" ? <div>
                <FDLoginInfo logininfo={logininfo} type="Login Info" />
              </div> : ''}
              {/* --------------- */}
              {showDiv === "notes" ? <div>
                <Notesview notesData={notesData} />
              </div> : ''}
              {/* --------------- */}
              {showDiv === "StageLifeCycle" ? <div>
                <StageLifeCycle folderdata={folderdata} LifeCycleDataStage={LifeCycleDataStage} />
              </div> : ''}
              {showDiv === "TagLifeCycle" ? <div>
                <TagLifeCycle folderdata={folderdata} LifeCycleDataTag={LifeCycleDataTag} />
              </div> : ''}

            </div>
            {/* ----------------------- */}

            {/* ----- Right block ---- */}
            <div className='folder_view_right'>
              {stageData !== null && TagLogsData !== null ?
                (<RightPanelBlock stagedata={stageData} tagsinfo={TagLogsData} />) :
                (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>)}
            </div>
            {/* _----------- */}


          </div>) : ""}



        </div>
      </div>


      {open ? (<Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={queryParamId ? "Updated Sucessfully" : " "}
      // action={action}
      />) : ""}

    </>
  )
}

export default FolderView;
